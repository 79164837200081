import { Link as RouterLink } from 'react-router-dom'
import { Box, Center, Container, Flex, Heading, Image, Link, LinkBox, LinkOverlay, Spacer } from '@chakra-ui/react'
const PortfolioCard = ({ bgPos, height, image, text, textJustify, to, width }) => {
  // console.log(to)
  return (
    <LinkBox as='article'>
      <Box
        alignItems={'center'}
        color='brand.secondary.light'
        display={'flex'}
        flexDir={'column'}
        h={height || '100%'}
        // w={width || 'auto'}
        // h='500px'
        width={width || '100%'}
        justifyContent={textJustify || 'flex-start'}
        letterSpacing={'1.25ch'}
        overflow={'hidden'}
        p={8}
        position='relative'
        style={{
          aspectRatio: '1 / 1',
        }}
        zIndex={10}
        // transition='all ease-in-out 600ms'

        _after={{
          backgroundPosition: { ...bgPos },
          bgSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'brand.secondary',
          bgImage: `url('${image}')`,
          content: '""',
          transition: 'transform 2s, filter 1.5s ease-in-out',
          transformOrigin: 'center center',
          filter: 'brightness(50%)',
          h: '100%',
          w: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          zIndex: -1,
        }}
        _hover={{
          color: 'brand.accent',
          _after: {
            filter: 'brightness(100%)',
            transform: 'scale(1.3)',
          },
        }}
      >
        <Heading as={'h3'} color={'inherit'} fontWeight={'bold'} fontSize={'2.5rem'} whiteSpace={'nowrap'}>
          <LinkOverlay as={RouterLink} to={to}>
            {text}
          </LinkOverlay>
        </Heading>
      </Box>
    </LinkBox>
  )
}

export default PortfolioCard

/*
-webkit-filter: brightness(.6);
-moz-filter: brightness(.6);
filter: brightness(.6);
-webkit-transition: all ease-in-out 600ms !important;
-moz-transition: all ease-in-out 600ms !important;
transition: all ease-in-out 600ms !important;

*/
