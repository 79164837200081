import { Box, Image, useColorMode } from '@chakra-ui/react'

import './style.css'
import logoBlackInner from '../../images/logo-trans-inner-black.png'
import logoBlackOuter from '../../images/logo-trans-outer-black.png'
import logoWhiteInner from '../../images/logo-trans-inner-white.png'
import logoWhiteOuter from '../../images/logo-trans-outer-white.png'
const LoaderLogo = ({ height = '100px', width = '100px' }) => {
  const { colorMode } = useColorMode()
  return (
    <Box height={height} position={'relative'} width={width}>
      <Image className={'rotate'} height={'100%'} position={'absolute'} src={colorMode === 'dark' ? logoWhiteOuter : logoBlackOuter} width={'100%'}></Image>
      <Image height={'100%'} ml={'2px'} position={'absolute'} src={colorMode === 'dark' ? logoWhiteInner : logoBlackInner} width={'100%'}></Image>
    </Box>
  )
}

export default LoaderLogo
