import { fetchApi, postApi } from './api'

const addTag = async tagLabel => {
  const URL = 'manage/tag'
  return postApi(URL, { label: tagLabel })
}

const deleteTag = async (tag = '') => {
  const URL = 'manage/tag/' + tag
  return fetchApi(URL, 'DELETE')
}

const fetchTag = async (tag = null, byId = false) => {
  let URL = 'tag'
  if (tag) URL += `/${tag}`
  try {
    const data = await fetchApi(URL)
    if (byId) {
      if (data?.length) {
        const tagsById =
          data?.reduce((acc, curr) => {
            return { ...acc, [curr.id.toString()]: { ...curr } }
          }, {}) || {}
        return tagsById
      }
      if (data?.id) {
        return { [data.id]: data }
      }
    }
    return data
  } catch (e) {
    console.error('error caught fetching tag data')
    console.error(e)
    throw new Error(e?.message ?? e ?? 'error caught fetching tag data')
  }
}

const fetchGalleryTagData = async () => {
  const URL = 'tag/gallery'
  return fetchApi(URL)
}

const updateTag = async tagData => {
  const URL = 'manage/tag'
  return postApi(URL, tagData, 'PUT')
}

export { addTag, deleteTag, fetchTag, fetchGalleryTagData, updateTag }
