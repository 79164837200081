import { useEffect } from 'react'
import { NavLink, Outlet, useParams,  } from 'react-router-dom'
import { Box, Container, Heading, HStack, Icon,  Image, Link, Text, useColorMode } from '@chakra-ui/react'
import { FaCircle } from "react-icons/fa";
import PortraitPolicy from '../../components/portrait-policy'
import ResidentialPolicy from '../../components/residential-policy'
const PoliciesPage = () => {
  const params = useParams()
  const policy = params?.policy
  useEffect(() => {
    if (params?.policy) {
      console.log(params?.policy)
    
    }
  }, [params])
  return (
    <Container
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-around'}
      textAlign={'center'}
    >
     
      <Heading
        lineHeight={1.1}
        fontWeight={'bold'}ResidentialPolicy
        fontSize={{ base: '4xl' }}
        textAlign={'center'}
        textTransform={'uppercase'}
      >
       Policies
      </Heading>
      <HStack align={{ base:  'center' }} justify={{ base:  'center' }} spacing={4} py={4} overflowX={'auto'} width={'100%'}>
      <Link
      as={NavLink}
      fontSize={'2xl'}
      fontWeight={500}
      id={`policy-residential`}
      m={ 0}
      maxW={'220px'}
      p={2}
      style={({ isActive }) =>
        isActive 
          ? {
              color: 'orange',
              // textDecoration: 'underline',
            }
          : undefined
      }
      textAlign={'center'}
      to={'/policies/portrait'}
      transition={'color .5s'}
      width={'150px'}
      _hover={{
        textDecoration: 'none',
        color: 'orange',
        transition: 'color .75s',
      }}
    >
      PORTRAIT
    </Link>
    <Box >

    <Icon as={FaCircle} fontSize={10} mx={'auto'} />
    </Box>
      <Link
      as={NavLink}
      fontSize={'2xl'}
      fontWeight={500}
      id={`policy-residential`}
      m={ 0}
      maxW={'220px'}
      p={2}
      style={({ isActive }) =>
        isActive 
          ? {
              color: 'orange',
              // textDecoration: 'underline',
            }
          : undefined
      }
      textAlign={'center'}
      to={'/policies/residential'}
      transition={'color .5s'}
      width={'150px'}
      _hover={{
        textDecoration: 'none',
        color: 'orange',
        transition: 'color .75s',
      }}
    >
      RESIDENTIAL
    </Link>
        </HStack>
        {policy !== 'residential' && policy !== 'portrait' && (
          <Text as={'p'} mt={20} fontSize={'2xl'} textAlign={'left'}>To ensure a smooth and successful photoshoot, Manuel Pellón Photography requests clients adhere to our established policies. 
          <br />
          <br />
          Please select one of our available policies above to view. 
          </Text>
        )}
        {
          policy === 'portrait' && <PortraitPolicy />

        }
        {
          policy === 'residential' && <ResidentialPolicy />

        }
        {/* <Outlet />  */}
          
        
    </Container>
  )
}

export default PoliciesPage
