import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabIndicator,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react'

import { AiOutlineClose } from 'react-icons/ai'
import { BsPersonLinesFill, BsPersonFillAdd, BsFillPersonCheckFill } from 'react-icons/bs'
import { FaSearch, FaUserEdit } from 'react-icons/fa'
import { MdEventAvailable, MdMail, MdPhone, MdSend } from 'react-icons/md'
import { PiArrowFatLeftFill, PiArrowFatRightFill } from 'react-icons/pi'

import ContactEditForm from '../contact-edit-form/contact-edit-form'

import './style.css'

import { debounce } from '../../services/util'
import { fetchContact } from '../../services/contact'

const tabs = ['contacts', 'packages']

const ManageClients = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { colorMode } = useColorMode()
  const [contacts, setContacts] = useState([])
  const [contactsDisplayed, setContactsDisplayed] = useState([])
  const [contactEditing, setContactEditing] = useState({})
  const [searchInput, setSearchInput] = useState('')
  const [showTabs, setShowTabs] = useState(true)

  const clearSearch = () => {
    setSearchInput('')
  }

  const navigate = useNavigate()
  const params = useParams()

  const handleTabsChange = tabIdx => {
    console.log(handleTabsChange)
    setActiveTab(tabIdx)
    const view = tabs[tabIdx]
    if (view) {
      navigate(`/manage/clients/${view}`)
    }
  }

  const onContactDelete = contact => {
    //if needed in future, args used: (contact, removeEvents)
    setContacts(() => contacts.filter(c => c.id !== contact))
    setContactEditing({})
    //if removeEvents....
  }

  const onContactEdit = contact => {
    // console.log(contact)
    setContactEditing({ ...contact })
  }
  const onContactUpdate = contact => {
    // console.log('onContactUpdate')
    // console.log(contact)
    //may need to update existing events in future ?
    setContacts([...contacts.filter(c => c.id !== contact.id), contact])
    setContactEditing({})
  }

  const onContactModalClose = () => {
    setContactEditing({})
  }

  useEffect(() => {
    // console.log('fetching contacts...')
    fetchContact().then(r => {
      if (r?.length) {
        setContacts(r)
      } else {
        setContacts([])
      }
    })
  }, [])

  useEffect(
    x => {
      //todo dont store results in state
      if (!searchInput) {
        debounce(() => setContactsDisplayed([...contacts]))()
      } else {
        const search = searchInput
        debounce(() =>
          setContactsDisplayed(
            [...contacts].filter(c => {
              const searchToLower = search?.toLowerCase()
              if (c?.first_name?.toLowerCase()?.includes(searchToLower)) return true
              if (c?.last_name?.toLowerCase()?.includes(searchToLower)) return true
              if (c?.email?.toLowerCase()?.includes(searchToLower)) return true
              if (c?.phone?.toLowerCase()?.includes(searchToLower)) return true
              return false
            }),
          ),
        )()
      }
    },
    [contacts, searchInput],
  )

  return (
    <Tabs align='start' index={activeTab} isLazy={true} lazyBehavior={'keepMounted'} onChange={handleTabsChange} variant='unstyled'>
      {showTabs && (
        <Box
          bgColor={colorMode === 'dark' ? 'brandDark.background' : 'brand.background'}
          position={'sticky'}
          // after primary tabs top
          top={186}
          zIndex={19}
        >
          <TabList bgColor={`brand.background.${colorMode}`} shadow={'md'}>
            <Tab fontWeight={'bold'} fontSize={'xl'}>
              CONTACTS
            </Tab>
            <Tab fontWeight={'bold'} fontSize={'xl'}>
              PACKAGES
            </Tab>
          </TabList>
          <TabIndicator mt='-1.5px' height='2px' bg='brand.accent' borderRadius='1px' />
        </Box>
      )}

      <TabPanels pt={4}>
        <TabPanel>
          <Box alignContent={'center'} display={'flex'}>
            <InputGroup mb={4} flexGrow={1}>
              <InputLeftElement pointerEvents='none'>
                <Icon as={FaSearch} color='gray.300' />
              </InputLeftElement>
              {searchInput && (
                <InputRightElement cursor={'pointer'}>
                  <Icon as={AiOutlineClose} color='gray.300' onClick={clearSearch} />
                </InputRightElement>
              )}
              <Input placeholder='Search clients' focusBorderColor={'brand.accent'} onChange={e => setSearchInput(e.target.value)} type={'search'} value={searchInput} />
            </InputGroup>
            <IconButton icon={<BsPersonFillAdd />} onClick={() => setContactEditing({ id: null })} fontSize={'xl'} ml={4} variant={'ghost'} />
          </Box>
          <TableContainer>
            <Table sx={{ tableLayout: 'auto', width: '100%' }}>
              <Thead>
                <Tr>
                  <Th width={1}>
                    <Icon aria-label='last communication' color='brand.accent' as={MdMail} fontSize='lg' transition={'all 1s ease'} variant='ghost' />
                  </Th>
                  <Th color={'brand.accent'}>first</Th>
                  <Th color={'brand.accent'}>last</Th>
                  <Th color={'brand.accent'}>email</Th>
                  <Th color={'brand.accent'}>phone</Th>
                  <Th color={'brand.accent'}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!contactsDisplayed?.length ? (
                  <Tr>
                    <Td textAlign='center' colSpan='6'>
                      NO CONTACTS FOUND
                    </Td>
                  </Tr>
                ) : (
                  contactsDisplayed.map(contact => (
                    <Tr key={contact.id}>
                      {/*     
                      // Last communication/status icon
                      <Tooltip label={'last message received Monday, Dec. 25th 2023 04:20 PM'} closeOnClick={false} hasArrow placement={'top'}>
                        <span>
                          <Icon as={PiArrowFatRightFill} />
                        </span>
                      </Tooltip>
                    */}
                      <Td>-</Td>
                      <Td>{contact.first_name}</Td>
                      <Td>{contact.last_name}</Td>
                      <Td>{contact.email}</Td>
                      <Td>{contact.phone}</Td>
                      <Td className='action-td'>
                        {/* TODO: make btns */}
                        {/* <IconButton icon={<FaUserEdit />} cursor={'pointer'} fontSize={'xl'} title={'Edit Contact'} variant={'ghost'} /> */}
                        <IconButton
                          icon={<BsPersonLinesFill />}
                          cursor={'pointer'}
                          fontSize={'xl'}
                          ml={4}
                          onClick={() => onContactEdit(contact)}
                          title={'Contact Details'}
                          variant={'ghost'}
                        />
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel>Manage client's photo packages</TabPanel>
      </TabPanels>

      {/* ADD/EDIT CONTACT */}
      <AlertDialog key={contactEditing.id} isCentered isOpen={contactEditing?.id !== undefined} onClose={onContactModalClose}>
        <AlertDialogOverlay>
          <AlertDialogContent maxH={'90vh'} maxW={900} width={'95vw'}>
            {/* <AlertDialogContent maxH={600} maxW={600} width={'100%'}> */}
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {contactEditing?.id ? 'EDIT' : 'ADD NEW'} CONTACT
            </AlertDialogHeader>
            <AlertDialogBody p={2}>
              <ContactEditForm key={contactEditing} onCancel={onContactModalClose} onDelete={onContactDelete} onUpdate={onContactUpdate} originalContact={contactEditing} />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Tabs>
  )
}

export default ManageClients
