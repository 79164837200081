import {
  Flex,
  Hide,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'

import { AiFillInstagram } from 'react-icons/ai'
import { BsPhoneFill } from 'react-icons/bs'
import { MdMail } from 'react-icons/md'
const CotactIcons = () => {
  const { hasCopied, onCopy } = useClipboard('contact@manuelpellon.com')
  return (
    <Flex
      align={{ base: 'center', lg: 'flex-start' }}
      direction={{ base: 'row', xl: 'column' }}
      justifyContent={{ base: 'space-between' }}
      spacing={{ base: 6, md: 10, lg: 20 }}
      height={{ base: 'auto', xl: 200 }}
      gap={{ base: 'unset', lg: 8 }}
    >
      <Stack
        align='center'
        direction={{ base: 'column', xl: 'row' }}
        justify={{ base: 'center', lg: 'flex-start' }}
        width={{ base: '180px', lg: '300px' }}

        // pr={{ base: 0, md: 6, lg: 8 }}
      >
        <Tooltip
          label={hasCopied ? 'Email Copied!' : 'Click to copy.'}
          closeOnClick={false}
          hasArrow
          placement={'top'}
        >
          <IconButton
            aria-label='email'
            variant='ghost'
            size='lg'
            fontSize='3xl'
            icon={<MdMail />}
            _hover={{
              bg: 'brand.primary',
              color: 'brand.secondary',
              transition: 'all 1s ease',
            }}
            transition={'all 1s ease'}
            onClick={onCopy}
            isRound
          />
        </Tooltip>
        <Hide below='md'>
          <Text
            as={'span'}
            fontSize={{ base: 'md', lg: '2xl' }}
            fontWeight={'medium'}
          >
            contact@manuelpellon.com
          </Text>
        </Hide>
      </Stack>
      <Stack
        align='center'
        direction={{ base: 'column', xl: 'row' }}
        flexGrow={1}
        justify={{ base: 'center', lg: 'flex-start' }}
        width={{ base: '180px', lg: '300px' }}
      >
        <Tooltip label={'Call me: 1-914-885-5968'} hasArrow placement={'top'}>
          <Link href='tel:1-914-885-5968'>
            <IconButton
              aria-label='github'
              variant='ghost'
              size='lg'
              fontSize='3xl'
              icon={<BsPhoneFill />}
              _hover={{
                bg: 'brand.primary',
                color: 'brand.secondary',
                transition: 'all 1s ease',
              }}
              transition={'all 1s ease'}
              isRound
            />
          </Link>
        </Tooltip>
        <Hide below='md'>
          <Text
            as={'p'}
            fontSize={{ base: 'md', lg: '2xl' }}
            fontWeight={'medium'}
          >
            914-885-5968
          </Text>
        </Hide>
      </Stack>
      <Stack
        align='center'
        direction={{ base: 'column', xl: 'row' }}
        justify={{ base: 'center', lg: 'flex-start' }}
        width={{ base: '180px', lg: '300px' }}
      >
        <Tooltip label={'Follow on Instagram.'} hasArrow placement={'top'}>
          <Link href='https://www.instagram.com/manuelpellonph/' isExternal>
            <IconButton
              aria-label='github'
              variant='ghost'
              size='lg'
              fontSize='3xl'
              icon={<AiFillInstagram />}
              _hover={{
                bg: 'brand.primary',
                color: 'brand.secondary',
                transition: 'all 1s ease',
              }}
              transition={'all 1s ease'}
              isRound
            />
          </Link>
        </Tooltip>
        <Hide below='md'>
          <Text
            as={'p'}
            fontSize={{ base: 'md', lg: '2xl' }}
            fontWeight={'medium'}
          >
            @manuelpellonph
          </Text>
        </Hide>
      </Stack>
    </Flex>
  )
}
export default CotactIcons
