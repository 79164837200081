import { fetchApi, postApi } from './api'
import { timestampSql } from './format'
const BASE_URL = process.env.REACT_APP_API_URL

const addEvent = async event => {
  const URL = 'manage/event'
  // const formData = new FormData()
  return postApi(URL, event)
}

const archiveEvent = async event => {
  if (!event) {
    throw new Error('Invalid event ID received')
  }
  const URL = `manage/event/archive/${event}`
  return fetchApi(URL)
}

const calendarEvent = e => {
  // console.log('calendarEvent')
  /*
      description=""
      endDate='2024-06-24'
      endTime='23:30'
      location='World Wide Web'
      name='Title'
      startDate='2024-06-24'
      startTime='10:15'
  */
  //TODO: This should be moved to server
  //make single source of tuth. config in /manage
  //split out?
  const durationDefault = 60
  const portraitTimeMin = { 'package-1': 30, 'package-2': 45, 'package-3': 120, 'package-4': 120 }
  const eventDuration = (portraitTimeMin?.[e?.portrait_package] ?? durationDefault) * 60 * 1000
  const services = { residential: 'Real estate', portrait: 'Portrait' }
  const event = {}
  let name = ''
  let description = eventDescripitionStr(e)
  let location = ''
  if (e?.conf_date) {
    event.startDate = e.conf_date
  }

  if (e?.conf_time) {
    event.startTime = e.conf_time
  }

  if (event?.startDate && event?.startTime) {
    const startDt = new Date(`${event.startDate} ${event.startTime}`)
    startDt.setTime(startDt.getTime() + eventDuration)
    const endSql = timestampSql(startDt)
    const [endDate, endTime] = endSql.split(' ')
    if (endDate) event.endDate = endDate
    if (endTime) event.endTime = endTime
  }

  if (e?.service === 'residential') {
    location = eventLocationString(e)
    if (location) event.location = location
  }

  if (services?.[e?.service]) {
    name += services[e.service]
    if (e?.contact) {
      const { first_name, last_name } = e.contact
      const contactName = `${first_name || ''} ${last_name || ''}`
      if (contactName) name += ` with ${contactName}`
    }
  }

  if (name) {
    event.name = name
  }
  if (description) {
    event.description = description
  }

  return event
}

const confirmEvent = async (event, sendEmail = false) => {
  if (!event?.id) {
    throw new Error('Invalid event ID received')
  }
  let URL = `manage/event/confirm/${event.id}`
  if (sendEmail) {
    URL += '/t'
  }
  return fetchApi(URL)
}

const cancelEvent = async (event = '', sendEmail = false) => {
  if (!event) {
    throw new Error('Invalid event ID received')
  }

  let URL = `manage/event/cancel/${event}`
  if (sendEmail) URL += '/t'
  // const formData = new FormData()
  // formData.append('label', eventLabel)
  return fetchApi(URL)
}

const deleteEvent = async (event = '') => {
  if (!event) {
    throw new Error('Invalid event ID received')
  }

  let URL = 'manage/event/' + event
  // const formData = new FormData()
  // formData.append('label', eventLabel)
  return fetchApi(URL, 'DELETE')
}

const eventDescripitionStr = eventData => {
  let description = ''
  if (eventData?.service === 'residential' || eventData?.service === 'portrait') {
    const packages = {
      'package-1': 'Package #1 (5 photos, 30min.)',
      'package-2': 'Package #2 (7 photos, 45min.)',
      'package-3': 'Package #3 (10 photos, 2hr.)',
      'package-4': 'Package #4 (15 photos, 2hr.)',
      // 'package-1':  {name: 'Package #1', detail: '5 photos, 30min.'},
      // 'package-2':  {name: 'Package #1', detail: '7 photos, 45min.'},
      // 'package-3':  {name: 'Package #1', detail: '10 photos, 2hr.'},
      // 'package-4':  {name: 'Package #1', detail: '15 photos, 2hr.'},
    }
    const services = {
      portrait: {
        detailLabel: '',
        detail: packages?.[eventData?.portrait_package]?.replaceAll('package-', '') || '',
        key: 'portrait',
        service: 'Portrait',
      },
      residential: {
        detailLabel: 'Photos',
        detail: eventData?.residential_photos || '',
        key: 'residential',
        service: 'Real Estate',
      },
    }

    if (services?.[eventData.service]) {
      const { detailLabel, detail, service, key } = services[eventData.service]
      description += `${detailLabel} ${detail}`
      const serviceOptions = eventData?.[`${key}_options`] || []
      if (serviceOptions?.length) {
        const optionsString =
          serviceOptions
            ?.sort((a, b) => a.localeCompare(b))
            ?.map(o => o.replaceAll('-', ' '))
            ?.join(', ') || ''
        if (description?.length) description += ','
        description += ` ${optionsString}`
      }
    }
  }
  return description
}

const eventLocationString = eventData => {
  let locationString = ''
  //123 East St. Astoria, NY 123456
  if (eventData?.location_address) locationString += `${eventData.location_address}`
  if (eventData?.location_city) locationString += ` ${eventData.location_city}`
  if (eventData?.location_state) locationString += locationString ? `, ${eventData.location_state} ` : ` ${eventData.location_state}`
  if (eventData?.location_zipcode) locationString += ` ${eventData.location_zipcode}`
  return locationString
}

const fetchEvent = async (event = null) => {
  const URL = 'manage/event'
  if (event) URL += `/${event}`
  return fetchApi(URL)
  try {
    const resp = await fetch(URL)
    // const data = await resp.json()
    // console.log(data)
    return await resp.json()
    // return data
  } catch (e) {
    console.log('error caught fetching event data')
    console.log(e)
  }
}

const updateEvent = async eventData => {
  const URL = 'manage/event'
  // const formData = new FormData()
  // formData.append('label', eventLabel)
  return postApi(URL, eventData, 'PUT')
  // try {
  //   const resp = await fetch(URL, {
  //     method: 'PUT',
  //     body: JSON.stringify(eventData),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     // body: formData,
  //   })
  //   console.log(resp)
  //   if (resp?.status !== 200) {
  //     throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error updating event')
  //   }
  //   return await resp.json()
  // } catch (e) {
  //   console.log('===error caught posting event data===')
  //   console.log(e?.message || e)
  //   throw new Error(e)
  // }
}

export { addEvent, calendarEvent, cancelEvent, confirmEvent, archiveEvent, deleteEvent, fetchEvent, updateEvent }
