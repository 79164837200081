import { useEffect, useRef, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Event,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  useBreakpoint,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import AddToCalendar from '../controls/add-to-calendar'
import { BiCalendarEdit, BiCalendarCheck, BiCalendarStar, BiCalendarX, BiSolidCalendarCheck, BiSolidCalendarEdit, BiSolidCalendarStar, BiSolidCalendarX } from 'react-icons/bi'
import { MdDelete, MdMarkEmailRead, MdOutlineDelete, MdOutlineDeleteForever, MdOutlineMarkEmailRead } from 'react-icons/md'
import { RiMailSendFill, RiMailSendLine } from 'react-icons/ri'
//extract iconBottons, or at least map eventType codes to color, icon, loader etc
import { timestampHuman } from '../../services/format.js'
const locationKeys = ['address', 'city']
const EventTileActions = ({ actionState, addToCalendar, archiveEvent, cancelEvent, confirmEvent, deleteEvent, editEvent, event, eventType, readEvent, sendEmail }) => {
  const { colorMode } = useColorMode()
  const [hoveredBtn, setHoveredBtn] = useState(null)
  const [deletePermanently, setDeletePermanently] = useState(false)
  const [expandMessage, setExpandMessage] = useState(false)
  const [serviceText, setServiceText] = useState(null)

  const { isOpen: deleteOpen, onClose: onDeleteClose, onToggle: onDeleteToggle } = useDisclosure()
  const { isOpen: confirmEmailOpen, onClose: onConfirmEmailClose, onToggle: onConfirmEmailToggle } = useDisclosure()
  const { isOpen: cancelAppointmentOpen, onClose: onCancelAppointmentClose, onToggle: onCancelAppointmentToggle } = useDisclosure()
  const { isOpen: addToCalendarOpen, onClose: onAddToCalendarClose, onToggle: onAddToCalendarToggle } = useDisclosure()

  //refs
  const sendCancelationEmailCheckbox = useRef()

  const onConfirmDelete = () => {
    onDeleteClose()
    if (deletePermanently) {
      deleteEvent(event)
    } else {
      archiveEvent(event)
    }
  }

  const onCancelAppointment = () => {
    console.log('onCancelAppointment')
    cancelEvent(event, onCancelAppointmentClose)
  }

  const onSendConfirmationEmail = () => {
    console.log('onSendConfirmationEmail')
    sendEmail(event, 'confirmation', onConfirmEmailToggle)
  }

  useEffect(() => {
    // console.log(actionState)
    const service = event?.service
    const text = { serviceDetail: null, serviceOptions: null }
    // if (service === 'general') {
    // }
    if (service === 'portrait') {
      text.serviceDetail = `Package ${event?.portrait_package?.split('-')?.[1] || '-'}`
      text.serviceOptions = event?.portrait_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    if (service === 'residential') {
      text.serviceDetail = `${event?.residential_photos} Photos`
      text.serviceOptions = event?.residential_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    setServiceText(text)
  }, [event])
  return (
    <Box
      display={'flex'}
      alignItems={'end'}
      justifyContent={'space-between'}
      // ml={{ base: 'unset', sm: 'auto' }}
      mt={{ base: 4, sm: 2 }}
      minWidth={{ base: '100%', sm: 'auto' }}
    >
      {/* GENERAL INQUERY EVENTS */}
      {eventType === 'general' && (
        <>
          <Popover isLazy isOpen={deleteOpen} placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label='Delete inquiry.'
                color={'red'}
                display={'flex'}
                icon={<Icon as={deleteOpen || hoveredBtn === 'delete' ? MdDelete : actionState?.status === 'error' ? MdOutlineDeleteForever : MdOutlineDelete} />}
                // icon={hoveredBtn === 'delete' ? <Icon as={MdDelete} /> :  <Icon as={MdOutlineDelete} />}
                isLoading={actionState?.status === 'deleting'}
                fontSize='3xl'
                ml={{ base: 0, sm: 4 }}
                onClick={onDeleteToggle}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('delete')}
                title={'Delete inquiry.'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onDeleteClose} />
              <PopoverHeader color={'red'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody>
                {actionState?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to delete this message resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                    <Text as={'p'}>Are you sure you want to permanently this message?</Text>
                    <Text as={'p'}>To archive instead, choose mark as read.</Text>
                  </>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  mt={4}
                  colorScheme='red'
                  onClick={onConfirmDelete}
                  width={'100%'}
                  // ref={initRef}
                >
                  {actionState?.status === 'error' ? 'RETRY' : 'DELETE'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton
            aria-label='Mark inquery as read.'
            color={'green'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => archiveEvent(event)}
            icon={hoveredBtn === 'read' ? <Icon as={MdMarkEmailRead} /> : <Icon as={MdOutlineMarkEmailRead} />}
            isLoading={actionState?.status === 'archiving'}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('read')}
            fontSize='3xl'
            title={'Mark as read.'}
            variant={'unstyled'}
          />
        </>
      )}
      {eventType === 'pending' && (
        <>
          <Popover isLazy isOpen={deleteOpen} placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label='Archive requested appointment'
                color={'red'}
                display={'flex'}
                fontSize='3xl'
                ml={{ base: 0, sm: 4 }}
                // onClick={() => deleteEvent(event)}
                icon={deleteOpen || hoveredBtn === 'delete' ? <Icon as={BiSolidCalendarX} /> : <Icon as={BiCalendarX} />}
                isLoading={actionState?.status === 'deleting'}
                onClick={onDeleteToggle}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('delete')}
                title={'Archive request'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onDeleteClose} />
              <PopoverHeader color={'red'} fontSize={'xl'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody fontSize={'lg'} maxWidth={'600px'} width={'100%'}>
                {actionState?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to delete this event resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                    <Text as={'p'} fontSize={'xl'}>
                      Are you sure you want to archive this event?
                    </Text>
                    <Checkbox colorScheme='orange' iconSize='4rem' isChecked={deletePermanently} my={8} onChange={e => setDeletePermanently(e.target.checked)}>
                      <Text fontSize={'lg'} fontWeight={'bold'}>
                        Delete Permanently
                      </Text>
                    </Checkbox>
                  </>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  mt={4}
                  colorScheme='red'
                  onClick={onConfirmDelete}
                  width={'100%'}
                  // ref={initRef}
                >
                  {actionState?.status === 'error' ? 'RETRY' : deletePermanently ? 'DELETE' : 'ARCHIVE'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <IconButton
            aria-label='Edit requested event'
            color={'yellow'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => editEvent(event)}
            icon={hoveredBtn === 'edit' ? <Icon as={BiSolidCalendarEdit} /> : <Icon as={BiCalendarEdit} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('edit')}
            fontSize='3xl'
            title={'Edit request'}
            variant={'unstyled'}
          />
          <IconButton
            aria-label='Approve requested appointment'
            color={'green'}
            display={'flex'}
            icon={hoveredBtn === 'approve' ? <Icon as={BiSolidCalendarCheck} /> : <Icon as={BiCalendarCheck} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('approve')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => confirmEvent(event)}
            fontSize='3xl'
            title={'Approve request'}
            variant={'unstyled'}
          />
        </>
      )}
      {eventType === 'confirmed' && (
        <>
          <Popover isLazy isOpen={cancelAppointmentOpen} placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label='Cancel Appointment'
                color={'red'}
                display={'flex'}
                icon={cancelAppointmentOpen || hoveredBtn === 'delete' ? <Icon as={BiSolidCalendarX} /> : <Icon as={BiCalendarX} />}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('delete')}
                ml={{ base: 0, sm: 4 }}
                onClick={onCancelAppointmentToggle}
                fontSize='3xl'
                title={'Cancel Appointment'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onCancelAppointmentClose} />
              <PopoverHeader color={'orange'} fontSize={'xl'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody fontSize={'lg'} maxWidth={'600px'} width={'100%'}>
                {actionState?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to send an email resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                    <Text as={'p'} fontSize={'xl'}>
                      Are you sure you want to cancel this event?
                    </Text>
                  </>
                )}
                {/* defaultChecked */}
                <Checkbox iconSize='4rem' isDisabled={true} my={8} ref={sendCancelationEmailCheckbox}>
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    Send cancelation email
                  </Text>
                </Checkbox>

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  colorScheme={actionState?.status === 'error' ? 'red' : 'orange'}
                  isLoading={actionState?.status === 'canceling'}
                  mt={4}
                  onClick={onCancelAppointment}
                  width={'100%'}
                >
                  {actionState?.status === 'error' ? 'RETRY' : 'CANCEL APPOINTMENT'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton
            aria-label='Edit event'
            color={'yellow'}
            display={'flex'}
            icon={hoveredBtn === 'edit' ? <Icon as={BiSolidCalendarEdit} /> : <Icon as={BiCalendarEdit} />}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('edit')}
            ml={{ base: 0, sm: 4 }}
            onClick={() => editEvent(event)}
            fontSize='3xl'
            title={'Edit Appointment'}
            variant={'unstyled'}
          />
          <Center height='45px' ml={{ base: 0, sm: 4 }}>
            <Divider orientation='vertical' />
          </Center>

          <Popover isLazy isOpen={confirmEmailOpen} placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label='Send confirmation email.'
                color={'green'}
                display={'flex'}
                icon={confirmEmailOpen || hoveredBtn === 'send-mail' ? <Icon as={RiMailSendFill} /> : <Icon as={RiMailSendLine} />}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('send-mail')}
                ml={{ base: 0, sm: 4 }}
                onClick={onConfirmEmailToggle}
                fontSize='3xl'
                title={'Send confirmation email.'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onConfirmEmailClose} />
              <PopoverHeader color={'green'} fontSize={'xl'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody display={'flex'} flexDir={'column'} fontSize={'lg'} justifyContent={'space-between'} maxWidth={'600px'} minHeight={'200px'} width={'100%'}>
                {actionState?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to send an email resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                    <Text as={'p'} fontSize={'xl'}>
                      Are you sure you want to send a confirmation email for this event?
                    </Text>
                    <Text as={'p'} fontSize={'sm'}>
                      Will log past email history here...
                    </Text>
                  </>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  colorScheme={actionState?.status === 'error' ? 'red' : 'green'}
                  isLoading={actionState?.status === 'loading'}
                  onClick={onSendConfirmationEmail}
                  width={'100%'}
                >
                  {actionState?.status === 'error' ? 'RETRY' : 'SEND'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover closeOnBlur={true} isLazy isOpen={addToCalendarOpen} placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label='Add Appointment to Calendar'
                color={'green'}
                display={'flex'}
                icon={addToCalendarOpen || hoveredBtn === 'add-cal' ? <Icon as={BiSolidCalendarStar} /> : <Icon as={BiCalendarStar} />}
                // isDisabled={true}
                onMouseLeave={() => setHoveredBtn(null)}
                onMouseOver={() => setHoveredBtn('add-cal')}
                ml={{ base: 0, sm: 4 }}
                onClick={onAddToCalendarToggle}
                fontSize='3xl'
                title={'Add to calendar'}
                variant={'unstyled'}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              {/* <PopoverCloseButton onClick={onAddToCalendarClose} /> */}
              {/* <PopoverHeader color={'green'} fontSize={'xl'} fontWeight={'bold'}>
                ADD TO CALENDAR
              </PopoverHeader> */}
              <PopoverBody>
                <AddToCalendar event={event} />
                {/* <Button colorScheme={actionState?.status === 'error' ? 'red' : 'orange'} ml={'auto'} onClick={onAddToCalendarClose}>
                  CLOSE
                </Button> */}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </>
      )}
      {eventType === 'history' && (
        <>
          <IconButton
            aria-label='Delete permanently.'
            color={'red'}
            display={'flex'}
            ml={{ base: 0, sm: 4 }}
            onClick={() => deleteEvent(event)}
            icon={hoveredBtn === 'delete' ? <Icon as={MdDelete} /> : <Icon as={MdOutlineDelete} />}
            isLoading={actionState?.status === 'deleting'}
            onMouseLeave={() => setHoveredBtn(null)}
            onMouseOver={() => setHoveredBtn('delete')}
            fontSize='3xl'
            title={'Delete permanently.'}
            variant={'unstyled'}
          />
        </>
      )}

      {/* {uploadItem && (
    <IconButton
      aria-label='Upload Event'
      color={eventType.eventType === 'success' ? 'green' : eventType.eventType === 'error' ? 'red' : undefined}
      display={'flex'}
      icon={<Icon as={eventType.eventType === 'success' ? MdOutlineCloudDone : eventType.eventType === 'error' ? TbCloudOff : BiCloudUpload} />}
      isLoading={eventType.eventType === 'loading'}
      ml={4}
      onClick={() => uploadItem(event)}
      fontSize='3xl'
      title={'Upload Event'}
      variant={'unstyled'}
      _hover={{
        color: 'brand.accent',
      }}
    />
  )} */}
    </Box>
  )
}
export default EventTileActions
