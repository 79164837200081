import { Box, Image } from '@chakra-ui/react'
import SocialIcons from '../social-icons/social-icons'
const FooterBar = () => {
  const currentYear = new Date().getFullYear()
  return (
    <Box
      as={'footer'}
      position={'absolute'}
      bottom={0}
      width={'100%'}
      height={'140px'}
      display={'flex'}
      flexDir={'column'}
      // pos={'absolute'}
      // left={0}
      // right={0}
      // bottom={0}
      // padding={2}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      // marginTop={'200px'}
      padding={4}
      w={'100%'}
    >
      <SocialIcons></SocialIcons>
      <p>© {currentYear} Manuel Pellón Photography </p>
      {/* <a href='mailto:contact@manuelpellon.com' title='Email'>
        <Image
          alt='icon of an email envelope'
          src={email}
          h={'auto'}
          w={'3rem'}
          marginInline={10}
        ></Image>
      </a>
      <a
        href='https://www.instagram.com/manuelpellonph/'
        target='_blank'
        title='Instagram'
        rel='noreferrer'
      >
        <Image
          alt='icon of instagram logo'
          src={insagram}
          h={'auto'}
          w={'3rem'}
          marginInline={2}
        ></Image>
      </a> */}
    </Box>
  )
}
export default FooterBar
