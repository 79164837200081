import { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Fade,
  Heading,
  Icon,
  Slide,
  Spacer,
  Text,
} from '@chakra-ui/react'
import LoaderLogo from '../../components/loader-logo'
import WeatherDay from '../weather-day'
import WeatherDetail from '../weather-detailed'
import {MdOutlineCloudOff} from 'react-icons/md'
// import WeatherIcon from '../weather-icon'
const WeatherCard = () => {
  const [weatherData, setWeatherData] = useState(null)
  const [weatherDisplayed, setWeatherDisplayed] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    //TODO: REACT-QUERY
    const fetchWeather = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + 'weather'
        // const url = 'http://localhost:3030/weather'
        const resp = await fetch(url)
        const data = await resp.json()
        setWeatherData(data)
        setWeatherDisplayed(data.current)
      } catch (e) {
        console.log('error retrieving weather data')
        console.log(e)
      }
      setIsLoading(false)
      // console.log(weatherData)
    }
    fetchWeather()
  }, [])

  return (
    <Box>
      <Box
        alignItems={'flex-end'}
        display={'flex'}
        flexDir={'row'}
        justifyContent={'space-between'}
        w={'100%'}
      >
        <Heading
          as='h3'
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl' }}
          mb={4}
          textAlign={'left'}
        >
          UPCOMING FORECAST
        </Heading>
      </Box>
      <Box display={'flex'} flexDir={'column'}>
        {isLoading ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            w={'100%'}
          >
            <LoaderLogo />
          </Box>
        ) : !weatherData ? (
          <Text as={'p'} display={'flex'} alignContent={'center'}  ><Icon as={MdOutlineCloudOff} fontSize={20} mr={2} />Forecast temporarily unavailable.</Text>
        ) : (
          <>
            <Box
              border={'2px solid'}
              justifyContent={'space-between'}
              display={'flex'}
              gridGap={2}
              // maxWidth={'710px'}
              overflowX={'auto'}
              py={1}
              w={'100%'}
            >
              {weatherData.daily.map((d, i) => (
                <Box
                  key={d.id}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flexGrow={1}
                  position={'relative'}
                >
                  <WeatherDay
                    isActive={weatherDisplayed.id === d.id}
                    selectDay={setWeatherDisplayed}
                    weather={d}
                  />
                  {i + 1 < weatherData.daily.length && (
                    <Divider
                      color={'white'}
                      orientation='vertical'
                      pos={'absolute'}
                      right={'0px'}
                      height={'80%'}
                    />
                  )}
                </Box>
              ))}
            </Box>
            <Fade in={weatherDisplayed?.dt} unmountOnExit={true}>
              <WeatherDetail weather={weatherDisplayed} />
            </Fade>
          </>
        )}
      </Box>
    </Box>
  )
}

export default WeatherCard
