import { Avatar, Box, Image, Text } from '@chakra-ui/react'
import AvatarWrapper from '../avatar-wrapper/avatar-wrapper'
import { StarIcon } from '@chakra-ui/icons'
const ReviewCard = ({ review }) => {
  //TODO: install timeago

  return (
    <Box>
      <Box alignItems={'center'} display={'flex'}>
        <AvatarWrapper person={review} />
        <Box display={'flex'} flexDirection={'column'}>
          <Text as={'h4'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'capitalize'}>
            {review.name}
          </Text>
          <Text as={'h6'} mt={-2}>
            {review.date}
          </Text>
        </Box>
      </Box>
      <Box display={'flex'} py={4}>
        {[...Array(review.rating)].map((r, i) => (
          <StarIcon color={'brand.accent'} key={i}></StarIcon>
        ))}
      </Box>
      <Box textAlign={'left'}>
        <Text as={'p'}>{review.comment}</Text>
      </Box>
    </Box>
  )
}

export default ReviewCard
