import { forwardRef, useCallback, memo, useEffect, useRef, useState } from 'react'
import { Box, FormControl, FormLabel, Icon, Spinner, Text } from '@chakra-ui/react'

import { PhotoAlbum } from 'react-photo-album'
import clsx from 'clsx'
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { MdError } from 'react-icons/md'
// import imageListet from './imageList'
import Image from '../image-wrapper'
import ImageGallery from '../../components/image-gallery'
import MultiSelect from '../controls/multi-select'
import TagsSelect from '../controls/tags-select'

import { debounce } from '../../services/util'
import { fetchGalleryTagData, fetchTag } from '../../services/tag'

import './style.css'
import { postApi } from '../../services/api'

// const galleries = [
//   {
//     label: 'ART',
//     value: 'nyc',
//   },
//   {
//     label: 'PORTRAIT',
//     value: 'portrait-photography',
//   },
//   {
//     label: 'RESIDENTIAL',
//     value: 'real-estate',
//   },
// ]

const PhotoFrame = memo(
  forwardRef(function PhotoFrame(props, ref) {
    const { active, attributes, imageProps, insertPosition, layout, layoutOptions, listeners, overlay, photo } = props
    const { alt, ...restImageProps } = imageProps
    // console.log('props photo frame')
    // console.log(props)

    return (
      <div
        ref={ref}
        // style={{
        //   width: overlay
        //     ? `calc(100% - ${2 * layoutOptions.padding}px)`
        //     : style.width,
        //   padding: style.padding,
        //   marginBottom: style.marginBottom,
        // }}
        className={clsx('photo-frame', {
          overlay: overlay,
          active: active,
          insertBefore: insertPosition === 'before',
          insertAfter: insertPosition === 'after',
        })}
        {...attributes}
        {...listeners}
      >
        <Image
          imageProps={imageProps}
          layout={layout}
          layoutOptions={layoutOptions}
          photo={photo}
          // ref={ref}
          {...listeners}
          {...attributes}
          {...restImageProps}
        />
      </div>
    )
  }),
)

function SortablePhotoFrame(props) {
  // console.log('SortablePhotoFrame===')
  // console.log(props)
  const { photo, activeIndex } = props
  const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({ id: photo?.id })

  return (
    <PhotoFrame
      ref={setNodeRef}
      active={isDragging}
      insertPosition={activeIndex !== undefined && over?.id === photo?.id && !isDragging ? (index > activeIndex ? 'after' : 'before') : undefined}
      aria-label='sortable image'
      attributes={attributes}
      listeners={listeners}
      {...props}
    />
  )
}

export default function ManageImageGallery({ images }) {
  const [imageList, setImageList] = useState([])
  const [galleries, setGalleries] = useState([])
  const [galleryTags, setGalleryTags] = useState(null)
  const [galleryTagsOriginal, setGalleryTagsOriginal] = useState({})
  const [errorState, setErrorState] = useState({})
  const [loadingState, setLoadingState] = useState('')
  const [tagData, setTagData] = useState({})
  const [sortOrders, setSortOrders] = useState({})

  const renderedImageList = useRef({})
  const [activeId, setActiveId] = useState(null)
  const [gallerySelected, setGallerySelected] = useState({})
  const activeIndex = activeId ? imageList.findIndex(photo => photo?.id === activeId) : undefined

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 10 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragStart = useCallback(({ active }) => setActiveId(active.id), [])

  const handleDragEnd = useCallback(
    async event => {
      // console.log(event)
      const { active, over } = event

      if (over && active.id !== over.id) {
        let newList
        const URL = 'manage/image-gallery/sort-order/' + gallerySelected?.id || ''
        setImageList(items => {
          const oldIndex = items.findIndex(item => item.id === active.id)
          const newIndex = items.findIndex(item => item.id === over.id)
          newList = arrayMove(items, oldIndex, newIndex)
          return newList
        })
        if (newList?.length) {
          try {
            //because i cant figure out how to get current sort order of images
            //event?.over?.data?.current?.sortable?.items is 1 behind
            const order = newList.map(({ id }) => id)
            setSortOrders({ ...sortOrders, [gallerySelected?.id]: order })
            await postApi(URL, order)
            // const data = await resp.json()
          } catch (e) {
            console.log(e.message || 'error')
          } finally {
            // console.log('done')
          }
        }
      }
      setActiveId(undefined)
    },
    [gallerySelected?.id],
  )

  const onGalleryTagsChange = async e => {
    const URL = process.env.REACT_APP_API_URL + 'manage/tag/gallery/' + gallerySelected?.id || ''
    // console.log(e)
    // console.log(gallerySelected)
    const galleryId = gallerySelected?.id
    if (!galleryId) {
      console.error('no gallery id...should not happen!')
      return
    }
    setErrorState('subtags')
    setLoadingState('subtags')
    const newValues = e
    // const newValues = e?.sort((a, b) => a?.label?.localeCompare(b?.label))

    setGalleryTags({ ...galleryTags, [galleryId]: newValues })
    debounce(async () => {
      try {
        const selectedTagIds = newValues?.map(t => t.id)
        const resp = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify({ tags: selectedTagIds }),
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          credentials: 'include',
          withCredentials: true,
        }).then(r => r?.json())
        // console.log(resp)
        if (resp?.error) throw new Error(resp.error)
        setGalleryTagsOriginal({ ...galleryTagsOriginal, [galleryId]: newValues })
      } catch (err) {
        console.log(err?.message || err || 'error saving tags!')
        setGalleryTags({ ...galleryTags, [galleryId]: galleryTagsOriginal[galleryId] })
        setErrorState({ ...errorState, subtags: err?.message || err || 'Error saving gallery tags' })
      } finally {
        setLoadingState('')
      }
    }, 2000)()
  }

  const renderPhoto = props => {
    // capture rendered imageList for future use in DragOverlay
    // console.log('props render photo')
    // console.log(props)
    renderedImageList.current[props?.photo?.id] = props
    return <SortablePhotoFrame activeIndex={activeIndex} {...props} />
  }

  useEffect(() => {
    // console.log(images)
    // console.log(gallerySelected)
    // console.log(!gallerySelected?.id)
    if (!gallerySelected?.id) return
    // console.log(!gallerySelected?.id)
    // console.log(gallerySelected)
    // console.log(tagData)
    if (gallerySelected?.tags?.length) {
      const selectedTags = gallerySelected?.tags?.map(t => tagData[t])
      // console.log(selectedTags)
      setGalleryTags({ ...galleryTags, [gallerySelected.id]: selectedTags })
      setGalleryTagsOriginal({ ...galleryTags, [gallerySelected.id]: selectedTags })
    }

    let sortOrder = sortOrders?.[gallerySelected?.id]
    if (!sortOrder) {
      const fetchOrder = async () => {
        try {
          const URL = process.env.REACT_APP_API_URL + `manage/image-gallery/sort-order/${gallerySelected?.id}`
          const resp = await fetch(URL, {
            credentials: 'include',
            withCredentials: true,
          })
          const sortOrder = (await resp.json()) || []
          // console.log(sortOrder)
          if (sortOrder?.length > 0) {
            // console.log(sortOrder)
            setSortOrders({ ...sortOrders, [gallerySelected?.id]: sortOrder })
          }
        } catch (e) {
          console.log('error caught fetching image list')
          console.log(e)
        }
      }
      fetchOrder()
    }
  }, [gallerySelected?.id])

  useEffect(() => {
    let sortOrder = sortOrders?.[gallerySelected?.id]
    // console.log(gallerySelected?.id)
    // console.log(images.length)
    let displayedImages =
      gallerySelected?.id && images.length
        ? [...images].filter(i => {
            // let hasTag = false
            // i?.tags?.forEach((t) => {
            //   if (t.value === gallerySelected?.id) hasTag = true
            // })
            // return hasTag
            return i?.tags?.includes(gallerySelected.id)
            // return i?.tags?.findIndex(t => t?.value === gallerySelected?.id) > -1
          })
        : []

    // console.log(sortOrder)
    // console.log(displayedImages)
    if (displayedImages?.length && sortOrder?.length) {
      let sortedImages = []
      // sort images by stored sortOrder..
      // images may no longer exist so ensure no
      // undefined entries in sorted array
      sortOrder?.forEach(image_id => {
        const idx = displayedImages.findIndex(i => i.id === image_id)
        if (idx > -1) {
          sortedImages.push(displayedImages[idx])
          displayedImages = displayedImages.slice(0, idx).concat(displayedImages.slice(idx + 1))
        }
        // const img = displayedImages.find(i => i.id === image_id)
        // if (img) {
        //   sortedImages.push(img)
        // }
      })
      // console.log(sortedImages)
      console.log(displayedImages)
      if (displayedImages?.length) {
        sortedImages = [...sortedImages, ...displayedImages]
      }
      setImageList(sortedImages)
    } else {
      setImageList(displayedImages)
    }
    // console.log(imageList)
  }, [gallerySelected?.id, images, sortOrders?.[gallerySelected?.id]])

  useEffect(() => {
    // TODO: FIX
    //This will need to be done better...
    fetchTag(null, true).then(r => {
      // console.log(r)
      const baseIds = [1, 2, 3]
      for (const id of baseIds) {
        delete r[id]
      }
      setTagData(r)
    })

    fetchGalleryTagData()
      .then(resp => {
        const { galleries, galleryTags, tags } = resp
        if (galleries?.length) {
          const fixed = galleries.map(g => ({ ...g, value: g.id }))
          setGalleries(fixed)
        }
        // if (galleryTags) {
        //   setGalleryTags(galleryTags)
        // }
        // if (tags) {
        //   setTagData({ ...tagData, ...tags })
        // }
      })
      .catch(e => {
        console.error(e?.message ?? 'error getting gallery and tag data!')
      })
  }, [])

  return (
    <>
      <Box mb={8}>
        <FormControl>
          <FormLabel>Gallery</FormLabel>
          <MultiSelect isMulti={false} name={'gallery-select'} onChange={e => setGallerySelected(e)} options={galleries} placeHolder={'Select gallery'} value={gallerySelected} />
        </FormControl>
      </Box>
      {/* <Text as={'h2'} fontSize={'xl'} fontWeight={'bold'} textDecoration={'underline'} my={4}>
        Sub Tags
      </Text> */}
      <TagsSelect
        isClearable={false}
        isDisabled={!gallerySelected?.id}
        label={'Sub tags'}
        onChange={onGalleryTagsChange}
        showMultiSelectCheckbox={false}
        tagOptions={Object.values(tagData) || []}
        tags={galleryTags?.[gallerySelected?.id]?.sort((a, b) => a?.label?.localeCompare(b?.label)) || []}
      />
      <Box alignItems={'center'} display={'flex'} gap={2} height={'24px'} pl={5}>
        {loadingState === 'subtags' && (
          <>
            <Spinner size='sm' /> <Text>Saving tags...</Text>
          </>
        )}
        {errorState?.subtags && (
          <>
            <Icon as={MdError} color={'red'} size={'small'} />
            <Text color='red'>{errorState?.subtags || 'Error saving tags'}</Text>
          </>
        )}
      </Box>
      <Text as={'h2'} fontSize={'xl'} fontWeight={'bold'} textDecoration={'underline'} my={4}>
        Image Order
      </Text>
      {!imageList?.length && (
        <Text as={'p'} fontSize={'ls'} my={2}>
          Select a gallery to map sub tags and arrange images.
        </Text>
      )}
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext items={imageList}>
          <div style={{ margin: 30 }}>
            <PhotoAlbum
              breakpoints={[300, 600, 800, 1200]}
              layout={'masonry'}
              photos={imageList}
              renderPhoto={renderPhoto}
              columns={containerWidth => {
                if (containerWidth < 400) return 2
                if (containerWidth < 800) return 3
                // if (containerWidth < 800) return 4
                if (containerWidth < 1200) return 4
                return 5
              }}
            />
          </div>
        </SortableContext>
        <DragOverlay>{activeId && <PhotoFrame overlay {...renderedImageList.current[activeId]} />}</DragOverlay>
      </DndContext>
    </>
  )
}
