import { lazy, Suspense, useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import PhotoAlbum from 'react-photo-album'

import Image from '../image-wrapper'
import { createSourceSet, createSrc } from '../../services/image'

import './style.css'

const ImageGallery = ({ photos }) => {
  const [index, setIndex] = useState(-1)
  const [images, setImages] = useState([])

  useEffect(() => {
    // const images = photos.map((p) => {
    //   // console.log(createSourceSet(p))
    //   const src = createSrc(p)
    //   return { ...p, src }
    // })
    // console.log(images)
    //Testing
    // setImages([{ ...images[0], height: 100, width: 50 }])
    setImages(photos)
    // console.log(photos)
  }, [photos])

  // const slides = photos.map(({ src, width, height, images }) => ({
  //   src,
  //   width,
  //   height,
  //   srcSet: images.map((image) => ({
  //     src: image.src,
  //     width: image.width,
  //     height: image.height,
  //   })),
  // }))
  const slides = images
  // console.log(images)

  return (
    <>
      <PhotoAlbum
        breakpoints={[300, 600, 800, 1200]}
        columns={containerWidth => {
          if (containerWidth < 400) return 2
          if (containerWidth < 800) return 3
          // if (containerWidth < 800) return 4
          if (containerWidth < 1200) return 4
          return 5
        }}
        componentsProps={{ imageProps: { height: '100%' } }}
        layout={'masonry'}
        onClick={(event, photo, index) => setIndex(index)}
        photos={images}
        renderPhoto={Image}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  )
}
export default ImageGallery
