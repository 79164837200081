const reviews = [
  {
    avatar: '',
    comment:
      'MP had take so many pictures of me that will never die. Memories of such a long time of my life were captured in an incredible way for him and im very gratefull whit his amazing job and art. Ten out of ten!',
    date: 'a month ago',
    id: 2,
    name: 'Dani Brunetto',
    rating: 5,
  },

  {
    avatar: '4.png',
    comment:
      '(Translated by Google) When I was in New York I needed a photographer, Manuel was recommended to me, we had an excellent experience in terms of results and it was very pleasant to have his services, an excellent person and professional!',
    date: '4 months ago',
    id: 4,
    name: 'Liliana Gimenez',
    rating: 5,
  },
  {
    avatar: '5.png',
    comment:
      '(Translated by Google) Excellent professional! The most beautiful photos I had in my life! A beautiful experience, I recommend it !!!',
    date: '4 months ago',
    id: 5,
    name: 'Gabriela Sol Ferreira',
    rating: 5,
  },
  {
    avatar: '',
    comment: `Fabulous! Ive had several photo shoots for head shots and body shots, and Manual was by far the best! So many pics were great, I had trouble making my final choices. He set me up in settings and positions I was skeptical about, but it turns out he has a fabulous eye and a magic touch. The photos all look natural and great, not staged and heavily touched up. On top of all this, he is the warmest, nicest, kindest person. I recommend him wholeheartedly.`,
    date: '4 months ago',
    id: 3,
    name: 'Marsha Jacobson',
    rating: 5,
  },
  {
    avatar: '6.png',
    comment:
      'Great photographer, he carefully listens to what you want to get from a shoot and professionally guides you throughout the process.',
    date: '5 months ago',
    id: 6,
    name: 'Gurgen Balasanyan',
    rating: 5,
  },
  {
    avatar: '',
    comment:
      'Amazing experience. Incredibly professional. Just delightful to be around. Pictures came out amazing!!',
    date: '5 months ago',
    id: 1,
    name: 'Kelly Howe',
    rating: 5,
  },
  {
    avatar: '7.png',
    comment:
      'The best photographer in Brooklyn! Went to Manuel for a headshot and it was the best photo taken of me ever!',
    date: '7 months ago',
    id: 7,
    name: 'Mindy Gratt',
    rating: 5,
  },
  {
    avatar: '3.png',
    comment: `I’ve used Manuel many times for different occasions. He has shot for me in professional and personal settings. He’s incredibly professional, responsive and has a keen eye for design. He makes everything he shoots look amazing! I highly recommend him!`,
    date: 'a year ago',
    id: 9,
    name: 'Christian Haag',
    rating: 5,
  },
  {
    avatar: '8.png',
    comment: `I had the opportunity to meet Manuel since we needed a real estate session for some units and our team. We couldn’t be happier and more pleased with the results. I can tell he is an excellent photographer, high quality of work, responsive, professional, attentive and overall committed to his work.
Will definitely make use of his services again and highly recommend.`,
    date: 'a year ago',
    id: 8,
    name: 'Danny Yumblatt',
    rating: 5,
  },
]

export default reviews
