import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
function ModalDialog({ isOpen = false, onClose, modalBody = '', title = '' }) {
  return (
    <Modal onClose={onClose} height={'60vh'} isOpen={isOpen} isCentered size={'xl'}>
      <ModalOverlay />

      <ModalContent borderRadius={2}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modalBody}</ModalBody>
        {/* <ModalFooter> */}
        {/* <Button onClick={onClose} mr={'auto'} variant={'none'}>
            Cancel
          </Button> */}
        {/* </ModalFooter> */}
      </ModalContent>
    </Modal>
  )
}
export default ModalDialog
