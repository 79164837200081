const portraitOptions = {
  base: [
    {
      costLocal: '$250',
      description: '5 photos, 30min.',
      icon: '',
      isBaseOption: true,
      label: 'Package #1',
      value: 'package-1',
    },
    {
      costLocal: '$300',
      description: '7 photos, 45min.',
      icon: '',
      isBaseOption: true,
      label: 'Package #2',
      value: 'package-2',
    },
    {
      costLocal: '$400',
      description: '10 photos, 2hr.',
      icon: '',
      isBaseOption: true,
      label: 'Package #3',
      value: 'package-3',
    },
    {
      costLocal: '$500',
      description: '15 photos, 2hr.',
      icon: '',
      isBaseOption: true,
      label: 'Package #4',
      value: 'package-4',
    },
  ],
  extra: [
    {
      icon: '',
      isBaseOption: false,
      label: 'Hair Stylist',
      value: 'hair-stylist',
    },
    {
      icon: '',
      isBaseOption: false,
      label: 'Makeup Artist',
      value: 'makeup-artist',
    },
  ],
}

const residentialOptions = [
  // base: [
  // {
  //   label: 'Basic Declutter',
  //   value: 'basic-declutter',
  //   costLocal: '$25.00',
  // },
  // {
  //   icon: '',
  //   label: 'Cleaning services',
  //   value: 'cleaning',
  // },
  // {
  //   icon: '',
  //   label: 'Floor plans',
  //   value: 'floor-plans',
  // },
  {
    label: 'Declutter - Basic',
    value: 'basic-declutter',
    costLocal: '$25.00',
  },
  {
    label: 'Declutter - Full',
    value: 'full-declutter',
    costLocal: '$50.00',
  },
  {
    label: 'Floor Plan (< 2000 sqft)',
    value: 'small-floorplan',
    costLocal: '$50.00',
  },
  {
    label: 'Floor Plan (2000+ sqft)',
    value: 'large-floorplan',
    costLocal: '$90.00',
  },
  {
    label: 'Declutter - Full',
    value: 'full-declutter',
    costLocal: '$50.00',
  },
  {
    label: 'Itemized Virtual Staging',
    value: 'itemized-virtual-staging',
    costLocal: '$35.00',
  },
  // {
  //   label: 'Full Virtual Staging',
  //   value: 'full-virtual-staging',
  // },

  {
    label: 'Full Declutter & Virtual Staging',
    value: 'full-declutter-and-virtual-staging',
    costLocal: '$100.00',
  },
  // { isBaseOption: true, value: 'count-4', label: '4 photos' },
  // { isBaseOption: true, value: 'count-5', label: '5 photos' },
  // { isBaseOption: true, value: 'count-6', label: '6 photos' },
  // { isBaseOption: true, value: 'count-7', label: '7 photos' },
  // { isBaseOption: true, value: 'count-8', label: '8 photos' },
  // { isBaseOption: true, value: 'count-9', label: '9 photos' },
  // { isBaseOption: true, value: 'count-10', label: '10 photos' },
  // { isBaseOption: true, value: 'count-11', label: '11 photos' },
  // { isBaseOption: true, value: 'count-12', label: '12 photos' },
  // { isBaseOption: true, value: 'count-13', label: '13 photos' },
  // { isBaseOption: true, value: 'count-14', label: '14 photos' },
  // { isBaseOption: true, value: 'count-15', label: '15 photos' },
  // { isBaseOption: true, value: 'count-16', label: '16 photos' },
  // { isBaseOption: true, value: 'count-17', label: '17 photos' },
  // { isBaseOption: true, value: 'count-18', label: '18 photos' },
  // { isBaseOption: true, value: 'count-19', label: '19 photos' },
  // { isBaseOption: true, value: 'count-20-22', label: '20-22 photos' },
  // { isBaseOption: true, value: 'count-23-25', label: '23-25 photos' },
  // ],
  // extra: [
  //   {
  //     label: 'Additional Photos',
  //     value: 'additional-photos',
  //   },
  // {
  //   label: 'Basic Declutter',
  //   value: 'basic-declutter',
  // },
  // {
  //   icon: '',
  //   label: 'Cleaning services',
  //   value: 'cleaning',
  // },
  // {
  //   icon: '',
  //   label: 'Floor plans',
  //   value: 'floor-plans',
  // },
  // {
  //   label: 'Full Declutter',
  //   value: 'full-declutter',
  // },
  // {
  //   label: 'Full Virtual Staging',
  //   value: 'full-virtual-staging',
  // },
  // {
  //   label: 'Full declutter & virtual Staging',
  //   value: 'full-declutter-virtual-staging',
  // },
]

const serviceOptions = [
  {
    icon: '',
    label: 'General Inquiry',
    value: 'general',
  },
  {
    icon: '',
    label: 'Portrait Photography',
    // value: 'portrait-photography',
    value: 'portrait',
  },
  {
    icon: '',
    label: 'Residential Photography',
    value: 'residential',
  },
]

const virtualStagingOptions = [
  {
    label: 'Full Virtual Staging',
    value: 'full-virtual-staging',
  },
  {
    label: 'Full declutter & virtual Staging',
    value: 'full-virtual-staging-and-declutter',
  },
]
export {
  portraitOptions,
  residentialOptions,
  serviceOptions,
  virtualStagingOptions,
}
