import { Link as RouterLink } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'

const PrimaryButton = ({
  btnProps = {},
  icon,
  label = 'SCHEDULE',
  size,
  to,
}) => {
  return (
    <Button
      as={RouterLink}
      display={'flex'}
      alignItems={'center'}
      colorScheme={'brand'}
      leftIcon={icon || <CalendarIcon mr={2} boxSize={'.75em'} />}
      // mt={{ base: 0, md: 4 }}
      px={'.5em'}
      py={'.25em'}
      rounded={'none'}
      size={size || 'md'}
      textTransform={'uppercase'}
      transformOrigin='left top'
      to={to}
      transition={'all 0.2s ease-in-out'}
      variant='outline'
      // bg={useColorModeValue('gray.900', 'gray.50')}
      // color={useColorModeValue('white', 'gray.900')}
      _hover={{
        // boxShadow: 'lg',
        boxShadow: '0 0 0 2px var(--chakra-colors-brand-accent)',
        borderColor: 'transparent',
        // borderWidth: '2.5px',
        color: 'brand.accent',
        // px: 'calc(.5em - 2.5px )',
        // py: 'calc(.25em - 2.5px )',
      }}
      {...btnProps}
    >
      {label}
    </Button>
  )
}
export default PrimaryButton
