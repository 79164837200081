import { useEffect, useState } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import WeatherIcon from '../weather-icon'
const WeatherDetail = ({ weather }) => {
  const [dateDisplayed, setDateDisplayed] = useState('')
  const [isToday, setIsToday] = useState(false)
  const [weatherDt, setWeatherDt] = useState(null)

  const getTime = (dt) => {
    const timeStr = new Date(dt * 1000).toLocaleTimeString()
    const [time, period] = timeStr.split(' ')
    const timeArr = time.split(':')
    timeArr.pop()
    return `${timeArr.join(':')} ${period}`
  }

  const getDateDisplayed = (dt) => {
    const detailDtOptions = {
      weekday: 'long',
      year: undefined,
      month: 'short',
      day: 'numeric',
    }
    const dtString = dt.toDateString(detailDtOptions)
    const [day, month, date] = dtString.split(' ')

    return `${day}, ${month} ${date}`
  }

  useEffect(() => {
    const dt = new Date(weather.dt * 1000)
    setWeatherDt(dt)
    const now = new Date()
    const todayString = now.toDateString()
    const weatherString = dt.toDateString()
    const today = todayString === weatherString
    setIsToday(today)
    if (today) {
      setDateDisplayed('Current Weather')
      return
    }
    setDateDisplayed(getDateDisplayed(dt))
  }, [weather])

  return (
    <Box display={'flex'} flexDir={{ base: 'column' }} gap={4} w={'100%'}>
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        gap={'0px  .5rem'}
        gridColumn={'span 2'}
        mt={2}
      >
        <Text>
          <strong>Humidity:</strong> {weather.humidity || 0}%
        </Text>
        <Text>
          <strong>Overcast:</strong> {weather.clouds || 0}%
        </Text>
        <Text>
          <strong>Sunrise:</strong> {getTime(weather.sunrise) || 'na'}
        </Text>
        <Text>
          <strong>Sunset:</strong> {getTime(weather.sunset) || 'na'}
        </Text>
        <Text>
          <strong>UVI:</strong> {Math.round(weather.uvi || 0)}
        </Text>
        <Text>
          <strong>Wind:</strong> {Math.round(weather.wind_speed || 0)}mph
        </Text>
        {/* <UnorderedList
          lineHeight={1.4}
          listStyleType={'none'}
          marginInlineStart={0}
        >
          <ListItem>Humidity: {weather.humidity || 0}%</ListItem>
          <ListItem>Overcast: {weather.clouds || 0}%</ListItem>
          <ListItem>Sunrise: {getTime(weather.sunrise) || 'na'}</ListItem>
          <ListItem>Sunset: {getTime(weather.sunset) || 'na'}</ListItem>
          <ListItem>UV Index: {Math.round(weather.uvi || 0)}</ListItem>
          <ListItem>Wind: {Math.round(weather.wind_speed || 0)}mph</ListItem>
        </UnorderedList> */}
      </Box>
      <Box display={'flex'}>
        <Box display={'flex'} flexShrink={1}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            mt={-2}
          >
            <Box display={'flex'} flexDir={'column'}>
              <Text as={'h6'} fontSize='4xl' fontWeight={'bold'}>
                {Math.round(weather.temp)}&deg;
              </Text>
              <Text as={'h6'} fontSize={'xs'} mt={-4}>
                Feels {Math.round(weather.feels_like)}&deg;
              </Text>
            </Box>
            <WeatherIcon iconcode={weather.icon} />
          </Box>
          <Box flexGrow={1} whiteSpace={'nowrap'}>
            <Heading
              as={'h6'}
              size='lg'
              fontWeight={'bold'}
              textTransform={'capitalize'}
            >
              {weather.description}
            </Heading>
            <Text fontSize={'xl'} fontWeight={'medium'} mt={-3}>
              {dateDisplayed}
            </Text>
            <Text as={'h6'} fontSize='sm' fontWeight={'medium'} mt={-2}>
              New York, NY
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WeatherDetail
