import { Box, Heading, Text } from '@chakra-ui/react'
import ReviewCard from '../../components/review-card'

import reviews from '../../data/reviews'

const ReviewsGrid = () => {
  return (
    <Box
      display={'grid'}
      gridGap={20}
      gridTemplateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr',
      }}
      width={'100%'}
    >
      {reviews.map((r) => (
        <ReviewCard key={r.id} review={r} />
      ))}
    </Box>
  )
}

export default ReviewsGrid
