import { IconButton, useColorMode } from '@chakra-ui/react'
import { IoCaretUpOutline } from 'react-icons/io5'
const scrollHeightBreakpoint = 1000

const ScrollTopButton = ({ scrollPos }) => {
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  return (
    <IconButton
      aria-label='Scroll To Top'
      bottom={10}
      bg={'brand.accent'}
      color='brand.primary.light'
      pointerEvents={scrollPos < scrollHeightBreakpoint && 'none'}
      transform={`scale(${scrollPos >= scrollHeightBreakpoint ? 1 : 0})`}
      fontSize='2xl'
      icon={<IoCaretUpOutline />}
      isRound={true}
      onClick={scrollToTop}
      position={'fixed'}
      right={'10'}
      variant='solid'
      transition={'all .5'}
      height={12}
      width={12}
      _hover={{
        bg: 'brand.accent',
        border: 'solid 2px',
        borderColor: 'brand.primary.light',
        transform: 'scale(1.25)',
        transition: 'all .5s',
      }}
    />
  )
}

export default ScrollTopButton
