import { Link as RouterLink } from 'react-router-dom'
import { Button, Icon, Link } from '@chakra-ui/react'
import { IoMdImages } from 'react-icons/io'

//TODO, REMOVE DEFAULT VALUES, UPDATE COMPONENTS
const SecondaryButton = ({ icon, size, text, to }) => {
  return (
    <Button
      as={RouterLink}
      colorScheme={'brand'}
      leftIcon={<Icon as={icon || IoMdImages} mr={2} boxSize={'.75em'} />}
      px={'.5em'}
      py={'.25em'}
      size={size || 'lg'}
      to={to}
      variant='none'
      textDecoration={'none'}
      backgroundImage={'linear-gradient(#ffa500, #ffa500)'}
      backgroundSize={'0% 0.1em'}
      backgroundPosition={'0% 100%'}
      backgroundRepeat={'no-repeat'}
      transition={'background-size 0.2s ease-in-out'}
      _active={{
        backgroundSize: '100% 0.1em',
        backgroundPositionX: '0%',
      }}
      _focus={{
        backgroundSize: '100% 0.1em',
        backgroundPositionX: '0%',
      }}
      _hover={{
        backgroundSize: '100% 0.1em',
        backgroundPositionX: '0%',
      }}
    >
      {text || 'GALLERY'}
    </Button>
  )
}
export default SecondaryButton
