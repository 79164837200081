import { Link } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

// const activeStyle = {
//   color: 'orange',
//   transform: 'scale(1.1)',
//   // textDecoration: 'underline',
// }

const NavLinkDesktop = ({
  color,
  end,
  flex,
  margin,
  showActive,
  size,
  text,
  to,
}) => {
  return (
    <Link
      as={NavLink}
      color={color}
      end
      flex={flex || 0}
      flexBasis={0}
      fontSize={size || '2xl'}
      fontWeight={500}
      id={`navlink-${text}`}
      m={margin || 0}
      maxW={'220px'}
      p={2}
      style={({ isActive }) =>
        isActive || showActive
          ? {
              color: 'orange',
              transform: 'scale(1.1)',
              // textDecoration: 'underline',
            }
          : undefined
      }
      textAlign={'center'}
      to={to}
      transition={'color 1.5s'}
      _hover={{
        textDecoration: 'none',
        color: 'orange',
        transform: 'scale(1.1)',
        transition: 'color .75s',
      }}
    >
      {text}
    </Link>
  )
}

export default NavLinkDesktop
