import { BrowserRouter as Router, Route, Routes, ScrollRestoration } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

// import { useState } from 'react'
// import About from './pages/about-page'
import Contact from './pages/contact-page'
import FooterBar from './components/footer-bar'
import Home from './pages/home-page'
import Login from './pages/login-page'
import Manage from './pages/manage-page'
import NavBar from './components/nav-bar'
import NotFound from './pages/not-found-page'
import Policies from './pages/policies-page'
import Portfolio from './pages/portfolio-page'
import Services from './pages/services-page'
import Team from './pages/team-page'
import VirtualStaging from './pages/virtual-staging-page'
import './App.css'

function App() {
  return (
    <Router>
      <div className='App'>
        <header className='App-header'>
          <NavBar></NavBar>
        </header>
        <main style={{ paddingBottom: '200px' }}>
          {/* TODO: SETUP DATA ROUTER */}
          {/* <ScrollRestoration /> */}
          <Routes>
            <Route path='/about' element={<Team />} />
            <Route path='/contact/' element={<Contact />}>
              <Route path=':service' />
            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='manage' element={<Manage />}>
              <Route path=':view' element={<Manage />} />
              <Route path=':view/:secondaryView' element={<Manage />} />
            </Route>
            <Route path='/policies' element={<Policies />}>
              <Route path=':policy' />
            </Route>
            <Route path='portfolio' element={<Portfolio />}>
              <Route path=':category' element={<Portfolio />} />
            </Route>
            <Route path='/services' element={<Services />}>
              <Route path=':service' element={<Portfolio />} />
            </Route>
            <Route path='/virtual-staging' element={<VirtualStaging />} />
            <Route path='/' element={<Home />} />
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </main>
        <FooterBar></FooterBar>
      </div>
    </Router>
  )
}

export default App
