import { Box, Heading, Image, Text } from '@chakra-ui/react'
import ProfileCard from '../profile-card'
import EnriqueImage from '../../images/team/ENRIQUE.JPG'
import GiselleImage from '../../images/team/GISELLE.JPG'
import ManuImage from '../../images/team/MANU.JPG'
import OscarImage from '../../images/team/OSCAR.JPG'
import teamData from '../../data/teamData'
const TeamCard = () => {
  return (
    <Box>
      <Box
        justifyContent={'start'}
        display={'grid'}
        gap={4}
        gridTemplateColumns={{ base: '1fr' }}
      >
        <ProfileCard profile={teamData[0]} profileImage={ManuImage} />
      </Box>
      {/* <Box
        justifyContent={'start'}
        display={'grid'}
        gap={4}
        gridTemplateColumns={{ base: '1fr', xl: '1fr 1fr 1fr' }}
      >
        <ProfileCard
          avatarProps={{ borderColor: 'brand' }}
          profile={teamData[1]}
          profileImage={OscarImage}
        />

        <ProfileCard
          avatarProps={{ borderColor: 'brand' }}
          profile={teamData[2]}
          profileImage={EnriqueImage}
        />
        <ProfileCard
          avatarProps={{ borderColor: 'brand' }}
          profile={teamData[3]}
          profileImage={GiselleImage}
        />
      </Box> */}
    </Box>
  )
}

export default TeamCard
