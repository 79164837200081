import { Box, List, ListItem, ListIcon, OrderedList, Text, UnorderedList } from '@chakra-ui/react'

const PortraitPolicy = () => {
  return (
    <Box fontSize={'2xl'} textAlign={'left'}>
      <OrderedList listStylePosition={'outside'}>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Arrival Time
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>Please arrive at the designated location (outdoor or indoor studio) at least 10 minutes before your scheduled appointment time. Punctuality is crucial to maximize our shooting time and ensure a relaxed atmosphere.</ListItem>
          </UnorderedList>
        </ListItem>
       
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Preparation
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>Arrive ready with your hair styled and makeup applied to your liking. If you require professional makeup services, please notify us in advance to arrange for a makeup artist (additional charges may apply).</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Clothing Selection
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>Bring a selection of clothing options suitable for your desired look and purpose of the headshots. Solid colors or simple patterns work best to keep the focus on you. Avoid overly busy patterns or logos that may distract from your face.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Clothing Readiness
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>Ensure that your clothing choices are clean, pressed, and ready to be worn. Wrinkles and creases can detract from the overall look of the photos.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Props and Accessories
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>If you wish to incorporate props or accessories into your headshots, please bring them along to the session. However, keep in mind that simplicity often yields the most timeless and versatile results.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Hydration and Comfort
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>

          Stay hydrated and comfortable throughout the session. Feel free to bring water or any personal comfort items to keep you relaxed and refreshed.
            </ListItem>
          </UnorderedList>
        </ListItem>
       
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Communication
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>If you have specific preferences or ideas for your headshots, don't hesitate to communicate them with us before or during the session. We're here to collaborate and ensure your vision is realized.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
          Relax and Enjoy
          </Text>
          <UnorderedList>
            <ListItem listStyleType={'none'}>Most importantly, relax and enjoy the experience! Our goal is to capture your unique personality and essence in a professional and flattering manner.</ListItem>
          </UnorderedList>
        </ListItem>
       
      </OrderedList>
      <Text as={'p'} mt={10}>By adhering to these protocol details, we can guarantee a successful and rewarding headshot session. If you have any further questions or concerns, please don't hesitate to reach out to us.</Text>
    </Box>
  )
}

export default PortraitPolicy
