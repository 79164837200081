import { useEffect, useRef, useState } from 'react'
import EventTileActions from '../event-tile-actions/index.js'
import EventTileDetails from '../event-tile-details/index.js'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  useColorMode,
  useDimensions,
} from '@chakra-ui/react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
import { FaStickyNote } from 'react-icons/fa'
//extract iconBottons, or at least map eventType codes to color, icon, loader etc
import { contactEmailPhoneString } from '../../services/contact.js'
import { timestampHuman } from '../../services/format.js'
const locationKeys = ['address', 'city', 'state', 'zipcode']
const statusColor = {
  confirmed: 'green',
  pending: 'yellow',
  general: 'brand.accent',
}

const EventTile = ({ actionState, addToCalendar, addContact, confirmEvent, archiveEvent, cancelEvent, deleteEvent, editEvent, event, eventType, sendEmail, status }) => {
  const msgTextRef = useRef()
  const { colorMode } = useColorMode()
  const [expandMessage, setExpandMessage] = useState(false)
  // const [showDetails, setShowDetails] = useState(eventType !== 'history')
  const [showDetails, setShowDetails] = useState(false)

  const onDetailsToggle = () => {
    const show = !showDetails
    setShowDetails(show)
    if (status === 'general') {
      setExpandMessage(show)
    }
  }

  return (
    <Box
      boxShadow={'xl'}
      borderLeftColor={statusColor[eventType] || 'unset'}
      borderWidth='1px'
      borderLeftWidth={'2px'}
      display='flex'
      flexDir={{ base: 'column', sm: 'row' }}
      flexWrap={'wrap'}
      gap={4}
      justifyContent={{ base: 'flex-end', sm: 'flex-start' }}
      key={event?.id || 'EventTile'}
      p={4}
      pb={2}
      position={'relative'}
    >
      {/* DETAILS COLUMN */}
      {/* CONTACT */}
      <Flex flexDir='column' justifyContent={'start'} width={'100%'}>
        <Flex justifyContent={'space-between'}>
          <Flex flexDir='column' flexGrow={'1'} justifyContent={'start'} mb={4}>
            <Flex flexDir='row' flexGrow={'1'} alignItems={'center'} justifyContent={'start'}>
              <Text fontSize='3xl' fontWeight={'bold'} lineHeight={6} m={0}>
                {`${event?.contact?.first_name || ''} ${event?.contact?.last_name || ''}`}
              </Text>
              {!event?.contact?.is_contact && (
                <IconButton alignSelf={'start'} height={'20px'} maxWidth={'20px'} icon={<BsPersonFillAdd />} onClick={() => addContact(event?.contact)} variant={'ghost'} />
              )}
            </Flex>
            <Text fontSize='lg' mt={1}>
              {contactEmailPhoneString(event?.contact)}
            </Text>
            {!showDetails && (
              <Box
                alignItems={'center'}
                display={'flex'}
                fontSize='lg'
                fontWeight={'bold'}
                m={0}
                opacity={eventType !== 'general' ? 1 : 0}
                position={'relative'}
                // opacity={eventType === 'history' ? 1 : 0}
                // opacity={eventType === 'history' && !showDetails ? 1 : 0}
                textTransform={'capitalize'}
                // transition={'opacity .5s ease-out'}
                width={'100%'}
              >
                <Box color={'brand.accent'}>{event.service || '-'}</Box>

                {event?.note && (
                  <Popover trigger='hover' isLazy={true} placement='right'>
                    <PopoverTrigger>
                      <Box>
                        <Icon as={FaStickyNote} color={'brand.accent'} cursor={'pointer'} fontSize={'md'} ml={4} variant={'unstyled'}></Icon>
                      </Box>
                    </PopoverTrigger>

                    <PopoverContent minHeight={'150px'} maxWidth='600px' minWidth={'400px'} width='100%'>
                      <PopoverArrow />
                      <PopoverHeader color={'brand.accent'} fontSize={'xl'} fontWeight={'bold'}>
                        EVENT NOTES
                        <PopoverCloseButton />
                      </PopoverHeader>
                      <PopoverBody fontSize={'lg'}>{event?.note}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </Box>
            )}
            {event?.req_datetime && !event?.confirmedAt && <Box fontWeight={'medium'}>REQUESTED DATE: {timestampHuman(event?.req_datetime)}</Box>}
            {event?.conf_datetime && !event?.confirmedAt && <Box fontWeight={'medium'}>TENTATIVE DATE: &nbsp; {timestampHuman(event?.conf_datetime)}</Box>}
            {event?.conf_datetime && event?.confirmedAt && <Box fontWeight={'medium'}>CONFIRMED DATE: {timestampHuman(event?.conf_datetime)}</Box>}
          </Flex>

          <Icon
            as={showDetails ? BiChevronUp : BiChevronDown}
            cursor={'pointer'}
            fontSize={'xl'}
            mb={'auto'}
            position={'absolute'}
            top={2}
            right={2}
            variant={'unstyled'}
            onClick={onDetailsToggle}
          ></Icon>
          {/* <Text pt={4}>{event?.id}</Text> */}
        </Flex>
        <Box display={'grid'} gridTemplateRows={showDetails ? '1fr' : '0fr'} transition={'all 1s'}>
          <Box overflow={'hidden'}>
            <EventTileDetails event={event} expanded={expandMessage} eventType={eventType} />
          </Box>
        </Box>
        <Flex direction={'row'} alignItems={{ base: 'flex-start', sm: 'flex-end' }} flexWrap={'wrap'} justifyContent={'space-between'} pt={showDetails ? 8 : 0}>
          <Text as={'span'} color={'gray'} fontSize='md' m={0}>
            {event?.createdAt && <span>RCV: {timestampHuman(event.createdAt)}</span>}
          </Text>
          <EventTileActions
            actionState={actionState}
            addToCalendar={addToCalendar}
            confirmEvent={confirmEvent}
            archiveEvent={archiveEvent}
            cancelEvent={cancelEvent}
            deleteEvent={deleteEvent}
            editEvent={editEvent}
            event={event}
            eventType={eventType}
            sendEmail={sendEmail}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
export default EventTile
