import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  CheckBox,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Tag,
  useColorMode,
} from '@chakra-ui/react'
//check URL for tags to enable on load
const TagsGroup = ({ onChange, tags }) => {
  const { colorMode } = useColorMode()
  const [selected, setSelected] = useState([])
  const isSelected = (tag) => {
    return selected?.findIndex((s) => s.id === tag.id) > -1
  }

  const toggleTag = (tag) => {
    const id = tag?.id
    if (!id) {
      console.error('tag id not found')
      return
    }
    const selectedItems = isSelected(tag)
      ? [...selected].filter((s) => s.id !== id)
      : [...selected, tag]
    const sorted = [...selectedItems].sort((a, b) =>
      a.label.localeCompare(b.label),
    )
    setSelected(sorted)
  }

  const getTagProps = (tag) => {
    const tagSelected = isSelected(tag)
    const tagProps = {
      default: {
        borderColor: `brand.primary.${colorMode}`,
        borderRadius: 1,
        color: `brand.primary.${colorMode}`,
        cursor: 'pointer',
        fontWeight: 'bold',
        mr: 2,
        size: { base: 'md', lg: 'lg' },
      },
      selected: {
        bg: `brand.accent`,
        borderColor: `brand.primary.${colorMode}`,
        color: `brand.primary.light`,
        // color: `brand.primary.${colorMode}`,
      },
    }
    if (tagSelected) {
      return { ...tagProps.default, ...tagProps.selected }
    }

    return { ...tagProps.default }
  }

  //for now, auto select all passed in tags
  //todo: default, select all, pull url params for specifics
  useEffect(() => {
    if (!tags?.length) return
    // setSelected([...tags])
  }, [tags])

  useEffect(() => {
    if (onChange) {
      onChange(selected)
    }
  }, [onChange, selected])

  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      {Array.isArray(tags) &&
        tags?.map((t) => {
          return (
            <Tag
              {...getTagProps(t)}
              key={t.id}
              onClick={() => toggleTag(t)}
              variant={'outline'}
            >
              {t.label}
            </Tag>
          )
        })}
    </Box>
  )
}

export default TagsGroup
