import { Container } from '@chakra-ui/react'
const VirtualStagingPage = () => {
  return (
    <Container>
      <h2>VIRTUAL STAGING EXAMPLES ?</h2>
      <h3>OR OMIT</h3>
    </Container>
  )
}

export default VirtualStagingPage
