import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useLocation, Form } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  extendTheme,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import { chakraComponents } from 'chakra-react-select'
// import { DatePicker } from 'chakra-datetime-picker'
import MultiSelect from '../controls/multi-select'

import { BiBuildingHouse, BiCalendarEvent, BiSolidBuildingHouse } from 'react-icons/bi'
import { BsFillPersonFill, BsListStars, BsPersonBoundingBox } from 'react-icons/bs'
import { FaAddressBook, FaCalendarCheck, FaCalendarPlus } from 'react-icons/fa6'
import { ImCamera } from 'react-icons/im'
import { IoGrid, IoLocationSharp } from 'react-icons/io5'
// import { LuCalendarClock } from 'react-icons/lu'
import { MdEventAvailable, MdMail, MdPhone, MdSend } from 'react-icons/md'
import { TfiLayoutGrid2Alt } from 'react-icons/tfi'
import AlertWrapper from '../alert-wrapper'

import { addEvent, updateEvent } from '../../services/event.js'
import { contactEmailPhoneString } from '../../services/contact.js'
import { timestampHuman } from '../../services/format.js'

import { portraitOptions, residentialOptions, serviceOptions, virtualStagingOptions } from '../../data/contactOptions'
import usStates from '../../data/usStates'

//Custom props for multiselect components

const customContactSelectComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Box color={props.isSelected && 'brand.accent'} display={'flex'} justifyContent={'space-between'} w={'100%'}>
        {/* <p> {JSON.stringify(props.isSelected)}</p> */}
        <Box display={'flex'} flexDir={'column'} justifyContent={'space-between'} w={'100%'}>
          <Text fontWeight={'bold'}>
            {props?.data?.last_name}, {props?.data?.first_name}
          </Text>
          {<Text>{props?.data?.email || ''}</Text>}
        </Box>
      </Box>
    </chakraComponents.Option>
  ),
  singleValue: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Box color={props.isSelected && 'brand.accent'} display={'flex'} justifyContent={'space-between'} w={'100%'}>
        xxxx
      </Box>
    </chakraComponents.Option>
  ),
}

const customServiceSelectComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Box color={props.isSelected && 'brand.accent'} display={'flex'} justifyContent={'space-between'} w={'100%'}>
        {/* <p> {JSON.stringify(props.isSelected)}</p> */}
        <Box display={'flex'} flexDir={'column'} justifyContent={'space-between'} w={'100%'}>
          <Text fontWeight={'bold'}> {props?.data?.label}</Text>
          {props?.data?.description ?? <Text fontSize={1}>{props?.data?.description}</Text>}
        </Box>
        <Text> {props?.data?.costLocal ?? ''}</Text>
      </Box>
    </chakraComponents.Option>
  ),
}

const defaultEvent = {
  conf_date: null,
  conf_datetime: null,
  conf_time: null,
  contact: {},
  contact_id: null,
  email: 'default-email-address-temp',
  event_id: null,
  location_address: '',
  location_city: '',
  location_state: '',
  location_zipcode: '',
  portrait_options: null,
  portrait_package: null,
  req_date: '',
  req_datetime: '',
  req_time: '',
  residential_options: [],
  residential_photos: null,
  service: '',
}

const EventEditForm = ({ contacts = [], onCancel, originalEvent, onUpdate }) => {
  const [actionState, setActionState] = useState({})
  const [event, setEvent] = useState({ ...defaultEvent })
  // TODO: MOVE THESE TO ACTION STATE
  const [submitResult, setSubmitResult] = useState(null)
  const [submitStatus, setSubmitStatus] = useState(null)

  //select fields
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedPortraitPackage, setSelectedPortraitPackage] = useState('')
  const [selectedPortraitOptions, setSelectedPortraitOptions] = useState([])
  const [selectNumResidentialItems, setSelectNumResidentialItems] = useState([])
  const [selectedNumResidential, setSelectedNumResidential] = useState(null)
  const [selectedResidentialOptions, setSelectedResidentialOptions] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [selectedVirtualStaging, setSelectedVirtualStaging] = useState([])

  //form state
  const [confirmedDate, setConfirmedDate] = useState(null)
  const [confirmedTime, setConfirmedTime] = useState(null)
  const [contactType, setContactType] = useState('existing')
  const [invalidFields, setInvalidFields] = useState([])
  // const [showAddressSelect, setShowAddressSelect] = useState(false)
  const [showDateSelect, setShowDateSelect] = useState(false)
  const [showPortraitSelect, setShowPortraitSelect] = useState(false)
  const [showResidentialSelect, setShowResidentialSelect] = useState(false)

  //Custom props (tobe moved)
  // const datepickerProps = {
  //   colorScheme: 'datetimepicker',
  //   currentLangKey: 'en',
  //   disableTimestampBefore: new Date().getTime(),
  //   showTimeSelector: true,
  // }

  const eventForm = useRef()
  const firstNameInputEl = useRef()
  const lastNameInputEl = useRef()
  const emailInputEl = useRef()

  const createNumResidentialItems = () => {
    //TODO!! memoize
    //creates number of photos options for real estate

    //min amount of photos, base price 180, +40 each add.
    // return new Promise(resolve => {
    let count = 4
    let cost = 180
    const costAdditionalPhoto = 40
    const items = new Array(99 - count + 1).fill('').map(() => {
      const option = {
        cost,
        costLocal: cost.toLocaleString('en-us', {
          currency: 'USD',
          style: 'currency',
        }),
        label: `${count} photos`,
        value: count.toString(),
      }
      count += 1
      cost += costAdditionalPhoto
      return option
    })
    // console.log(items)
    setSelectNumResidentialItems(items)
    // console.log(selectNumResidentialItems)
    // resolve(items)
    // })
  }

  const confirmRequestDateTime = () => {
    console.log('confirmRequestDateTime')

    setEvent(event => ({
      ...event,
      conf_date: event.req_date,
      conf_time: event.req_time,
    }))
    // setEvent(event => ({
    //   ...event,
    //   conf_date: event.req_date,
    //   conf_time: event.req_time,
    // }))
  }

  const handleNumResidential = e => {
    console.log('update event state object')
    setSelectedNumResidential(e)
  }

  const handlePortraitOptionSelect = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedPortraitOptions([])
      return
    }

    // if (action?.action === 'select-option' && action?.option.isBaseOption) {
    //   input = [...input.filter((i) => !i.isBaseOption), action?.option]
    // }

    input = input.sort((a, b) => a.label.localeCompare(b.label))
    setSelectedPortraitOptions(input)
  }

  const handlePortraitPackageSelect = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedPortraitPackage('')
      return
    }
    setSelectedPortraitPackage(input)

    // if (action?.action === 'select-option' && action?.option.isBaseOption) {
    //   input = [...input.filter((i) => !i.isBaseOption), action?.option]
    // }

    // input = input.sort((a, b) => a.label.localeCompare(b.label))
  }

  const handleResidentialInput = (input, action) => {
    if (action?.action === 'clear') {
      setSelectedResidentialOptions([])
      return
    }
    if (action?.action === 'select-option' && action?.option.isBaseOption) {
      input = [...input.filter(i => !i.isBaseOption), action?.option]
    }
    input = input.sort((a, b) => a.label.localeCompare(b.label))
    setSelectedResidentialOptions(input)
  }

  const handleServiceInput = (input, action) => {
    console.log('UPDATE EVENT OBJECT')
    if (action?.action === 'clear') {
      setSelectedService(null)
      return
    }
    setSelectedService(input)
  }

  const handleContactSelect = e => {
    setSelectedContact(e)
    console.log('handleContactSelect')
    console.log(e)
  }

  const handleStateSelect = e => {
    setSelectedState(e)
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    setActionState(as => ({ ...as, submit: 'loading' }))
    validateForm(e)
    const eventData = normalizeEventData()
    try {
      const res = eventData?.id ? await updateEvent(eventData) : await addEvent(eventData)
      if (res?.error) {
        throw new Error(res?.error || 'Error encountered')
      }
      if (res?.id) {
        onUpdate(res)
      }
    } catch (e) {
      setSubmitResult({
        status: 'error',
        message: e?.message || e || 'Error encountered while submitting.',
      })
    } finally {
      setActionState(as => ({ ...as, submit: null }))
    }

    return
  }

  // const handleVirtualStagingInput = (input, action) => {
  //   if (action?.action === 'clear') {
  //     setSelectedVirtualStaging([])
  //     return
  //   }
  //   setSelectedVirtualStaging(input)
  // }

  const normalizeEventData = () => {
    const eventData = {}
    const isNew = !event?.id

    //if editting existing event, retain these fields
    if (!isNew) {
      console.log('is not new')
      const retainedFields = ['id', 'event_id', 'req_date', 'req_datetime', 'req_time']
      for (const field of retainedFields) {
        if (event?.[field]) {
          eventData[field] = event[field]
        }
      }
    }

    //map form fields by name
    const eventFormByName = {}
    for (const field of eventForm.current) {
      const { name, value } = field
      if (name) {
        eventFormByName[name] = value
      }
    }

    // console.log(eventFormByName)

    //set new contact fields or existing contact_id
    if (contactType === 'new') {
      const contactFields = ['first_name', 'last_name', 'email', 'phone']
      eventData.contact = {}
      eventData.contact_id = null
      contactFields.forEach(f => {
        if (eventFormByName[f]) {
          eventData.contact[f] = eventFormByName[f]
        }
      })
    } else {
      eventData.contact_id = selectedContact?.id ?? null
    }

    //set unique service fields
    eventData.service = eventFormByName?.service || null
    if (eventFormByName?.service === 'residential') {
      console.log('residential---')
      const locationFields = ['location_address', 'location_city', 'location_state', 'location_zipcode']
      locationFields.forEach(f => {
        //we want to preserve removed fields
        // if (eventFormByName[f]) {
        //   eventData[f] = eventFormByName[f]
        // }
        eventData[f] = eventFormByName?.[f] ?? null
      })
      eventData.residential_photos = eventFormByName.residential_photos || null
      eventData.residential_options = selectedResidentialOptions?.map(o => o.value)
    }
    if (eventFormByName?.service === 'portrait') {
      console.log('portrait---')
      eventData.portrait_package = eventFormByName.portrait_package || null
      eventData.portrait_options = selectedPortraitOptions?.map(o => o.value)
    }

    if (eventFormByName?.service === 'residential' || eventFormByName?.service === 'portrait') {
      eventData.conf_date = eventFormByName?.conf_date || null
      eventData.conf_time = eventFormByName?.conf_time || null
    }

    //pick up here, error handling
    eventData.note = eventFormByName?.note || null

    console.log(eventFormByName)
    console.log(eventData)

    return eventData
  }

  const resetForm = () => {
    console.log('reset form...')
  }

  const validateForm = e => {
    console.log('validateForm')
    e.preventDefault()
    setSubmitResult(null)
    setInvalidFields([])
    let errorMessage = ''
    const withError = []
    if (contactType === 'new') {
      const firstNameIsValid = firstNameInputEl.current.checkValidity()
      const lastNameIsValid = lastNameInputEl.current.checkValidity()
      const emailIsValid = emailInputEl.current.checkValidity()
      if (!firstNameIsValid) {
        errorMessage += 'first name'
        withError.push(firstNameInputEl)
      }
      if (!lastNameIsValid) {
        errorMessage += errorMessage?.length ? ', last name' : 'last name'
        withError.push(firstNameInputEl)
      }
      if (!emailIsValid) {
        errorMessage += errorMessage?.length ? ', email' : 'email'
        withError.push(emailInputEl)
      }
    } else {
      console.log(selectedContact)
      if (!selectedContact) {
        errorMessage += errorMessage?.length ? ', contact' : 'contact'
        withError.push('contact')
      }
    }

    console.log(selectedService)
    if (!selectedService) {
      errorMessage += errorMessage?.length ? ', service' : 'service'
      withError.push('service')
    }
    if (withError?.length) {
      errorMessage = `Please enter a valid ${errorMessage}.`
      setInvalidFields(withError)
      setSubmitResult({
        status: 'error',
        message: errorMessage,
      })
    }

    return
  }

  useEffect(() => {
    console.log('rendered')
  }, [])

  useEffect(() => {
    //if multi select
    // const hasResidential = selectedService.find(s => s.value === 'residential') !== undefined

    const hasResidential = selectedService?.value === 'residential'
    setShowResidentialSelect(hasResidential)

    // setShowResidentialSelect(hasResidential)
    if (hasResidential) {
      if (selectNumResidentialItems?.length < 1) {
        createNumResidentialItems()
      }
      console.log(selectedState)
      if (!selectedState) {
        const state = event?.location_state || (!event?.id && 'NY')
        console.log(state)
        const locationState = usStates.find(s => s.value === state)
        if (locationState) {
          setSelectedState(locationState)
        }
      }
      // const locationData = {}
      // for (const field of locationFields) {
      //   const k = `location_${field}`
      //   locationData[k] =
      // }
    }

    const hasPortraitPhotography = selectedService?.value === 'portrait'
    setShowPortraitSelect(hasPortraitPhotography)

    // no longer showing..
    // setShowVirtualStagingSelect(
    //   selectedService.find((s) => s.value === 'virtual-staging') !== undefined,
    // )
  }, [selectNumResidentialItems, selectedService])

  useEffect(() => {
    setShowDateSelect(showPortraitSelect || showResidentialSelect)
  }, [showPortraitSelect, showResidentialSelect])

  useEffect(() => {
    if (selectedNumResidential) return
    if (event?.residential_photos) {
      const foundOption = selectNumResidentialItems?.find(o => +o.value === +event?.residential_photos)
      if (foundOption) {
        setSelectedNumResidential(foundOption)
      }
      return
    }
    setSelectedNumResidential(selectNumResidentialItems?.[0] || null)
  }, [event, selectNumResidentialItems])

  useEffect(() => {
    // console.log('ORIGINAL EVENT')
    // console.log({ ...originalEvent })
    const e = { ...defaultEvent, ...originalEvent }
    // console.log(e)
    setEvent(e)
    if (e?.service) {
      const service = serviceOptions.find(s => s.value === e.service)
      if (service) {
        setSelectedService(service)
      }

      if (e?.contact_id && contacts?.length) {
        const contact = contacts.find(c => c.id === e.contact_id)
        if (contact) {
          setSelectedContact(contact)
        }
      }

      if (e.service === 'portrait') {
        if (e?.portrait_package) {
          const portraitPackage = portraitOptions?.base?.find(p => p.value === e.portrait_package) || null
          if (portraitPackage) {
            setSelectedPortraitPackage(portraitPackage)
          }
        }
        if (e?.portrait_options?.length) {
          const selectedOptions = []
          e.portrait_options.forEach(portraitOption => {
            const foundOption = portraitOptions?.extra?.find(o => o.value === portraitOption)
            if (foundOption) {
              selectedOptions.push(foundOption)
            }
          })
          setSelectedPortraitOptions(selectedOptions)
        }
      }

      // console.log(e.service)

      if (e.service === 'residential') {
        if (e?.residential_options?.length) {
          const selectedOptions = []
          e.residential_options.forEach(residentialOption => {
            const foundOption = residentialOptions?.find(o => o.value === residentialOption)
            if (foundOption) {
              selectedOptions.push(foundOption)
            }
          })
          setSelectedResidentialOptions(selectedOptions)
        }
        // console.log(selectNumResidentialItems)
        // console.log(e?.residential_photos)
        // if (e?.residential_photos) {
        //   const foundOption = selectNumResidentialItems?.find(o => +o.value === +e?.residential_photos)
        //   console.log(foundOption)
        //   if (foundOption) {
        //     setSelectedNumResidential(foundOption)
        //   }
        // }
      }
    }
  }, [originalEvent])

  return (
    <Box>
      <Box
        as={'form'}
        bg={'transparent'}
        // border={'solid'}
        // borderColor={'brand.primary'}
        // borderWidth={1.5}
        // borderRadius='none'
        height={'100%'}
        id={'event-form'}
        key={event}
        maxHeight={{ base: '65vh', md: '70vh' }}
        minHeight={'600px'}
        name={'event-form'}
        onSubmit={handleSubmitForm}
        overflowY={'auto'}
        p={{ base: 4, md: 8 }}
        ref={eventForm}
        // color={'brand.primary'}
        shadow='base'
      >
        <FormControl>
          {/* <FormLabel>Services</FormLabel> */}
          <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} justifyContent={'space-between'} lineHeight={'22px'}>
            <Box display={'flex'} alignContent={'center'} alignItems={'end'}>
              <Icon as={BsFillPersonFill} boxSize={'22px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Contact
              </Text>
            </Box>
            <RadioGroup isDisabled={event?.contact_id} name='contact-type' onChange={setContactType} value={contactType}>
              <Stack direction='row'>
                <Radio value='existing'>Existing</Radio>
                <Radio value='new'>New</Radio>
              </Stack>
            </RadioGroup>
          </FormLabel>
        </FormControl>

        <VStack height='100%' spacing={10}>
          {contactType === 'existing' && (
            <FormControl>
              <InputGroup display={'flex'} alignItems={'center'} my={4}>
                {/* <Icon as={ImCamera} mr={2} boxSize={'20px'} /> */}

                <MultiSelect
                  isMulti={false}
                  name={'contact_id'}
                  onChange={handleContactSelect}
                  options={contacts?.filter(c => c.is_contact)?.sort((a, b) => a?.last_name.localeCompare(b?.last_name))}
                  selectComponents={customContactSelectComponents}
                  value={selectedContact}
                />
              </InputGroup>
            </FormControl>
          )}

          {/* NAME INPUT */}
          {contactType === 'new' && (
            <>
              <FormControl>
                <SimpleGrid columns={{ base: 1, md: 2 }} overflow={'hidden'} spacing={{ base: 4 }}>
                  <FormControl isRequired isInvalid={invalidFields.includes(firstNameInputEl)}>
                    <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        First
                      </Text>
                    </FormLabel>
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.contact?.first_name}
                      focusBorderColor={'brand.accent'}
                      name='first_name'
                      onFocus={() => setInvalidFields(invalidFields.filter(i => i !== firstNameInputEl))}
                      // placeholder='Full Name*'
                      ref={firstNameInputEl}
                      type='text'
                    />
                    {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
                  </FormControl>
                  <FormControl isRequired isInvalid={invalidFields.includes(lastNameInputEl)}>
                    <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        Last
                      </Text>
                    </FormLabel>
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.contact?.last_name}
                      focusBorderColor={'brand.accent'}
                      name='last_name'
                      onFocus={() => setInvalidFields(invalidFields.filter(i => i !== lastNameInputEl))}
                      // placeholder='Full Name*'
                      ref={lastNameInputEl}
                      type='text'
                    />
                    {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
                  </FormControl>
                </SimpleGrid>
              </FormControl>
              <SimpleGrid columns={{ base: 1, md: 2 }} overflow={'hidden'} spacing={{ base: 4 }} width={'100%'}>
                {/* EMAIL INPUT */}

                <FormControl isRequired isInvalid={invalidFields.includes(emailInputEl)}>
                  {/* <p>TODO: group these into "contact"?</p>
          <Icon as={FaAddressBook} boxSize={'22px'} mr={1} /> */}
                  <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                    <Icon as={MdMail} boxSize={'22px'} mr={1} />

                    <Text as={'span'} fontSize={'lg'}>
                      Email Address
                    </Text>
                  </FormLabel>

                  <InputGroup display={'flex'} alignItems={'center'}>
                    {/* <Icon as={MdMail} mr={2} boxSize={'20px'} /> */}
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.contact?.email}
                      focusBorderColor={'brand.accent'}
                      name='email'
                      onFocus={() => setInvalidFields(invalidFields.filter(i => i !== emailInputEl))}
                      // placeholder='Email*'
                      ref={emailInputEl}
                      type='email'
                    />
                  </InputGroup>
                  {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
                </FormControl>

                {/* PHONE INPUT */}

                <FormControl>
                  {/* <FormLabel>Phone</FormLabel> */}
                  <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1} mt={{ base: 4, md: 0 }}>
                    <Icon as={MdPhone} boxSize={'22px'} mr={1} />
                    <Text as={'span'} fontSize={'lg'}>
                      Telephone Number
                    </Text>
                  </FormLabel>
                  <InputGroup display={'flex'} alignItems={'center'}>
                    {/* <Icon as={MdPhone} mr={2} boxSize={'20px'} /> */}
                    {/* <InputLeftElement children={<MdPhone />} /> */}
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.contact?.phone}
                      focusBorderColor={'brand.accent'}
                      name='phone'
                      // placeholder='Phone number'
                      type='tel'
                    />
                  </InputGroup>
                </FormControl>
              </SimpleGrid>
            </>
          )}
          <FormControl>
            {/* <FormLabel>Services</FormLabel> */}
            <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={2} onClick={e => e.preventDefault()}>
              <Icon as={IoGrid} boxSize={'20px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Services
              </Text>
            </FormLabel>

            <InputGroup display={'flex'} alignItems={'center'}>
              {/* <Icon as={ImCamera} mr={2} boxSize={'20px'} /> */}
              <MultiSelect
                isMulti={false}
                name={'service'}
                onChange={handleServiceInput}
                options={serviceOptions}
                // placeHolder={'Services'}
                selectComponents={customServiceSelectComponents}
                value={selectedService}
              />
            </InputGroup>
          </FormControl>

          {showPortraitSelect && (
            <FormControl>
              <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={{ base: 4 }}>
                <Icon as={BsPersonBoundingBox} boxSize={'20px'} mr={1} />

                <Text as={'span'} fontSize={'lg'}>
                  Portrait Options
                </Text>
              </FormLabel>
              <InputGroup display={'flex'} alignItems={'flex-start'} flexDir={{ base: 'column', md: 'row', xl: 'column' }} flexWrap={'1'}>
                <FormControl maxW={{ base: '100%', md: '30%', xl: '100%' }} pr={{ base: 0, md: 4, xl: 0 }}>
                  <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                    <Text as={'span'} fontSize={'sm'}>
                      Package
                    </Text>
                  </FormLabel>
                  <MultiSelect
                    isMulti={false}
                    name={'portrait_package'}
                    onChange={setSelectedPortraitPackage}
                    options={portraitOptions.base}
                    selectComponents={customServiceSelectComponents}
                    value={selectedPortraitPackage}
                  />
                </FormControl>
                <FormControl mt={{ base: 4, md: 0, xl: 4 }}>
                  {/* <FormLabel>Services</FormLabel> */}

                  <Text as={'span'} fontSize={'sm'}>
                    Additional Options
                  </Text>
                  <MultiSelect
                    name={'portrait_options'}
                    onChange={setSelectedPortraitOptions}
                    options={portraitOptions.extra}
                    placeHolder={'Select...'}
                    selectComponents={customServiceSelectComponents}
                    // placeHolder={'Portrait Options'}
                    value={selectedPortraitOptions}
                  />
                </FormControl>
              </InputGroup>
              <InputGroup display={'flex'} alignItems={'center'}>
                {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
                {/* <InputLeftElement children={<MdPhone />} /> */}
              </InputGroup>
            </FormControl>
          )}
          {showResidentialSelect && (
            <>
              <FormControl>
                {/* <FormLabel>Services</FormLabel> */}

                <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={{ base: 4 }}>
                  <Icon as={BiSolidBuildingHouse} boxSize={'22px'} mr={1} />

                  <Text as={'span'} fontSize={'lg'}>
                    Real Estate Options
                  </Text>
                </FormLabel>
                <InputGroup display={'flex'} alignItems={'flex-start'} flexDir={{ base: 'column', md: 'row', xl: 'column' }} flexWrap={'1'}>
                  {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
                  <FormControl maxW={{ base: '100%', md: '30%', xl: '100%' }} pr={{ base: 0, md: 4, xl: 0 }}>
                    <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        Photos
                      </Text>
                    </FormLabel>
                    <MultiSelect
                      isMulti={false}
                      name={'residential_photos'}
                      onChange={setSelectedNumResidential}
                      options={selectNumResidentialItems}
                      selectComponents={customServiceSelectComponents}
                      value={selectedNumResidential}
                    />
                  </FormControl>
                  <FormControl mt={{ base: 4, md: 0, xl: 4 }}>
                    {/* <FormLabel>Services</FormLabel> */}

                    <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        Additional Options
                      </Text>
                    </FormLabel>
                    <MultiSelect
                      name={'residential-select'}
                      onChange={setSelectedResidentialOptions}
                      options={residentialOptions}
                      placeHolder={'Real Estate  Options'}
                      selectComponents={customServiceSelectComponents}
                      value={selectedResidentialOptions}
                    />
                  </FormControl>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={{ base: 4 }}>
                  <Icon as={IoLocationSharp} boxSize={'22px'} mr={1} />

                  <Text as={'span'} fontSize={'lg'}>
                    Location
                  </Text>
                </FormLabel>

                <SimpleGrid columns={{ base: 1, md: 2 }} overflow={'hidden'} spacing={{ base: 4 }}>
                  <FormControl>
                    <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        Address
                      </Text>
                    </FormLabel>
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.location_address || ''}
                      focusBorderColor={'brand.accent'}
                      name='location_address'
                      // placeholder='Address number'
                      type='text'
                    />
                  </FormControl>
                  <FormControl mt={{ base: 4, sm: 0 }}>
                    {/* <FormLabel>Services</FormLabel> */}

                    <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        City
                      </Text>
                    </FormLabel>
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.location_city || ''}
                      focusBorderColor={'brand.accent'}
                      name='location_city'
                      // placeholder='City number'
                      type='text'
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, sm: 2 }} overflow={'hidden'} spacing={{ base: 4 }} mt={4}>
                  {/* 
                  TODO: 
                  pickup here,
                    set breakpoints fot state/zip split
                    state label bp sm abb, lg full name etc

                */}
                  <FormControl>
                    <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        State
                      </Text>
                    </FormLabel>
                    <MultiSelect isMulti={false} name='location_state' onChange={handleStateSelect} options={usStates} value={selectedState}>
                      {/* {usStates.map(s => (
                      <option key={s.abbreviation} value={s.abbreviation}>
                        {' '}
                        {s.name}
                      </option>
                    ))} */}
                    </MultiSelect>
                    {/* <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='location_state'
                    onChange={(e) =>
                      handleLocationInput({ state: e.target.value })
                    }
                    // placeholder='Address number'
                    type='text'
                    value={location.state}
                  /> */}
                  </FormControl>
                  <FormControl mt={{ base: 4, sm: 0 }}>
                    {/* <FormLabel>Services</FormLabel> */}

                    <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                      <Text as={'span'} fontSize={'sm'}>
                        Zip code
                      </Text>
                    </FormLabel>
                    <Input
                      borderRadius={'none'}
                      defaultValue={event?.location_zipcode || ''}
                      focusBorderColor={'brand.accent'}
                      name='location_zipcode'
                      // placeholder='Zipcode number'
                      type='text'
                    />
                  </FormControl>
                </SimpleGrid>
              </FormControl>
            </>
          )}
          {/* {showVirtualStagingSelect && (
          <FormControl>
         

            <InputGroup display={'flex'} alignItems={'center'}>
              <Icon as={BsListStars} mr={2} boxSize={'20px'} />
              <MultiSelect
                name={'virtual-staging-select'}
                placeHolder={'Virtual Staging Options'}
                options={virtualStagingOptions}
                onChange={handleVirtualStagingInput}
                value={selectedVirtualStaging}
              />
            </InputGroup>
          </FormControl>
        )} */}

          {showDateSelect && (
            <FormControl>
              {event?.id && (
                <HStack align={'flex-end'} mb={4}>
                  <Text as={'div'} fontSize={'xl'} mr={2}>
                    Desired Appointment:
                  </Text>
                  {!event?.req_date && !event?.req_time ? (
                    <Text as={'div'} color={'yellow'} fontSize={'lg'}>
                      {'not set'}
                    </Text>
                  ) : (
                    <>
                      <Text as={'div'} color={'yellow'} fontSize={'lg'}>
                        {timestampHuman(event?.req_datetime, true, true, 'short') ?? ''}
                      </Text>

                      <Button colorScheme={'orange'} ml={4} mr={'auto'} onClick={confirmRequestDateTime} size='xs' variant='outline'>
                        SET AS CONFIRMED
                      </Button>
                    </>
                  )}
                </HStack>
              )}

              <FormLabel display={'flex'} alignContent={'center'} alignItems={'center'} lineHeight={'22px'} mb={4}>
                <Icon as={BiCalendarEvent} boxSize={'20px'} mr={1} />
                {/* <Icon as={LuCalendarClock} boxSize={'20px'} mr={1} /> */}
                <Text as={'div'} fontSize={'xl'} mr={2}>
                  Confirmed Appointment
                </Text>
              </FormLabel>
              {/* <DatePicker
       
          //TODO: move to comp
          {...datepickerProps}
          /> */}
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                overflow={'hidden'}
                spacing={{ base: 4 }}

                // textAlign={'left'}
              >
                {/* <Icon as={BsListStars} mr={2} boxSize={'20px'} /> */}
                <FormControl mt={{ base: 4, md: 0 }}>
                  <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                    <Text as={'span'} fontSize={'sm'}>
                      Date
                    </Text>
                  </FormLabel>
                  <Input
                    borderRadius={'none'}
                    // defaultValue={event?.conf_date}
                    focusBorderColor={'brand.accent'}
                    // min={new Date().toISOString().split('T')[0]}
                    name='conf_date'
                    onChange={e => {
                      setEvent(event => ({ ...event, conf_date: e?.target?.value || '' }))
                    }}
                    type={'date'}
                    value={event?.conf_date?.split(' ')?.[0] ?? ''}
                  />
                </FormControl>
                <FormControl mt={{ base: 4, md: 0 }}>
                  {/* <FormLabel>Services</FormLabel> */}

                  <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                    <Text as={'span'} fontSize={'sm'}>
                      Time
                    </Text>
                  </FormLabel>
                  <Input
                    borderRadius={'none'}
                    focusBorderColor={'brand.accent'}
                    name='conf_time'
                    type={'time'}
                    onChange={e => setEvent(event => ({ ...event, conf_time: e?.target?.value || '' }))}
                    value={event?.conf_time ?? ''}
                  />
                </FormControl>
              </SimpleGrid>
            </FormControl>
          )}
          <FormControl>
            {/* <FormLabel>Message</FormLabel> */}
            <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
              <Text as={'span'} fontSize={'sm'}>
                Notes (internal)
              </Text>
            </FormLabel>
            <Textarea borderRadius={'none'} defaultValue={event?.note || ''} focusBorderColor={'brand.accent'} name='note' placeholder='Notes.' rows={4} resize='none' />
          </FormControl>
        </VStack>
      </Box>
      <Divider />
      <Box d={'flex'} alignContent={'center'} minHeight={'75px'} w={'full'}>
        {submitResult && <AlertWrapper {...submitResult} onClick={() => setSubmitResult(null)}></AlertWrapper>}
      </Box>

      <Box display={'flex'} justifyContent={'space-between'} p={2}>
        <Button colorScheme={'yellow'} onClick={onCancel} variant='outline' width={'80px'}>
          CANCEL
        </Button>

        <Button
          colorScheme={'green'}
          isLoading={actionState?.submit?.status === 'loading'}
          form='event-form'
          rightIcon={event?.id ? <FaCalendarCheck /> : <FaCalendarPlus />}
          type={'submit'}
          variant='outline'
        >
          {event?.id ? 'SAVE' : 'ADD'} EVENT
        </Button>
      </Box>
    </Box>
  )
}

export default EventEditForm
