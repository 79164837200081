import { Box, Button, createIcon, Icon, IconButton, Image, Link } from '@chakra-ui/react'
import { AiFillInstagram } from 'react-icons/ai'
import { BsPhoneFill } from 'react-icons/bs'
import { MdMail } from 'react-icons/md'
const SocialIcons = ({ iconColor }) => {
  return (
    <Box display={'flex'} gap={10} padding={4} alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
      <Link
        href='mailto:contact@manuelpellon.com'
        title='Email'
        isExternal
        _hover={{
          transform: 'scale(1.2)',
          transition: 'all .2s ease-in-out',
        }}
      >
        <Icon as={MdMail} boxSize={10} color={iconColor || null} />
      </Link>

      <Link
        href='tel:+1-914-885-5968'
        title='Call me'
        _hover={{
          transform: 'scale(1.2)',
          transition: 'all .2s ease-in-out',
        }}
      >
        <Icon as={BsPhoneFill} boxSize={8} color={iconColor || null} />
      </Link>

      <Link
        href='https://www.instagram.com/manuelpellonph/'
        isExternal
        title='Instagram'
        _hover={{
          transform: 'scale(1.2)',
          transition: 'all .2s ease-in-out',
        }}
      >
        <Icon as={AiFillInstagram} boxSize={10} color={iconColor || null} />
      </Link>
    </Box>
  )
}
export default SocialIcons
