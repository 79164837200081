import { Link as RouterLink } from 'react-router-dom'
import { Box, Heading, Image, List, ListIcon, ListItem, SimpleGrid, Stack, Text, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import PrimaryButton from '../controls/primary-button'
import SecondaryButton from '../controls/secondary-button'
import VirtualStagingCard from '../virtual-staging-card'

const realEstateAdditionalServices = [
  // {
  //   description: 'Per each additional photo over 25',
  //   price: 30,
  //   service: 'Additional Photos',
  // },
  {
    description: 'Removal of specific items',
    price: 25,
    service: 'Basic Declutter',
  },
  {
    description: 'Full room declutter',
    price: 50,
    service: 'Full Declutter',
  },
  {
    description: 'Up to 2,000 sq ft',
    price: 60,
    service: 'Floor Plan',
  },
  {
    description: '2,000 sq ft and above',
    price: 90,
    service: 'Floor Plan (large)',
  },
  {
    description: 'Staging of empty room.',
    price: 35,
    service: 'Itemized Virtual Staging',
  },
  {
    description: 'Erase furniture, virtual staging.',
    price: 100,
    service: 'FULL DECLUTTER & VIRTUAL STAGING',
  },
  // {
  //   description: 'Erase  furniture, virtual staging',
  //   // description: 'Erase existing furniture, virtual staging',
  //   price: 100,
  //   service: 'Full declutter & virtual Staging',
  // },
]
const RealEstatePricingCard = () => {
  return (
    <Box
      id='residential-photography'
      display={'flex'}
      alignContent={{ base: 'center', md: 'flex-start' }}
      alignItems={{ base: 'center', md: 'flex-start' }}
      flexDir={'column'}
      justifyContent={'center'}
      mt={{ base: 0, md: 0 }}
      width={'100%'}
    >
      <Heading lineHeight={1.1} fontWeight={'bold'} fontSize={{ base: '4xl' }} my={{ base: 4 }} textAlign={'center'} textTransform={'uppercase'}>
        RESIDENTIAL PHOTOGRAPHY
      </Heading>
      <Text as={'p'} fontSize={'2xl'} lineHeight={1.2} maxWidth={'900px'} mb={2} textAlign={'left'}>
        Looking to become one of top selling realtors in one of the most competitive markets in the world?
      </Text>
      <Text as={'p'} color={'brand.accent'} fontSize={'2xl'} textAlign={'left'} mb={2} width={'100%'}>
        These are the pictures that will help you get there.
      </Text>
      <Image
        src={'https://manuelpellonphotography.imgix.net/827d9ea825032eb9bd78280847989287.jpg?dpr=1&h=1200'}
        // bgAttachment={{ base: 'fixed' }}
        bgPos={{ base: 'center center' }}
        bgRepeat={'no-repeat'}
        height={{ base: 'auto', md: 500, xl: 550 }}
        mt={8}
        objectFit={'cover'}
        width='100%'
      ></Image>
      <Stack
        direction={'row'}
        align={'center'}
        fontSize={30}
        justify={{
          base: 'space-around',
        }}
        py={14}
        w={'100%'}
      >
        <PrimaryButton size={'xl'} to={'/contact/?service=residential'}></PrimaryButton>
        <SecondaryButton size={'xl'} to={'/portfolio/residential'}></SecondaryButton>
      </Stack>

      <SimpleGrid columns={{ base: 1, md: 2 }} mt={8} overflow={'hidden'} spacing={{ base: 20 }} width={'100%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignContent={'flex-start'}
          alignItems={'flex-start'}
          fontSize={'md'}
          gap={4}
          height={'100%'}
          justifyContent={'flex-start'}
          flexGrow={1}
          pt={{ base: 10, md: 0 }}
          textAlign={'left'}
        >
          <Text as={'h6'} fontSize={'4xl'}>
            Pricing
          </Text>
          <Text as={'p'} fontSize={'2xl'} maxWidth={{ base: '90ch', xl: '60ch' }} textAlign={'left'}>
            {/* Residential photography pricing starts at $180 for 4 photos. All photos above the minimum 4 will be an addtional $40 each. All real estate services include guaranteed
            next day delivery. */}
            At just $40 per image, our residential photography rates are designed to offer affordability and transparency without any complications.
            <br />
            <br />
            <Text as={'span'} fontWeight={'bold'} mr={2}>
              $40 per Image:
            </Text>
            Whether you need one image or a dozen, each high-quality residential photograph is priced at a flat rate of $40.
            <br />
            <br />
            <Text as={'span'} fontWeight={'bold'} mr={2}>
              Minimum of 4 Images:
            </Text>
            We understand that every property deserves to be showcased in its best light. That's why we have a minimum order requirement of just 4 images to ensure your listing or
            project receives the attention it deserves.
            <br />
            <br />
          </Text>
          <Box as={'p'} fontSize={'2xl'} maxWidth={{ base: '90ch', xl: '60ch' }} pt={2} textAlign={'left'} my={'auto'}>
            With our straightforward pricing model, you can easily plan your budget without any surprises. Experience professional residential photography that's accessible and
            exceptional.
            <br />
            <br />
            Reach out to us today to schedule your session!
          </Box>
        </Box>
        <Box
          alignContent={'flex-start'}
          alignItems={'flex-start'}
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          fontSize={'md'}
          gap={4}
          height={'100%'}
          justifyContent={'flex-start'}
          pt={{ base: 10, md: 0 }}
          textAlign={'left'}
        >
          <Text as={'h6'} fontSize={'4xl'}>
            Additional Services
          </Text>
          {
            // maxWidth={'600px'}
            <Box width={'100%'} mb={8}>
              {realEstateAdditionalServices.map(i => (
                <Box
                  key={i.service}
                  display={'flex'}
                  alignItems={'center'}
                  fontSize={{}}
                  justifyContent={'space-between'}
                  // border={'.2px black solid'}
                  mb={6}
                  textAlign={'left'}
                  width={'100%'}
                  whiteSpace={'nowrap'}
                  _hover={{
                    borderColor: 'brand.accent',
                  }}
                >
                  <Box d={'flex'} flexDir={'column'}>
                    <Text fontSize={{ base: '2xl' }} fontWeight={'bold'} textTransform={'uppercase'}>
                      {i.service}
                    </Text>

                    <Text fontSize={{ base: 'xl' }} fontWeight={'normal'}>
                      {i.description}
                    </Text>
                  </Box>
                  <Text fontSize={{ base: '2xl', sm: '2xl' }}>{`$${i.price}`} </Text>
                </Box>
              ))}
            </Box>
          }
          {<VirtualStagingCard />}
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default RealEstatePricingCard
