import { useEffect, useState } from 'react'
import { vhToPx, vwToPx } from '../../services/dom'
import { createSrc } from '../../services/image'
import { Box, Image, useBreakpoint } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import LoaderLogo from '../../components/loader-logo'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import './style.css'
const ImageCarousel = ({ images, maxImageHeight }) => {
  const [show, setShow] = useState(true)
  // const [imagesDisplayed, setImagesDisplayed] = useState(images)
  const currentBP = useBreakpoint()
  const mobileBPs = ['base', 'sm', 'md']
  const isMobile = mobileBPs.includes(currentBP)
  const handler = isMobile ? 'slide' : 'fade'
  // useEffect(() => {
  //   const timeout = setTimeout(() => setShow(true), 2000)
  //   return () => clearTimeout(timeout)
  // }, [])
  // useEffect(() => {
  //   if (images)

  // }, [images])
  const imagesFixed = () => {
    const height = vhToPx(60)
    const width = vwToPx()
    if (width < height) {
      return images.map((image) => createSrc(image, null, width, 100))
    }
    return images.map((image) => createSrc(image, height, null, 100))
  }
  //TODO: SHOW LOGO OVER CAROUSEL
  // FADE OUT ON ANY IMAGE LOAD?
  // 2 SEC FOR NOW
  //MIN HEIGHT 60vh?
  return !show ? (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      w={'100%'}
    >
      <LoaderLogo />
    </Box>
  ) : (
    <Carousel
      animationHandler={handler}
      autoPlay={false}
      dynamicHeight={false}
      infiniteLoop={true}
      interval={5000}
      maxWidth={'80vw'}
      showStatus={false}
      stopOnHover
      transitionTime={2500}
      width={'100%'}
    >
      {imagesFixed().map((image, idx) => {
        return (
          <Box
            key={idx}
            display='flex'
            alignContent={'center'}
            justifyContent={'center'}
            // maxHeight='500px'
            // height={'60vh'}
            // height={'auto'}
            // maxHeight={maxImageHeight || 'auto'}
            w={'100%'}
          >
            <img
              alt={`description ${idx}`}
              loading='eager'
              src={image}
              className='carouselImage'
            />
          </Box>
        )
      })}
    </Carousel>
  )
}
export default ImageCarousel
