import { useState } from 'react'
import { HStack, Image, VStack } from '@chakra-ui/react'
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider'
import { createSrc } from '../../services/image'
import './style.css'
const ImageComparisonSlider = ({ imageSets }) => {
  const [imagesCompared, setImagesCompared] = useState(imageSets[0])
  const onSelectImageSet = imageSet => {
    setImagesCompared(imageSet)
  }
  return (
    <VStack width={'100%'}>
      <ReactCompareSlider
        className='compare-slider'
        itemOne={
          <ReactCompareSliderImage
            alt='Desc to add one'
            src={createSrc(imagesCompared.before, undefined, 800)}
            // srcSet='...'
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            alt='Desc to add two'
            src={createSrc(imagesCompared.after, undefined, 800)}
            // srcSet='...'
          />
        }
        handle={
          <ReactCompareSliderHandle
            buttonStyle={{
              height: '40px',
              width: '40px',
            }}
          />
        }
      />
      <HStack justify={{ base: 'flex-start', sm: 'center' }} spacing={4} py={4} overflowX={'auto'} width={'100%'}>
        {imageSets.map((imageSet, idx) => {
          return (
            <Image
              boxSizing='boder-box'
              {...(imageSet.id === imagesCompared.id
                ? {
                    border: 'solid 3px',
                    borderColor: 'brand.accent',
                  }
                : {
                    border: '3px solid #fff',
                    borderColor: 'brand.background',
                  })}
              // border={imageSet.id === imagesCompared.id && 'solid 2px black'}
              cursor={'pointer'}
              height={'55px'}
              key={idx}
              onClick={() => onSelectImageSet(imageSet)}
              p={'2px'}
              src={createSrc(imageSet.before, undefined, 120)}
              rounded={'none'}
              transition={'border .15s ease-in'}
              variant={'outline'}
              width={'120px'}
              _hover={{
                border: 'solid 3px',
                borderColor: 'brand.accent',
              }}
            ></Image>
          )
        })}
      </HStack>
    </VStack>
  )
}
export default ImageComparisonSlider
