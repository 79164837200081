import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Show,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react'

import ImageComparisonSlider from '../image-comparison-slider'
import PrimaryButton from '../controls/primary-button'
import SecondaryButton from '../controls/secondary-button'
import { CgDetailsMore } from 'react-icons/cg'
// import ImageComparisonSlider from '../image-carousel'

// const imageSets = [
//   {
//     id: 1,
//     before: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/415%20Bergen%20St-6.jpg',
//     after: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/415%20Bergen%20St-6_vs.jpg',
//   },
//   {
//     id: 2,
//     before: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/136%2014th%20St%2C%204A%20_%205A-16_Original.jpg',
//     after: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/136%2014th%20St%2C%204A%20_%205A-16.jpg',
//   },
//   {
//     id: 3,
//     before: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/415%20Bergen%20St-9.jpg',
//     after: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/415%20Bergen%20St-9_vs.jpg',
//   },
//   {
//     id: 4,
//     before: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/766%20Union%20St_%20CF-1.jpg',
//     after: 'https://media.manuelpellon.com/images/VIRTUAL%20STAGING/766%20Union%20St_%20CF-1_vs.jpg',
//   },
// ]
const imageSets = [
  {
    id: 1,
    before: 'VIRTUAL%20STAGING/415%20Bergen%20St-6.jpg',
    after: 'VIRTUAL%20STAGING/415%20Bergen%20St-6_vs.jpg',
  },
  {
    id: 2,
    before: 'VIRTUAL%20STAGING/136%2014th%20St%2C%204A%20_%205A-16_Original.jpg',
    after: 'VIRTUAL%20STAGING/136%2014th%20St%2C%204A%20_%205A-16.jpg',
  },
  {
    id: 3,
    before: 'VIRTUAL%20STAGING/415%20Bergen%20St-9.jpg',
    after: 'VIRTUAL%20STAGING/415%20Bergen%20St-9_vs.jpg',
  },
  {
    id: 4,
    before: 'VIRTUAL%20STAGING/766%20Union%20St_%20CF-1.jpg',
    after: 'VIRTUAL%20STAGING/766%20Union%20St_%20CF-1_vs.jpg',
  },
]

const VirtualStagingCard = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      {/* <Box as={'header'}>
        <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: 'xl' }} mb={10} textAlign={'left'}>
          VIRTUAL STAGING
        </Heading>
      </Box> */}
      <ImageComparisonSlider imageSets={imageSets}></ImageComparisonSlider>
    </Box>
  )
}

export default VirtualStagingCard
