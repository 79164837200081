const vhToPx = (vh = 0) => {
  if (!vh) {
    return window.innerWidth
  }
  if (isNaN(vh)) {
    return 0
  }
  const vhPx = window.innerWidth / 100
  return Math.round(vhPx * vh)
}
const vwToPx = (vw = null) => {
  if (!vw) {
    return window.innerWidth
  }
  if (isNaN(vw)) {
    return 0
  }
  const vwPx = window.innerWidth / 100
  return Math.round(vwPx * vw)
}

export { vhToPx, vwToPx }
