import { lazy, Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CheckBox, Container, Flex, Heading, SimpleGrid } from '@chakra-ui/react'

import TagGroup from '../../components/controls/tag-group'
import ImageGallery from '../../components/image-gallery'
import PortfolioCardGrid from '../../components/portfolio-card-grid'
import ReviewsGrid from '../../components/reviews-grid'

//utils
import { createSrc, createSrcSet } from '../../services/image'
import { fetchGalleryTagData } from '../../services/tag'
import { fetchImagesByTag } from '../../services/image'

//LAZY
const ImageCarousel = lazy(() => import('../../components/image-carousel'))

//Constants
// const photographers = ['enrique-fernandez', 'oscar-alarcon']
// const tags = {
//   art: [
//     {
//       id: 1,
//       label: 'Black & White',
//     },
//     {
//       id: 2,
//       label: 'City',
//     },
//     {
//       id: 3,
//       label: 'landscape',
//     },
//     {
//       id: 4,
//       label: 'Street',
//     },
//   ],
//   portrait: [
//     {
//       id: 1,
//       label: 'Men',
//     },
//     {
//       id: 3,
//       label: 'Outdoor',
//     },
//     {
//       id: 4,
//       label: 'Studio',
//     },
//     {
//       id: 2,
//       label: 'Women',
//     },
//   ],
//   residential: [
//     {
//       id: 2,
//       label: 'Editorial',
//     },
//     {
//       id: 1,
//       label: 'Residential',
//     },
//   ],
// }

const PortfolioPage = () => {
  const { category } = useParams()
  const [galleries, setGalleries] = useState([])
  const [gallerySelected, setGallerySelected] = useState([])
  const [photos, setPhotos] = useState({})

  // move this to computed
  const [photosDisplayed, setPhotosDisplayed] = useState({})

  const [tags, setTags] = useState({})
  const [tagData, setTagData] = useState({})
  const [tagsSelected, setTagsSelected] = useState([])

  // console.log(category)
  // console.log(galleries)

  const photosDisp = () => {
    // console.log(gallerySelected)
    if (gallerySelected?.id) {
      let images = photos?.[gallerySelected?.id]
      //if all tags or no tags selected, return all images
      // console.log(tagsSelected?.length)
      // console.log(tagData)
      // console.log(tagData?.[gallerySelected.id])
      // console.log(Object.values(tagData?.[gallerySelected.id]))
      // console.log(tagData?.[gallerySelected.id] && Object.values(tagData?.[gallerySelected.id])?.length === tagsSelected?.length)
      if (!tagsSelected?.length || gallerySelected?.tags?.length === tagsSelected?.length) {
        return images
      }
      const tagsSelectedId = tagsSelected?.map(t => t.id)
      //match any
      images = images.filter(image => {
        //match exact
        //  return image?.tags?.length && image.tags.sort((a, b) => a - b)?.join('') === tagsSelectedId?.join('')
        //match any
        return image?.tags?.filter(tag => tagsSelected?.find(selectedTag => selectedTag.id === tag))?.length > 0
      })
      // .map(i => {
      //   return { ...i, tags: i?.tags?.map(t => tags[t]) || [] }
      // })
      return images
    }
  }

  if (galleries?.length) {
    const gallery = galleries.find(g => category?.toLowerCase() === g?.label?.toLowerCase())
    if (gallery?.id !== gallerySelected?.id) setGallerySelected(gallery)
  }

  //Pickup here. on gallerySelected get photos..
  //show tags gallery tags && tag.id !== gallery.id (dont show gallery name tag)

  //fetch page data
  useEffect(() => {
    fetchGalleryTagData()
      .then(d => {
        if (d.galleries) {
          setGalleries(d.galleries)
          if (category) {
            const gallery = d.galleries.find(g => category?.toLowerCase() === g?.label?.toLowerCase())
            // console.log(gallery)
            setGallerySelected(gallery)
          }
        }
        if (d.tags) {
          setTagData(d.tags)
        }
      })
      .catch(e => console.log(e?.message ?? 'error getting tag data'))
  }, [])

  useEffect(() => {
    // console.log(tagData)
    // console.log(gallerySelected)
    // if (Object.keys(tagData)?.length) {
    //   if (gallerySelected) {
    //     const gallery = galleries.find(g => gallerySelected?.toLowerCase() === g?.label?.toLowerCase())
    //     if (gallery) {
    //       const tags = gallery?.tags?.map(t => {
    //         return tagData[t]
    //       })
    //       console.log(tags)
    //       setTags(tags || [])
    //       setTagsSelected(tags || [])
    //     }
    //   }
    //   return
    // } else {

    // }
    if (gallerySelected?.id) {
      const gallery = gallerySelected.id
      const tags = gallerySelected?.tags?.map(t => tagData[t]).sort((a, b) => a?.label.localeCompare(b?.label))
      setTags(tags)
      setTagsSelected([])
      // setTagsSelected(tags)
    }
  }, [gallerySelected, tagData])

  // useEffect(() => {
  //   if (!photos?.[gallerySelected?.id]?.length) return
  //   const tagSelectedId = tagsSelected.map(t => t.id)
  //   console.log(tagSelectedId)
  //   console.log(photos?.[gallerySelected])
  //   console.log(gallerySelected)
  //   const galleryId = galleries.find()
  //   const photosFiltered = photos?.[gallerySelected].filter(p => p?.tags?.filter(t => tagSelectedId.includes(t)) > 0)
  //   console.log(photosFiltered)
  //   setPhotosDisplayed(photosFiltered)
  // }, [gallerySelected, photos, tagsSelected])

  useEffect(() => {
    //SET LOADER
    if (!gallerySelected?.id) return

    if (!photos?.[gallerySelected?.id]) {
      fetchImagesByTag(gallerySelected?.label).then(r => setPhotos({ ...photos, [gallerySelected.id]: r }))
    }
  }, [gallerySelected])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 300)
  }, [category])

  // useEffect(() => {
  //   //SET LOADER
  //   if (!gallerySelected?.id) return

  // }, [tagsSelected])

  return (
    <>
      <Container display='flex' flexDirection={'column'} alignItems={'center'} justifyContent={'center'} maxWidth={'100vw'}>
        {/* <Heading
          letterSpacing={10}
          fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
          fontWeight={600}
          lineHeight={1.1}
          p={8}
        >
          GALLERIES
        </Heading> */}
        {/* {photos?.[gallerySelected]?.length < 1 && ( */}
        {/* <Box mb={20} /> */}
        {/* {gallerySelected === 'virtual-staging' && (
          <Heading textTransform={'uppercase'}>VIRTUAL STAGING HERE</Heading>
        )} */}
        {!photos[gallerySelected?.id] && <PortfolioCardGrid />}
        {photos?.[gallerySelected?.id]?.length > 0 ? (
          <>
            <Flex align={'start'} flexDir={'column'} justify={'start'} mb={8} py={4} w={'100%'} wrap>
              <Heading fontSize={40} mb={2} textTransform={'uppercase'}>
                {gallerySelected?.label || ''}
                {/* {gallerySelected === 'residential' ? 'RESIDENTIAL' : gallerySelected.replaceAll('-', ' ')} */}
              </Heading>
              <TagGroup onChange={s => setTagsSelected(s)} tags={tags || []} />
              {/* <TagGroup tags={tags?.[gallerySelected]} /> */}
            </Flex>
            <Box h={'100%'} w={'100%'}>
              <ImageGallery photos={photosDisp()} />
              {/* <ImageGallery photos={photosDisplayed} /> */}
              {/* <ImageGallery photos={photos[gallerySelected]} /> */}
            </Box>
          </>
        ) : (
          ''
        )}
      </Container>
      <Container display='flex' flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        {/* <Box mb={20} /> */}
        <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '5xl' }} mb={10} textAlign={'left'}>
          TESTIMONIALS
        </Heading>
        <ReviewsGrid />
      </Container>
    </>
  )
}

export default PortfolioPage
