import { Box, List, ListItem, ListIcon, OrderedList, Text, UnorderedList } from '@chakra-ui/react'

const ResidentialPolicy = () => {
  return (
    <Box fontSize={'2xl'} textAlign={'left'}>
      <OrderedList listStylePosition={'outside'}>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Preparing the property
          </Text>
          <UnorderedList>
            <ListItem>Kindly ensure that the property is clean, decluttered, and well-lit before the scheduled photoshoot.</ListItem>
            <ListItem>Open curtains and blinds to maximize natural light.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Property Access
          </Text>
          <UnorderedList>
            <ListItem>Confirm that I will have access to all rooms and outdoor spaces during the photoshoot.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Property Highlights
          </Text>
          <UnorderedList>
            <ListItem>Share any specific features or areas you would like to highlight in the photos.</ListItem>
            <ListItem>Identify unique selling points that should be emphasized.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Special Requests
          </Text>
          <UnorderedList>
            <ListItem>Inform me of any specific shots or angles you believe are crucial for the listing.</ListItem>
            <ListItem>Note if there are areas that need special attention or retouching.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Timing and Lighting
          </Text>
          <UnorderedList>
            <ListItem>Choose a time for the photoshoot when the property is well-lit, preferably during daylight hours.</ListItem>
            <ListItem>Consider scheduling when the property is likely to be at its best, showcasing its full potential.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>
            Property Readiness Checklist (Share with the owner)
          </Text>
          <UnorderedList>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
                Exterior
              </Text>
              <UnorderedList>
                <ListItem>Ensure that the lawn is well-maintained, mowed, and free of debris.</ListItem>
                <ListItem>Trim any overgrown bushes or trees that may obstruct the view of the property.</ListItem>
                <ListItem>Remove any vehicles from driveways and the front of the property.</ListItem>
                <ListItem>Clean windows and sweep pathways leading to the entrance.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
                Interior
              </Text>
              <UnorderedList>
                <ListItem>Declutter all rooms by removing personal items, excessive decorations, and unnecessary furniture.</ListItem>
                <ListItem>Ensure all light bulbs are working, and consider replacing any burnt-out bulbs.</ListItem>
                <ListItem>Open curtains and blinds to allow natural light into each room.</ListItem>
                <ListItem>Hide personal items such as family photos, mail, and toiletries.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
                Living Spaces
              </Text>
              <UnorderedList>
                <ListItem>Arrange furniture to create a sense of space and highlight the flow of the room.</ListItem>
                <ListItem>Fluff and arrange pillows and cushions for a polished look.</ListItem>
                <ListItem>Remove pet beds, toys, and bowls.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
                Kitchen
              </Text>
              <UnorderedList>
                <ListItem>Clear countertops of small appliances, dish soap, and other clutter.</ListItem>
                <ListItem>Ensure all kitchen appliances are clean and in good condition.</ListItem>
                <ListItem>Hide dish towels and cleaning supplies.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
                Bedrooms
              </Text>
              <UnorderedList>
            <ListItem>Make beds neatly and arrange pillows for an inviting appearance.</ListItem>
            <ListItem>Clear nightstands and dressers of personal items.</ListItem>
            <ListItem>Remove any laundry or personal items from the floor.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
              Bathrooms
              </Text>
              <UnorderedList>
            <ListItem>Clean and declutter bathroom countertops.</ListItem>
            <ListItem>Hide toiletries, toothbrushes, and personal items.</ListItem>
            <ListItem>Replace used towels with fresh, neatly folded ones.</ListItem>
            <ListItem>Please don’t take a shower before the photoshoot.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
              General Tips
              </Text>
              <UnorderedList>
            <ListItem>Ensure all light fixtures are working, and replace any burnt-out bulbs.</ListItem>
            <ListItem>Clean and dust all surfaces, including baseboards and corners.</ListItem>
            <ListItem>Check for any visible signs of wear and tear that can be addressed before the photoshoot.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontWeight={'bold'} mb={1}>
              Final Checks
              </Text>
              <UnorderedList>
            <ListItem>Ensure all rooms are well-lit and that there are no dark or poorly lit areas.</ListItem>
            <ListItem>Secure any valuables or personal items that you do not want featured in the photos.</ListItem>
            <ListItem>If applicable, ensure that outdoor spaces like patios and decks are clean and staged.</ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>Additional Notes</Text>
          <UnorderedList>
            <ListItem>Provide any additional information or preferences you may have regarding the photography style or image composition.</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight={'bold'} mb={1}>Contact Information</Text>
          <UnorderedList>
            <ListItem>Ensure that I have the correct contact information for both you and the property owner on the day of the photoshoot in case you won’t be there.

</ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>
      <Text as={'p'} mt={10}>Thank you for your attention to these details. I am confident that, by working together and following this protocol, we will create compelling images that effectively market the property. If you have any further questions or specific requirements, please feel free to reach out.
Looking forward to our collaboration!</Text>
    </Box>
  )
}

export default ResidentialPolicy
