import { Box, Container, Heading, Image, useColorMode } from '@chakra-ui/react'
import logoBlack from '../../images/logo-trans-black.png'
import logoWhite from '../../images/logo-trans-white.png'
const NotFoundPage = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const logo = colorMode === 'dark' ? logoWhite : logoBlack
  return (
    <Container
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-around'}
      textAlign={'center'}
    >
      <Image
        alt='Manuel Pellón Circular Logo'
        h='auto'
        maxWidth={'300px'}
        objectFit='cover'
        src={logo}
        width={'80%'}
      />
      <Heading
        lineHeight={1.1}
        fontWeight={'bold'}
        fontSize={{ base: '4xl' }}
        mt={20}
        textAlign={'center'}
        textTransform={'uppercase'}
      >
        Page not found
      </Heading>
    </Container>
  )
}

export default NotFoundPage
