const BASE_URL = process.env.REACT_APP_API_URL

const login = async ({ username, password }) => {
  const URL = BASE_URL + 'auth/login'
  try {
    const resp = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include',
      withCredentials: true,
      // body: formData,
    })
    // console.log(resp)
    if (resp?.status === 401) {
      // console.log(window.location)
      if (!window.location?.pathname?.includes('login')) {
        const URL = `${window.location.origin}/login`
        window.location.replace(URL)
      }
      return await resp.json()
    }

    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error logging')
    }
    return await resp.json()
  } catch (e) {
    console.log('===error caught posting login data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const logout = async () => {
  const URL = BASE_URL + 'auth/logout'
  try {
    const resp = await fetch(URL, {
      method: 'GET',
      credentials: 'include',
      withCredentials: true,
    })
    // console.log(resp)
    if (resp?.status === 401) {
      console.log(window.location)
      if (!window.location?.pathname?.includes('login')) {
        const URL = `${window.location.origin}/login`
        window.location.replace(URL)
      }
      return await resp.json()
    }

    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error logging')
    }
    return true
    // return await resp.json()
  } catch (e) {
    console.log('===error caught logging out===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

const testAuth = async () => {
  const URL = BASE_URL + 'auth'
  try {
    const resp = await fetch(URL, {
      method: 'GET',
      credentials: 'include',
      withCredentials: true,
    })
    console.log(resp)
    if (resp?.status === 401) {
      console.log(window.location)
      if (!window.location?.pathname?.includes('login')) {
        const URL = `${window.location.origin}/login`
        window.location.replace(URL)
      }
      throw new Error(resp)
    }

    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error logging')
    }
    return true
    // return await resp.json()
  } catch (e) {
    console.log('===error caught posting login data===')
    console.log(e?.message || e)
    throw new Error(e)
  }
}

module.exports = { login, logout, testAuth }
