import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Icon, Text, useColorMode, useDimensions } from '@chakra-ui/react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
//extract iconBottons, or at least map status codes to color, icon, loader etc
import { timestampHuman } from '../../services/format.js'
const locationKeys = ['address', 'city', 'state', 'zipcode']
const statusColor = {
  confirmed: 'green',
  pending: 'yellow',
  general: 'brand.accent',
}
const EventTileDetails = ({ event, expanded, size = 'lg', status }) => {
  const msgContainerRef = useRef()
  const msgTextRef = useRef()
  const msgContainerDimm = useDimensions(msgContainerRef)
  const msgTextDimm = useDimensions(msgTextRef)
  const { colorMode } = useColorMode()
  const [expandMessage, setExpandMessage] = useState(false)
  const [msgMultiLine, setMsgMultiLine] = useState(false)
  const [serviceText, setServiceText] = useState(null)
  const [showExpandMessage, setShowExpandMessage] = useState(false)

  useEffect(() => {
    const message = event?.message
    const service = event?.service
    const text = { serviceDetail: null, serviceOptions: null }
    // if (service === 'general') {
    // }
    const hasMultiLine = message?.includes('\n')
    if (hasMultiLine) {
      setMsgMultiLine(hasMultiLine)
      setShowExpandMessage(true)
    }
    if (service === 'portrait') {
      text.serviceDetail = `Package ${event?.portrait_package?.split('-')?.[1] || '-'}`
      text.serviceOptions = event?.portrait_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    if (service === 'residential') {
      text.serviceDetail = `${event?.residential_photos} Photos`
      text.serviceOptions = event?.residential_options?.join(', ')?.replaceAll('-', ' ') || null
    }
    setServiceText(text)
  }, [event])

  useEffect(() => {
    const msgContainerWidth = msgContainerDimm?.borderBox?.width || 0
    const msgTextWidth = msgTextDimm?.borderBox?.width || 0
    const buffer = 20
    //if there is no message or the element hasnt finished mounting
    // dont toggle visibility
    if (!event?.message || msgTextWidth < 1) {
      return
    }
    const show = msgTextWidth + buffer - msgContainerWidth > 0
    if (show) {
      setShowExpandMessage(show)
    }
  }, [event, msgContainerDimm, msgTextDimm])

  useEffect(() => {
    if (expanded && showExpandMessage) {
      setExpandMessage(expanded)
    }
  }, [expanded, showExpandMessage])

  return (
    <Flex flexDir='column' flexGrow={'1'} flexWrap={'wrap'} justifyContent={'start'}>
      {/* REQUESTED DATE */}
      {event?.req_datetime && (
        <Flex flexDir='column' alignItems={'flex-start'}>
          <Text as={'h6'} color={'brand.accent'} fontSize={size === 'md' ? '2xl' : '3xl'} fontWeight={'bold'} textTransform={'capitalize'}>
            Date Requested
          </Text>
          <Text fontSize='lg' fontWeight={'bold'} m={0} textTransform={'capitalize'}>
            {timestampHuman(event?.req_datetime, true, true, 'short')}
          </Text>
        </Flex>
      )}

      {/* SERVICE DETAILS */}
      {event?.service !== 'general' && event.service && (
        <Flex flexDir='column' alignItems={'flex-start'}>
          <Text as={'h6'} color={'brand.accent'} fontSize={size === 'md' ? '2xl' : '3xl'} fontWeight={'bold'} mt={2} textTransform={'capitalize'}>
            Services
          </Text>
          <Text fontSize='xl' fontWeight={'bold'} m={0} textTransform={'capitalize'}>
            {event.service}: {serviceText?.serviceDetail}
          </Text>

          <Text fontSize='lg' m={0}>
            {serviceText?.serviceOptions && ` ${serviceText.serviceOptions}`}
            {/* {serviceText?.serviceOptions && `Extras: ${serviceText.serviceOptions}`} */}
          </Text>
        </Flex>
      )}

      {/* RESIDENTIAL DETAILS */}
      {event?.service === 'residential' && (
        // TODO: PICKUP HERE
        // MAP LOCATION =
        <Flex flexDir='column' alignItems={'flex-start'}>
          <Text as={'h6'} color={'brand.accent'} fontSize={size === 'md' ? '2xl' : '3xl'} fontWeight={'bold'} mt={2} textTransform={'capitalize'}>
            Location
          </Text>
          {/* PICKUP HERE: FORMAT ADDRESS  */}
          {locationKeys.map(k => {
            if (event?.[`location_${k}`]) {
              return (
                <Box display={'flex'} key={k}>
                  <Text fontWeight={'bold'} mr={2} textTransform={'capitalize'}>
                    {k}
                  </Text>
                  <Text>{`${event?.[`location_${k}`] || '-'}`}</Text>
                </Box>
              )
            }
          })}
        </Flex>
      )}
      {event?.message && (
        <>
          <Text as={'h6'} color={'brand.accent'} fontSize={size === 'md' ? '2xl' : '3xl'} fontWeight={'bold'} mt={2} textTransform={'capitalize'}>
            Message
          </Text>
          <Box
            border={'solid 1.5px'}
            borderColor={`brand.primary.${colorMode}`}
            borderRadius={'md'}
            display={'flex'}
            justifyContent={'space-between'}
            height={expandMessage ? '200px' : msgMultiLine ? '80px' : 'auto'}
            mt={4}
            p={2}
            ref={msgContainerRef}
            transition={'all 1s'}
            width={'100%'}
          >
            <Text fontSize='lg' m={0} overflowY={expandMessage ? 'auto' : 'hidden'} ref={msgTextRef} textOverflow={'ellipsis'} whiteSpace={'pre-wrap'}>
              {event?.message || '-'}
              {/* {expandMessage ? event.message : event.message.split('\n')[0]} */}
              {/* Message Message Message Message Message Message Message Message
              Message Message Message Message Message Message Message Message
              Message Message Message Message Message Message Message Message
              Message Message Message Message Message Message */}
            </Text>
            {showExpandMessage && (
              <Icon
                as={expandMessage ? BiChevronUp : BiChevronDown}
                cursor={'pointer'}
                fontSize={'xl'}
                mb={'auto'}
                variant={'unstyled'}
                onClick={() => setExpandMessage(!expandMessage)}
              ></Icon>
            )}
          </Box>
        </>
      )}
      {event?.note && (
        <>
          <Text as={'h6'} color={'brand.accent'} fontSize={size === 'md' ? '2xl' : '3xl'} fontWeight={'bold'} mt={2} textTransform={'capitalize'}>
            Note
          </Text>
          <Text as={'p'} fontSize='xl'>
            {event?.note || '-'}
          </Text>
        </>
      )}
    </Flex>
  )
}
export default EventTileDetails
