import { Link as RouterLink } from 'react-router-dom'
import { SimpleGrid } from '@chakra-ui/react'
import PortfolioCard from '../../components/portfolio-card'
const PortfolioCardGrid = () => {
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      alignContent='center'
      alignItems='center'
      gap={4}
      justifyItems='center'
      overflow={'hidden'}
      spacing={{ base: 8, md: 2 }}
      width={'100%'}
      maxWidth={'1200px'}
    >
      <PortfolioCard
        bgPos={{ base: 'center center', md: 'center center' }}
        height={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
        maxW={'100%'}
        image='https://manuelpellonphotography.imgix.net/e61375cbd62dfc970c03496fba3bf02b.jpeg'
        text='RESIDENTIAL'
        textJustify={'center'}
        to={'residential'}
        width={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
      ></PortfolioCard>
      <PortfolioCard
        bgPos={{ base: 'top center', md: 'center top', lg: 'center top' }}
        height={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
        maxW={'100%'}
        image='https://manuelpellonphotography.imgix.net/951b954e4d386013f456c5811b35ae5b.jpg?dpr=1&q=75&h=536&w=424'
        text='PORTRAIT'
        textJustify={'center'}
        to={'portrait'}
        width={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
      ></PortfolioCard>
      <PortfolioCard
        bgPos={{
          base: 'center bottom',
          md: 'bottom center',
          lg: 'center bottom',
        }}
        height={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
        maxW={'100%'}
        image='https://manuelpellonphotography.imgix.net/2e886b7df2cea350e6ac12cd20323ad7.JPEG?dpr=1&q=75&h=499&w=424'
        text='ART'
        textJustify={'center'}
        to={'art'}
        width={{ base: '350px', md: '450px', lg: '300px', xl: '350px' }}
      ></PortfolioCard>
    </SimpleGrid>
  )
}

export default PortfolioCardGrid
