import { Icon } from '@chakra-ui/react'
import { WiCloud, WiCloudy, WiDayCloudy, WiDayRain, WiDaySunny, WiRain, WiSnow, WiThunderstorm, WiWindy } from 'react-icons/wi'

const WeatherIcon = props => {
  const iconsMap = [
    {
      code: '01',
      icon: WiDaySunny,
    },
    {
      code: '02',
      icon: WiDayCloudy,
    },
    {
      code: '03',
      icon: WiCloud,
    },
    {
      code: '04',
      icon: WiCloudy,
    },
    {
      code: '09',
      icon: WiDayRain,
    },
    {
      code: '10',
      icon: WiRain,
    },
    {
      code: '11',
      icon: WiThunderstorm,
    },
    {
      code: '13',
      icon: WiSnow,
    },
    {
      code: '50',
      icon: WiWindy,
    },
  ]
  const { iconcode } = props
  const codeFixed = iconcode.substring(0, 2)
  const icon = iconsMap.find(i => i.code === codeFixed)

  return <Icon as={icon.icon} boxSize={'4rem'} {...props}></Icon>
}

export default WeatherIcon
