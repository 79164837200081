import { Box, Heading, Text, Hide, Show, Stack } from '@chakra-ui/react'
import ImageCarousel from '../image-carousel'
import PrimaryButton from '../controls/primary-button'
import SecondaryButton from '../controls/secondary-button'
import { CgDetailsMore } from 'react-icons/cg'

// const carouselImages = [
//   'https://media.manuelpellon.com/images/PORTRAITS/hu300.jpg',
//   'https://manuelpellonphotography.imgix.net/2bff3e2b7ebcf725ed36d70c265bffda.jpg',
//   'https://manuelpellonphotography.imgix.net/650cdf7dcb93eede7d601805d8636bf7.JPG',
//   'https://manuelpellonphotography.imgix.net/131e2829fe7dcfce93c135682055167e.jpg',
//   'https://manuelpellonphotography.imgix.net/ecde261b67a897f331c65ae9f79ba4e1.jpg',
//   'https://media.manuelpellon.com/images/PORTRAITS/27%202.jpg',
// ]
const carouselImages = [
  '7d4bb9d791da5e89c054b00675c0606a.jpg',
  '2bff3e2b7ebcf725ed36d70c265bffda.jpg',
  '650cdf7dcb93eede7d601805d8636bf7.JPG',
  '131e2829fe7dcfce93c135682055167e.jpg',
  'ecde261b67a897f331c65ae9f79ba4e1.jpg',
  '663e7b3e1ad846fe505fd5448ab49663.jpg',
]

const ServiceCard = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      // display={'grid'}
      // gridTemplateColumns={{ base: '1fr', lg: '2fr 1fr' }}
      // spacing={{ base: 8, md: 10 }}
    >
      <Box as={'header'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '6xl' }}  textAlign={'center'}>
          PORTRAIT PHOTOGRAPHY
        </Heading>
        <Text as={'p'} fontSize={'2xl'} lineHeight={1.3} maxWidth={'900px'} py={'100px'} textAlign={'left'}>
          {/* We offer a varied approach to traditional portrait photography. In the
          effort to get a more natural photo we can pick a NYC destination and
          use the energy of the city to capture you in your most natural form.
          We also offer professional headshots in our studio or your office.
          Whether you need headshots for networking, business cards, portfolio
          or social media, our headshot photography will ensure that you will
          stand out and assist in reaching the next step of your professional
          journey. */}
          <Text as={'span'} fontWeight={'bold'}>
            {' '}
            Experience Authentic Portraits in NYC!{' '}
          </Text>
          <br />
          <br />
          Step into the natural light and let your personality shine through with our outdoor portrait sessions. Whether you're an actor seeking captivating headshots, a
          professional in need of polished corporate portraits, or simply updating your social media profile, we've got you covered.
          <br />
          <br />
          Choose from our scenic outdoor locations across NYC for a backdrop that speaks to your story. Alternatively, opt for the sleek professionalism of our Brooklyn studio for
          corporate headshots that command attention. Wherever you choose to capture your essence, our goal remains the same: to create stunning portraits that reflect the true
          you.
          <br />
          <br />
          <Text as={'span'} color={'brand.accent'}>
            {' '}
            Schedule your session today and let's bring your vision to life, one frame at a time!{' '}
          </Text>
        </Text>
      </Box>

      <ImageCarousel images={carouselImages} ></ImageCarousel>
      <Hide above='lg'>
        <Stack direction={{ base: 'column', lg: 'row' }} fontSize={30} p={4}>
          <Stack direction={{ base: 'column', sm: 'row' }} justifyContent={'space-between'} mb={6} spacing={8}>
            <SecondaryButton icon={CgDetailsMore} size={{ base: 'xl', lg: '2xl' }} text={'DETAILS'} to={'/services/portrait-photography'}></SecondaryButton>
            <SecondaryButton size={{ base: 'xl', lg: '2xl' }} to={'/portfolio/portrait-photography'}></SecondaryButton>
          </Stack>
          <PrimaryButton size={{ base: 'xl', lg: '2xl' }} to={'/contact/portrait-photography'}></PrimaryButton>
        </Stack>
      </Hide>
      <Show above='lg'>
        <Stack align={'center'} direction={'row'} fontSize={30} height={120} justify={{ base: 'space-around' }}>
          <Box display={'flex'} justifyContent={'center'} w={'200px'}>
            <SecondaryButton icon={CgDetailsMore} size={{ base: 'xl', lg: '2xl' }} text={'DETAILS'} to={'/services/portrait-photography'}></SecondaryButton>
          </Box>
          <Box display={'flex'} justifyContent={'center'} w={'200px'}>
            <SecondaryButton size={{ base: 'xl', lg: '2xl' }} to={'/portfolio/portrait-photography'}></SecondaryButton>
          </Box>
          <Box display={'flex'} justifyContent={'center'} w={'200px'}>
            <PrimaryButton size={{ base: 'xl', lg: '2xl' }} to={'/contact/portrait-photography'}></PrimaryButton>
          </Box>
        </Stack>
      </Show>
    </Box>
  )
}

export default ServiceCard
