import { useEffect, useState } from 'react'
import byteSize from 'byte-size'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react'
import { BiCloudUpload, BiEdit } from 'react-icons/bi'
import { MdOutlineDelete, MdOutlineDeleteForever, MdOutlineCloudDone } from 'react-icons/md'
import { TbCloudOff } from 'react-icons/tb'

import TagMain from '../tag-main'

//fallback image
import logoBlack from '../../images/logo-trans-black.png'

//TODO
//extract iconBottons, or at least map status codes to color, icon, loader etc
const fixSrc = img => `${img.src}fit=crop&w=140&max-h=140`

const ImageTile = ({ image, deleteItem, editItem, status, uploadItem }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { isOpen: showImageModal, onClose: onImageModalClose, onOpen: toggleImageModal } = useDisclosure()
  const onConfirmDelete = () => {
    onClose()
    deleteItem(image)
  }
  const getSizeFromBytes = bytes => {
    const { unit, value } = byteSize(bytes)
    return `${value} ${unit}`
  }

  return (
    <Box alignContent={'center'} border={'.2px  solid'} display='flex' flexWrap={'wrap'} gap={4} minHeight={'100px'} justifyContent={'flex-start'} mt={2} padding={2}>
      <Box>
        <Image
          alt={image.name}
          fallback={logoBlack}
          flexShrink={'1'}
          height='auto'
          loading={'lazy'}
          maxHeight='100%'
          objectFit={'contain'}
          onClick={toggleImageModal}
          src={uploadItem ? URL.createObjectURL(image.file) : fixSrc(image)}
          width={'80px'}
        />
        <Modal isOpen={showImageModal} onClose={onImageModalClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <Image
              alt={image.name}
              fallback={logoBlack}
              flexShrink={'1'}
              height='auto'
              loading={'lazy'}
              maxHeight='100%'
              maxWidth='80vw'
              objectFit={'contain'}
              onClick={onImageModalClose}
              src={uploadItem ? URL.createObjectURL(image.file) : image.src}
              width={'100%'}
            />
          </ModalContent>
        </Modal>
      </Box>
      <Flex direction='column' alignItems={'flex-start'}>
        <Text as={'p'} fontSize={{ base: 'xl', md: '2xl' }} m={0}>
          {image.name || '-'}
        </Text>
        <Text as={'p'} fontSize='lg' display='flex' gap={2} m={0}>
          {getSizeFromBytes(uploadItem ? image.file.size : image.size_bytes)} <Text as={'span'}> • </Text> {image.ext}
          {/* {sizeKB(uploadItem ? image.file.size : image.size_bytes).toLocaleString('en-us', {
            style: 'unit',
            unit: 'megabyte',
            unitDisplay: 'short',
          })} */}
        </Text>
        {/* <TagsSelect></TagsSelect> */}
        <Box alignContent={'flex-start'} alignItems={'flex-start'} display={'flex'} gap={2} justifyContent={'flex-start'} mt={2} flexWrap={'wrap'}>
          {image?.tags?.sort((a, b) => a?.label?.localeCompare(b?.label))?.map(tag => <TagMain key={tag.id} label={tag?.label || '???'} size={'md'} />) || ''}
        </Box>
      </Flex>
      <Box
        display={'flex'}
        flexDirection={{ base: 'row', md: 'column' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        ml={{ base: '', md: 'auto' }}
        width={{ base: '100%', md: 'auto' }}
      >
        <Text color={'gray'}> {new Date(image.updatedAt)?.toLocaleString() || ''} </Text>
        <Box display={'flex'}>
          <Popover isLazy isOpen={isOpen} placement='left'>
            <PopoverTrigger>
              <IconButton
                aria-label='Remove from pending uploads'
                color={'red'}
                display={'flex'}
                icon={<Icon as={status?.status === 'error' ? MdOutlineDeleteForever : MdOutlineDelete} />}
                isLoading={status?.status === 'deleting'}
                ml={4}
                onClick={onToggle}
                fontSize='2xl'
                title={'Remove from pending uploads'}
                variant={'unstyled'}
                _hover={{
                  color: 'brand.accent',
                }}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton onClick={onClose} />
              <PopoverHeader color={'red'} fontWeight={'bold'}>
                CONFIRM!
              </PopoverHeader>
              <PopoverBody>
                {status?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to delete this image resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {status?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <Text as={'p'}>Are you sure you want to delete this image?</Text>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  mt={4}
                  colorScheme='red'
                  onClick={onConfirmDelete}
                  width={'100%'}
                  // ref={initRef}
                >
                  {status?.status === 'error' ? 'RETRY' : 'DELETE'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <IconButton
            aria-label='Edit upload data'
            display={'flex'}
            icon={<Icon as={BiEdit} />}
            ml={4}
            onClick={() => editItem(image)}
            fontSize='2xl'
            title={'Edit upload data'}
            variant={'unstyled'}
            _hover={{
              color: 'brand.accent',
            }}
          />
          {/* {uploadItem && (
          <IconButton
            aria-label='Upload Image'
            color={status.status === 'success' ? 'green' : status.status === 'error' ? 'red' : undefined}
            display={'flex'}
            icon={<Icon as={status.status === 'success' ? MdOutlineCloudDone : status.status === 'error' ? TbCloudOff : BiCloudUpload} />}
            isLoading={status.status === 'loading'}
            ml={4}
            onClick={() => uploadItem(image)}
            fontSize='2xl'
            title={'Upload Image'}
            variant={'unstyled'}
            _hover={{
              color: 'brand.accent',
            }}
          />
        )} */}
        </Box>
      </Box>
    </Box>
  )
}
export default ImageTile
