import { Link as RouterLink } from 'react-router-dom'
import { IconButton } from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

const ColorModeToggle = ({ colorMode, color, toggleColorMode }) => {
  return (
    <IconButton
      color={color}
      onClick={toggleColorMode}
      variant='unstyled'
      aria-label='Toggle dark mode'
      fontSize='20px'
      icon={colorMode === 'dark' ? <MoonIcon /> : <SunIcon />}
    />
  )
}
export default ColorModeToggle
