const dayOfWeekNameFromNum = (day, short = true) => {
  const days = [
    { short: 'SUN', long: 'DAY' },
    { short: 'MON', long: 'MONDAY' },
    { short: 'TUE', long: 'TUESDAY' },
    { short: 'WED', long: 'WEDNESDAY' },
    { short: 'THU', long: 'THURSDAY' },
    { short: 'FRI', long: 'FRIDAY' },
    { short: 'SAT', long: 'SATURDAY' },
  ]
  const dayData = days?.[+day] || { short: '', long: '' }
  return short ? dayData.short : dayData.long
}

const timeHuman = time => {
  if (!time) return ''
  const timeArr = time.split(':')
  let period = 'am'
  let hour = parseInt(timeArr['0'])
  let min = parseInt(timeArr['1'])
  if (hour >= 12) period = 'pm'
  if (hour > 12) hour = hour - 12
  if (hour === 0) hour = 12
  // return `${hour.toString().padStart(2, '0')}:${min
  //   .toString()
  //   .padStart(2, '0')}${period}`
  return `${hour.toString()}:${min.toString().padStart(2, '0')}${period}`
}

const timestampSql = (time, withTime = true) => {
  if (!time) time = new Date()
  time = time && typeof time.getMonth === 'function' ? time : new Date(time)

  const adjDt = new Date(time.getTime() - time.getTimezoneOffset() * 60000)
  return withTime ? adjDt.toISOString().slice(0, 19).replace('T', ' ') : adjDt.toISOString().split('T')[0]
}

const timestampHuman = (timestamp, withYear = true, withTime = true, withWeekday = false) => {
  const asDate = timestamp && typeof timestamp.getMonth === 'function' ? timestamp : new Date(timestamp)
  let human = ''
  let D = asDate.getDate()
  if (D < 10) {
    D = D.toString().padStart(2, '0')
  }
  let M = asDate.getMonth() + 1
  if (M < 10) {
    M = M.toString().padStart(2, '0')
  }
  human = `${M}/${D}`
  if (withYear) {
    human = human + '/' + asDate.getFullYear()
  }
  if (withTime) {
    let h = asDate.getHours()
    let m = asDate.getMinutes()
    let ampm = 'AM'
    if (h >= 12) {
      if (h > 12) {
        h = h - 12
      }
      ampm = 'PM'
    }
    if (h === 0) {
      h = 12
    }
    if (m < 10) {
      m = m.toString().padStart(2, '0')
    }
    human = human + `  ${h}:${m} ${ampm}`
  }

  if (withWeekday === 'short' || withWeekday === 'long') {
    human += ` (${dayOfWeekNameFromNum(asDate.getDay(), withWeekday)})`
  }
  return human
}

export { dayOfWeekNameFromNum, timeHuman, timestampSql, timestampHuman }
