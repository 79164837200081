import useScrollPosition from '../../hooks/useScrollPosition'

import { Box, Flex, Heading, Image, Show } from '@chakra-ui/react'
import HeroImage from '../../images/hero.jpg'
import logoWhite from '../../images/logo-trans-white.png'
const HeroBox = () => {
  const scrollPos = useScrollPosition()
  const headerOpacity = 1 - scrollPos / 175
  //detect ios, disable fixed background
  const ua = navigator.userAgent
  const isios =
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return (
    <Box
      // bgImage="url('https://media.manuelpellon.com/images/NYC/100%20Jay%20St%2C%2017H-views-1.jpg')"
      bgImage={HeroImage}
      backgroundPosition={{
        base: 'center',
        // base: 'calc(50% - 20px) ',
        // md: 'calc(50%) 20%',
        // md: 'calc(50% - 15px) top',
        // xl: 'calc(50%) 20%',
      }}
      bgColor={'brand.secondary'}
      bgSize='cover'
      backgroundRepeat='no-repeat'
      bgAttachment={isios ? 'unset' : 'fixed'}
      // bgPos='calc(50% - 20px) 100%'
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      h={{ base: '100vh', lg: '90vh' }}
      pos='relative'
      w={{ base: '100%' }}
    >
      <Show below='md'>
        <Image
          alt='Manuel Pellón Circular Logo'
          h='auto'
          left={{ base: '50vw' }}
          // left={{ base: '54.5vw', sm: '52.5vw' }}
          minWidth={'20px'}
          objectFit='cover'
          opacity={headerOpacity}
          pos={'absolute'}
          src={logoWhite}
          top={'10vh'}
          transform={'translate(-50%)'}
          w={{ base: '75vw', sm: '55vw' }}
        />
      </Show>
      <Show above='md'>
        <Flex
          left={'50vw'}
          color={{
            base: 'brand.secondary',
          }}
          alignContent={'center'}
          alignItems={'center'}
          flexDirection={{ base: 'column', md: 'row' }}
          flexWrap={'wrap'}
          gap={2}
          justifyContent={{ base: 'center', md: 'space-around' }}
          opacity={headerOpacity}
          position={'absolute'}
          textTransform={'uppercase'}
          top={'26vh'}
          transform={'translate(-50%)'}
          w={'100%'}
        >
          <Heading
            as={'h1'}
            color={'brand.secondary.light'}
            letterSpacing={{ base: '1.5ch', lg: '5vw' }}
            mr={{ base: '-5vw' }}
            textAlign={'center'}
          >
            Manuel
          </Heading>
          <Heading
            as={'h1'}
            color={'brand.secondary.light'}
            letterSpacing={{ base: '1.5ch', lg: '5vw' }}
            mr={{ base: '-1.5ch', lg: '-5vw' }}
            textAlign={'center'}
          >
            Pellón
          </Heading>
        </Flex>
      </Show>
    </Box>
  )
}

export default HeroBox
