import { fetchApi, postApi } from './api'

const addContact = async contact => {
  const URL = 'manage/contact'
  return postApi(URL, contact)
}

const contactEmailPhoneString = contact => {
  if (!contact) return ''
  let contactStr = ''
  if (contact?.email) contactStr += contact.email
  if (contact.email && contact.phone) contactStr += ' • '
  if (contact?.phone) contactStr += contact.phone
  return contactStr
}

const deleteContact = async (contact = '', deleteEvents = false) => {
  let URL = 'manage/contact/' + contact
  if (deleteEvents) URL += '/t'
  return fetchApi(URL, 'DELETE')
}

const fetchContact = async (contact = null) => {
  let URL = 'manage/contact'
  if (contact) URL += `/${contact}`
  return fetchApi(URL)
}

const updateContact = async contactData => {
  const URL = 'manage/contact'
  return postApi(URL, contactData, 'PUT')
}

export { addContact, contactEmailPhoneString, deleteContact, fetchContact, updateContact }
