import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorMode,
} from '@chakra-ui/react'

import { BiCalendarCheck, BiSolidError } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
import { FaCalendarCheck } from 'react-icons/fa'
import { MdErrorOutline, MdMarkEmailRead, MdOutlineLayersClear } from 'react-icons/md'

import ContactOverview from '../contact-overview'
import EventEditForm from '../event-edit-form/event-edit-form.js'
import EventTile from '../event-tile'
import EventTileDetails from '../event-tile-details/index.js'

import { archiveEvent, cancelEvent, confirmEvent, deleteEvent, fetchEvent } from '../../services/event.js'
import { sendConfirmationEmail } from '../../services/email'
import { updateContact } from '../../services/contact'
import { timestampHuman, timestampSql } from '../../services/format'

const displayedConfirmationDate = event => {
  let timestamp = ''
  if (event?.conf_datetime) {
    timestamp = event?.conf_datetime
  } else if (event?.conf_date) {
    const conf_time = event?.conf_time || '00:00:00'
    timestamp = `${event.conf_date} ${conf_time}`
  } else if (event?.req_date) {
    const req_time = event?.req_time || '00:00:00'
    timestamp = `${event.req_date} ${req_time}`
  } else {
    return ''
  }
  return timestampHuman(timestamp, true, true, 'short')
}

const ManageEvents = () => {
  const { colorMode } = useColorMode()

  const [actionState, setActionState] = useState({})
  const [contacts, setContacts] = useState({})
  const [contactAdding, setContactAdding] = useState({})
  const [eventEditing, setEventEditing] = useState([])
  const [events, setEvents] = useState([])
  const [eventConfirming, setEventConfirming] = useState({})
  const [eventConfirmingDisplayDate, setEventConfirmingDisplayDate] = useState(null)
  const [eventsConfirmed, setEventsConfirmed] = useState([])
  const [eventsHistory, setEventsHistory] = useState([])
  const [eventsInqueries, setEventsInqueries] = useState([])
  const [eventsRequested, setEventsRequested] = useState([])

  const sendConfirmationEmailCheckbox = useRef()

  const addEventToCalendar = () => {
    console.log('add event to calendar')
  }

  const approveAllPending = () => {
    console.log('approve all pending events')
  }

  const onConfirmEvent = event => {
    // console.log('approve event')
    // console.log(event)
    setEventConfirming(event)
  }

  // fix name
  const confirmEventConfirm = async event => {
    // console.log('confirm approve event')
    // console.log(event)

    if (!event?.id) {
      //set status error/message here
      console.error('EVENT ID NOT FOUND!')
      return
    }
    try {
      setActionState({ ...actionState, [event.id]: { status: 'confirming' } })
      const res = await confirmEvent(event, sendConfirmationEmailCheckbox?.current?.checked)
      // console.log(res)
      if (res?.error) {
        throw new Error(res.error)
      }
      const updatedEvents = [...events?.filter(e => e.id !== event.id), res]
      // console.log(updatedEvents)
      setEvents(updatedEvents)
      setActionState({ ...actionState, [event.id]: {} })
      setEventConfirming({})
    } catch (e) {
      console.log(e)
      setActionState({ ...actionState, [event.id]: { status: 'error', message: e ?? e?.message ?? 'Error encountered archiving event.' } })
    }
  }

  const onArchiveEvent = async event => {
    // console.log('archive event')
    // console.log(event?.id)
    if (!event?.id) {
      //set status error/message here
      console.error('EVENT ID NOT FOUND!')
      return
    }

    try {
      setActionState({ ...actionState, [event.id]: { status: 'archiving' } })
      const res = await archiveEvent(event.id)
      console.log(res)
      if (res?.error) {
        throw new Error(res.error)
      }
      event.archivedAt = timestampSql()
      // console.log(event)
      // console.log(events)
      const updatedEvents = [...events?.filter(e => e.id !== event.id), event]
      // console.log(updatedEvents)
      setEvents(updatedEvents)
      setActionState({ ...actionState, [event.id]: {} })
    } catch (e) {
      console.log(e)
      setActionState({ ...actionState, [event.id]: { status: 'error', message: e ?? e?.message ?? 'Error encountered archiving event.' } })
    }
  }

  const onCancelEvent = async (event, cb) => {
    // console.log('cancel event')
    // console.log(event)
    try {
      setActionState({ ...actionState, [event.id]: { status: 'canceling' } })
      const res = await cancelEvent(event.id)
      // console.log(res)
      if (res?.error) {
        throw new Error(res.error)
      }
      if (res?.id) {
        const updatedEvents = [...events?.filter(e => e.id !== event.id), res]
        setEvents(updatedEvents)
      }
      setActionState({ ...actionState, [event.id]: {} })
      if (cb) {
        cb()
      }
    } catch (e) {
      console.log(e)
      setActionState({ ...actionState, [event.id]: { status: 'error', message: e ?? e?.message ?? 'Error encountered archiving event.' } })
    }
  }

  const onDeleteEvent = async event => {
    // console.log('delete event')
    // console.log(event)
    try {
      setActionState({ ...actionState, [event.id]: { status: 'deleting' } })
      const res = await deleteEvent(event.id)
      // console.log(res)
      if (res?.error) {
        throw new Error(res.error)
      }
      const updatedEvents = [...events?.filter(e => e.id !== event.id)]
      setEvents(updatedEvents)
      setActionState({ ...actionState, [event.id]: {} })
    } catch (e) {
      console.log(e)
      setActionState({ ...actionState, [event.id]: { status: 'error', message: e ?? e?.message ?? 'Error encountered archiving event.' } })
    }
  }

  const onEditEvent = e => {
    // console.log('edit event')
    // console.log(e)
    setEventEditing({ ...e })
  }

  const onUpdateEvent = eventData => {
    // console.log('update event')
    // console.log(eventData)
    if (eventData?.contact?.id) {
      setContacts(contacts => ({ ...contacts, [eventData.contact.id]: { ...eventData.contact } }))
      delete eventData.contact
    }
    setEvents(events => [...events.filter(e => e.id !== eventData.id), { ...eventData }])
    setEventEditing({})
  }

  const markAllViewed = () => {
    console.log('mark all viewed')
  }

  const onContactModalClose = () => {
    setContactAdding({})
    // create key for contact loading/error states in actionState
    setLoadingState('')
  }

  const onEventModalClose = () => {
    setEventEditing({})
    // create key for contact loading/error states in actionState
    setLoadingState('')
  }

  const onSendEmail = async (event, type = 'confirmation', cb) => {
    //only confirmation used currently
    //todo: cancelation

    try {
      if (type === 'confirmation') {
        if (!event?.id) {
          throw new Error('Missing event id')
        }
        setActionState({ ...actionState, [event.id]: { status: 'sending' } })
        const res = await sendConfirmationEmail(event.id)
        // console.log(res)
        if (res?.error) {
          throw new Error(res.error)
        }
        if (cb) {
          cb()
        }
      }
    } catch (e) {
      console.log(e)
      setActionState({ ...actionState, [event.id]: { status: 'error', message: e?.message ?? e ?? 'Error encountered archiving event.' } })
    }
  }

  const saveContact = async () => {
    // console.log('save contact')
    if (!contactAdding) {
      console.error('contact adding not found!')
      return
    }
    setLoadingState('addingContact')

    try {
      const contact = { id: contactAdding.id, is_contact: true }
      const res = await updateContact(contact)
      if (res?.error) {
        throw new Error(res)
      }

      if (!res?.id) {
        throw new Error({ message: 'Did not receive expected server response. Possible failure. Refresh and try again. ' })
      }
      setContacts({ ...contacts, [contact.id]: res })
      onContactModalClose()
      // console.log(res)
      console.log('show success message!')
    } catch (e) {
      console.log(e?.message || e || 'Error saving contact')
    } finally {
      setLoadingState('')
    }
  }

  const setLoadingState = e => {
    // console.log('TEMP FUNC TO AVOID ERRORS DURING REFACTOR')
    // console.log(e)
    // console.log('------')
  }

  //  PULL EVENTS FROM API
  useEffect(() => {
    // const fetchPageData = async () => {

    fetchEvent()
      .then(data => {
        // console.log(data)
        if (data?.contacts) {
          setContacts(data.contacts)
        }
        if (data?.events?.length) {
          setEvents(data.events)
        }
      })
      .catch(e => {
        console.error('error from fetch page data, set error state, show alert message')
        console.log(e)
      })
  }, [])

  useEffect(() => {
    const now = new Date().getTime()
    const eventsDesc = events.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
    const eventsHistory = eventsDesc.filter(e => !!e?.archivedAt || e?.canceledAt)
    // const eventsHistory = eventsDesc.filter(e => e?.archivedAt || e?.conf_datetime < now)
    setEventsHistory(eventsHistory)
    const inqueries = eventsDesc.filter(e => e?.service === 'general' && !e?.archivedAt)
    setEventsInqueries(inqueries)
    const requested = eventsDesc.filter(e => !e?.archivedAt && e?.service !== 'general' && !e?.confirmedAt)
    setEventsRequested(requested)
    const eventsConfirmed = eventsDesc.filter(e => !!e?.confirmedAt && !e?.canceledAt)
    // const eventsConfirmed = eventsDesc.filter(e => !!e?.conf_date && !e?.archivedAt)
    setEventsConfirmed(eventsConfirmed)
  }, [events])

  useEffect(() => {
    setEventConfirmingDisplayDate(displayedConfirmationDate(eventConfirming))
  }, [eventConfirming])

  return (
    <Box>
      {/* <Text as={'h1'} fontSize={40} color={'red'}>
        !!!DO NOT USE!!! (TEMPORARILY!)
      </Text> */}
      {!!eventsInqueries.length && (
        <Box mt={8} mb={36} textAlign={'left'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
            <Text color={'brand.accent'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
              General Inquries
            </Text>
            <Button
              borderRadius={'none'}
              borderColor={'green'}
              color={'green'}
              onClick={markAllViewed}
              leftIcon={<MdMarkEmailRead />}
              variant={'outline'}
              _hover={{
                bgColor: 'black',
                borderColor: 'brand.accent',
                color: 'brand.accent',
              }}
            >
              MARK ALL READ
            </Button>
          </Flex>
          {eventsInqueries.map(event => (
            <EventTile
              key={event.id}
              actionState={actionState?.[event.id]}
              addContact={contact => setContactAdding(contact)}
              confirmEvent={confirmEvent}
              archiveEvent={onArchiveEvent}
              deleteEvent={onDeleteEvent}
              editEvent={onEditEvent}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'general'}
            />
          ))}
        </Box>
      )}

      <Box mt={8} mb={36} textAlign={'left'}>
        <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
          <Text color={'yellow'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
            Pending Requests
          </Text>

          {/* <Button
              borderRadius={'none'}
              borderColor={'yellow'}
              color={'yellow'}
              onClick={approveAllPending}
              leftIcon={<FaCalendarCheck />}
              variant={'outline'}
              _hover={{
                bgColor: 'black',
                borderColor: 'brand.accent',
                color: 'brand.accent',
              }}
            >
              CONFIRM ALL
            </Button> */}
          <Button
            borderRadius={'none'}
            borderColor={'green'}
            color={'green'}
            onClick={() => setEventEditing({ id: null })}
            leftIcon={<FaCalendarCheck />}
            variant={'outline'}
            _hover={{
              bgColor: 'black',
              borderColor: 'brand.accent',
              color: 'brand.accent',
            }}
          >
            ADD NEW
          </Button>
        </Flex>
        {!eventsRequested.length ? (
          <Text fontSize={'lg'}>No upcoming appointments.</Text>
        ) : (
          eventsRequested.map(event => (
            <EventTile
              key={event.id}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'pending'}
              addContact={contact => setContactAdding(contact)}
              confirmEvent={onConfirmEvent}
              archiveEvent={onArchiveEvent}
              deleteEvent={onDeleteEvent}
              editEvent={onEditEvent}
            />
          ))
        )}
      </Box>
      <Box mt={24} textAlign={'left'}>
        <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
          <Text color={'green'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
            Confirmed Appointments
          </Text>
          <Text fontSize={'lg'} fontWeight={'bold'} textTransform={'uppercase'}>
            Filter/Search/Sort
          </Text>
        </Flex>
        {!eventsConfirmed.length ? (
          <Text fontSize={'lg'}>No upcoming appointments.</Text>
        ) : (
          eventsConfirmed.map(event => (
            <EventTile
              key={event.id}
              actionState={actionState?.[event?.id]}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              eventType={'confirmed'}
              addToCalendar={addEventToCalendar}
              cancelEvent={onCancelEvent}
              editEvent={onEditEvent}
              sendEmail={onSendEmail}
            />
          ))
        )}
      </Box>

      {!!eventsHistory.length && (
        <Box mt={24} textAlign={'left'}>
          <Flex alignItems={'center'} justifyContent={'space-between'} my={8}>
            <Text color={'brand.accent'} fontSize={'2xl'} fontWeight={'bold'} textTransform={'uppercase'}>
              Event History
            </Text>
            <Text fontSize={'lg'} fontWeight={'bold'} textTransform={'uppercase'}>
              Filter/Search/Sort
            </Text>
          </Flex>
          {eventsHistory.map(event => (
            <EventTile
              actionState={actionState?.[event.id]}
              key={event.id}
              event={{ ...event, contact: contacts?.[event?.contact_id] || {} }}
              addContact={contact => setContactAdding(contact)}
              deleteEvent={onDeleteEvent}
              eventType={'history'}
            />
          ))}
        </Box>
      )}

      {/* ADD CONTACT */}
      <AlertDialog isCentered isOpen={contactAdding?.id} onClose={onContactModalClose}>
        <AlertDialogOverlay>
          <AlertDialogContent maxH={600} maxW={600} width={'100%'}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              ADD NEW CONTACT
            </AlertDialogHeader>
            <AlertDialogBody>
              <Box display={'flex'} flexDir={'column'} alignItems={'start'} gap={2}>
                <Text as={'p'} textColor={'yellow'}>
                  The below contact will be added to the address book.
                </Text>
                <Text as={'p'} fontStyle={'italic'}>
                  Saved contact details can be added or modified from the client management page.
                </Text>
                <ContactOverview contact={contactAdding} />
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter display={'flex'} justifyContent={'space-between'} mt={4}>
              <Button colorScheme={'yellow'} onClick={() => onContactModalClose()} variant='outline'>
                CANCEL
              </Button>
              <Button
                colorScheme={'green'}
                isLoading={actionState?.['addingContact']?.status === 'loading'}
                rightIcon={<BsPersonFillAdd />}
                onClick={saveContact}
                variant='outline'
              >
                ADD CONTACT
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* ADD/EDIT EVENT */}
      <AlertDialog key={eventEditing?.id} isCentered isOpen={eventEditing?.id !== undefined} onClose={onEventModalClose}>
        <AlertDialogOverlay>
          <AlertDialogContent maxH={'90vh'} maxW={900} width={'95vw'}>
            {/* <AlertDialogContent maxH={600} maxW={600} width={'100%'}> */}
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {eventEditing?.id ? 'EDIT' : 'ADD NEW'} EVENT
            </AlertDialogHeader>
            <AlertDialogBody p={2}>
              <Divider />
              <EventEditForm
                key={eventEditing?.id}
                contacts={Object.values(contacts) || []}
                onCancel={onEventModalClose}
                onDelete={onDeleteEvent}
                onUpdate={onUpdateEvent}
                originalEvent={eventEditing}
              />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* CONFIRM REQUEST */}
      {/* move to comp */}
      <AlertDialog isCentered isOpen={eventConfirming?.id} onClose={() => setEventConfirming({})}>
        <AlertDialogOverlay>
          <AlertDialogContent maxW={600} width={'100%'}>
            <AlertDialogHeader fontSize='2xl' fontWeight='bold'>
              CONFIRM APPOINTMENT
            </AlertDialogHeader>
            <AlertDialogBody>
              {eventConfirmingDisplayDate !== null && (
                <Box>
                  <Text as={'p'} textColor={'yellow'} fontSize={'xl'} fontWeight={'bold'}>
                    Do you want to confirm the below appointment?
                  </Text>
                  {!eventConfirmingDisplayDate ? (
                    <>
                      <Box as={'p'} display={'flex'} alignItems={'center'} fontSize={'xl'} fontWeight={'bold'} gap={2} textColor={'red'} my={4}>
                        <Icon as={BiSolidError} boxSize={'8'} />
                        {/* <Icon as={BiCalendarCheck} boxSize={'8'} />  */}
                        No requested or confirmation date found for event. <br />
                        Please set a confirmation date in the event edit dialog.
                      </Box>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Text as={'p'} display={'flex'} alignItems={'center'} fontSize={'xl'} fontWeight={'bold'} gap={2} textColor={'green'} my={4}>
                        <Icon as={BiCalendarCheck} boxSize={'8'} />
                        CONFIRMATION DATE: {eventConfirmingDisplayDate ?? '???'}
                      </Text>
                    </>
                  )}
                </Box>
              )}
              {/* <Text fontSize={'lg'} fontWeight={'bold'}>
                Original Request
              </Text> */}
              {/* <Box display={'flex'} flexDir={'column'} alignItems={'start'} border={'solid 1.5px white'} borderRadius={'5px'} gap={2} p={4}> */}
              <EventTileDetails event={eventConfirming} expanded={false} size='md' />
              {/* </Box> */}
              <Divider mt={4} />
              <Box display={'flex'} justifyContent={'center'} width={'100%'} py={6}>
                <Checkbox defaultChecked iconSize='4rem' isDisabled={!eventConfirmingDisplayDate} ref={sendConfirmationEmailCheckbox}>
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    Send confirmation email
                  </Text>
                </Checkbox>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter display={'flex'} justifyContent={'space-between'}>
              <Button colorScheme={'yellow'} onClick={() => setEventConfirming({})} variant='outline'>
                CANCEL
              </Button>
              <Button
                colorScheme={'green'}
                isDisabled={!eventConfirmingDisplayDate}
                isLoading={actionState?.[eventConfirming?.id]?.status === 'confirming'}
                rightIcon={<BiCalendarCheck />}
                onClick={() => confirmEventConfirm(eventConfirming)}
                variant='outline'
              >
                CONFIRM
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}

export default ManageEvents
