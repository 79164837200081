import { Box, Heading, Icon, Text } from '@chakra-ui/react'
import WeatherIcon from '../weather-icon'

const WeatherDay = ({ isActive, selectDay, weather }) => {
  const dt = new Date(weather.dt * 1000)
  const dtStr = dt.toDateString()
  const [day, monthAbv, date] = dtStr.split(' ')
  const monthNum = dt.getMonth() + 1
  const handleClick = () => {
    //slight delay for transition
    selectDay(false)
    setTimeout(() => selectDay(weather), 99)
  }
  return (
    <Box
      // border={'solid 2px'}
      color={isActive ? 'orange' : 'brand.primary'}
      display={'flex'}
      flexDir={'column'}
      alignItems={'center'}
      cursor={'pointer'}
      justifyContent={'space-between'}
      minH={'80px'}
      minW={'80px'}
      onClick={handleClick}
      p={2}
      textAlign={'center'}
      _focus={{
        // border: 'solid 2px orange',
        color: 'orange',
      }}
      _hover={{
        // border: 'solid 2px orange',
        color: 'orange',
      }}
    >
      <Text fontWeight={'medium'}>{`${day} ${monthNum}/${date}`}</Text>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} w={'100%'}>
        <Text as={'h6'} fontSize='2xl' fontWeight={'bold'} mr={2}>
          {Math.round(weather.temp)}&deg;
        </Text>
        <WeatherIcon iconcode={weather.icon} boxSize={'2rem'} />
      </Box>
    </Box>
  )
}

export default WeatherDay
