import { fetchApi, postApi } from './api'

const sendConfirmationEmail = async event => {
  //test for event
  console.log('todo: merge with /email/...')
  // const URL = `manage/email/event-confirmation/${event}`
  const URL = `manage/event/confirm/${event}/t`
  return fetchApi(URL)
}
export { sendConfirmationEmail }
