import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
// import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import App from './App'
import extendedTheme from './config/extendedTheme'
import reportWebVitals from './reportWebVitals'
// console.log(extendedTheme)

const root = ReactDOM.createRoot(document.getElementById('root'))
// console.log(extendedTheme)
root.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={extendedTheme}>
      {/* <ColorModeScript initialColorMode={extendedTheme.config.initialColorMode} /> */}
      <App />
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
