import { Link as RouterLink } from 'react-router-dom'
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useColorMode,
} from '@chakra-ui/react'

const ProfileCard = ({ avatarProps, children, profile, profileImage }) => {
  const { colorMode } = useColorMode()
  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'flex-start'}
      mb={'12rem'}
      p={4}
    >
      <Image
        src={profileImage || profile.image || ''}
        objectPosition={'center top'}
        objectFit={'cover'}
        borderRadius={'50%'}
        border={'.75rem solid'}
        borderColor={'brand.accent'}
        height={'auto'}
        maxHeight={'18rem'}
        mb={10}
        maxWidth={'18rem'}
        width={'100%'}
        {...avatarProps}
      ></Image>
      <Box as={'header'}>
        <Heading
          lineHeight={1.1}
          fontWeight={'bold'}
          fontSize={{ base: '4rem', xl: '3rem' }}
          mb={1}
          textAlign={'center'}
        >
          {profile.name}
        </Heading>
        <Heading
          lineHeight={1.1}
          fontWeight={'medium'}
          fontSize={{ base: '2xl' }}
          mb={10}
          textAlign={'center'}
        >
          {profile.title}
        </Heading>
        <Divider mb={5} />
      </Box>
      {profile?.text?.length && (
        <Box
          flexShrink={1}
          maxWidth={'85ch'}
          minHeight={{ base: 0, xl: '500px' }}
        >
          {profile.text.map((t, i) => (
            <Text
              as={'p'}
              fontSize={'2xl'}
              key={`${i}t`}
              py={4}
              textAlign={'left'}
            >
              {t}
            </Text>
          ))}
        </Box>
      )}

      {/* <LinkBox
        alignItems={'flex-end'}
        display={'flex'}
        h={'120px'}
        justifyContent={'center'}
      >
        <LinkOverlay as={RouterLink} to={profile.portfolioLink}>
          <AvatarGroup
            size='lg'
            max={3}
            _groupHover={{
              borderColor: 'brand.accent',
              cursor: 'pointer',
              transform: 'scale(1.1)',
              transition: 'transform .25s ease-in-out',
            }}
          >
            {profile.portfolioThumbnails.map((t) => (
              <Avatar
                bg='brand.primary'
                borderColor={`brand.primary.${colorMode}`}
                colorScheme={'brand'}
                key={t.id}
                name={profile.name}
                boxSize='75px'
                src={t.src}
                _groupHover={{
                  borderColor: 'brand.accent',
                  cursor: 'pointer',
                  transform: 'scale(1.1)',
                  transition: 'transform .25s ease-in-out',
                }}
              />
            ))}
          </AvatarGroup>
        </LinkOverlay>
      </LinkBox> */}
    </Box>
  )
}

export default ProfileCard
