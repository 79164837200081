import { useEffect } from 'react'
import { Box, Container, Heading } from '@chakra-ui/react'
import TeamCard from '../../components/team-card'
const AboutPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 300)
  }, [])
  return (
    <Container maxWidth={'1800px'}>
      {/* <Heading lineHeight={1.1} fontWeight={'bold'} fontSize={{ base: '4xl' }} my={{ base: 4, lg: 0 }} textAlign={'center'} textTransform={'uppercase'}>
        Meet our creative team
      </Heading> */}
      {/* <Box mb={'5rem'} /> */}
      <TeamCard />
    </Container>
  )
}

export default AboutPage
