import { createSrc } from '../services/image'
const teamData = [
  {
    aboutLink: '/about/',
    avatar: '',
    id: 'manuel-pellon',
    name: 'Manuel Pellón',
    portfolioLink: '/portfolio',
    portfolioThumbnails: [
      {
        alt: 'image desc here',
        id: 1,
        src: createSrc('8c156a0b51f36f84b7f476a057a78274.JPEG', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 2,
        src: createSrc('c701937365254cb4a48afa82668a7aec.jpg', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 3,
        src: createSrc('abd0050fdb0e497878d08931c4a9fa6b.jpg', 100, 100),
      },
    ],
    title: 'CEO',
    // title: 'Photographer /Director',
    text: [
      `I am Manuel Pellón, a professionally trained and award winning photographer with a focus on lifestyle,
      head shots, real estate and street photography.`,
      `I began my photography career in 2008, capturing my first images with a compact camera. I studied
      photography at the Asunción Image Institute in Paraguay and Buenos Aires, where I was awarded with a
      scholarship after shooting a winning image.`,
      `Upon completing my studies at the Institute, I began attending workshops and classes in Asunción,
      Buenos Aires and New York City, which helped me to create and forge my own style.`,
      `In 2010 I founded a Photography Institute, Fotearte, in my native Paraguay. There I developed an
      innovative and specific vision on how to teach Photography. To this day Fotearte is the leading
      Photography Institute in Paraguay. Knowing the importance of giving back what I learned, we started a
      scholarship program for underprivileged children and granted over 100 scholarships to train and hone the
      skills of the next generation of photographers. To date, Fotearte has grown from South America to the
      United States with more than 800 students.`,
      `My varied style of photography has allowed me to shoot editorials featured in magazines, newspapers
      and fashion campaigns. I have collaborated with brands presenting their collections at Asuncion Fashion
      Week and New York fashion Week.`,
      `My artistic passion is behind the camera. I love to tell stories through my work- capturing and sharing the
      beauty of my subjects and New York City's ever changing surroundings. I work closely with artistic
      directors, designers and leading voices in NYC residential real estate.`,
      `Please contact me with your inquiries.`,
      `I look forward to welcoming you into my world of photography.`,
    ],
  },
  {
    aboutLink: '/about/oscar-alarcon',
    avatar: '',
    id: 'oscar-alarcon',
    name: 'Oscar Alarcon',
    portfolioLink: '/portfolio/oscar-alarcon',
    portfolioThumbnails: [
      {
        alt: 'image desc here',
        id: 1,
        src: createSrc('421d1bfc938fd15476eae1a26a5ac254.jpg', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 2,
        src: createSrc('e716349f2f32fbebfeb628368c4bbb5b.jpg', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 3,
        src: createSrc('d75a10fc1c00b630a9aa5a4196578cf6.jpg', 100, 100),
      },
    ],
    title: 'Photographer',
    // text: [
    //   `Currently residing in New York City, He is a fashion and portrait photographer. He began his passion for photography when he was 16 and a former boss and mentor gave him his first camera and showed him the basics. He relocated to New York City in 2018 to expand beyond fashion photography. His focus since then has been on forming connections to various local photographers in a variety of disciplines, including real estate photography.`,
    //   `Oscar’s work involves showcasing the subject’s features and styling the photographs to match the vision I have in his head. The combination of various arts is what makes this vision become reality.`,
    // ],
  },
  {
    aboutLink: '/about/enrique-fernandez',
    avatar: '',
    id: 'enrique-fernandez',
    name: 'Enrique Fernandez',
    portfolioLink: '/portfolio/enrique-fernandez',
    portfolioThumbnails: [
      {
        alt: 'image desc here',
        id: 1,
        src: createSrc('239b0d44188e5db0a3ee9fe33bb96799.JPG', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 2,
        src: createSrc('10f4367810291d1e91b129bea0d69511.jpeg', 100, 100),
      },
      {
        alt: 'image desc here',
        id: 3,
        src: createSrc('5bf4cfff8e65e11e93c42d0e95cefd9b.jpeg', 100, 100),
      },
    ],
    title: 'Photographer',
    // text: [
    //   `An Argentinian Photographer based in Brooklyn, N.Y, He is self-taught with seven years of experience and a sincere passion for all things photography, beautiful light, fresh food, sincere people, and inspiring spaces. Enrique loves keeping things simple in life and on set as he finds it always produces the best results with the least stress. `,
    //   `No matter the size of the project, He always brings the same passion and dedication to each individual job, and it's his priority to make sure everyone goes home happy! Let's connect and make awesome work together.`,
    // ],
  },
  {
    aboutLink: '/about/giselle-meyer',
    avatar: '',
    id: 'giselle-meyer',
    name: 'Giselle Meyer',
    portfolioLink: '/portfolio/giselle-meyer',
    portfolioThumbnails: [],
    title: 'Makeup artist',
    // text: [
    //   `An Argentinian Photographer based in Brooklyn, N.Y, He is self-taught with seven years of experience and a sincere passion for all things photography, beautiful light, fresh food, sincere people, and inspiring spaces. Enrique loves keeping things simple in life and on set as he finds it always produces the best results with the least stress. `,
    //   `No matter the size of the project, He always brings the same passion and dedication to each individual job, and it's his priority to make sure everyone goes home happy! Let's connect and make awesome work together.`,
    // ],
  },
]

export default teamData
