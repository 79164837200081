import { useState } from 'react'
import { Box, Heading, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react'
import ContactForm from '../contact-form'
import ContactIcons from '../contact-icons'
import WeatherCard from '../weather-card'

const ContactCard = ({ showTitle = false }) => {
  const bp = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false },
  )
  return (
    <Box>
      {showTitle && (
        <Box as={'header'}>
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '5xl' }} mb={'100px'} textAlign={'center'}>
            CONNECT
          </Heading>
        </Box>
      )}

      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        overflow={'hidden'}
        spacing={{ base: 20, lg: 4 }}

        // textAlign={'left'}
      >
        <Stack direction={'column'} justify={'flex-start'} p={{ base: 0, xl: 8 }} py={{ base: 14, xl: 0 }} spacing={{ base: 20, lg: 40 }}>
          {/* <Box>
            <Text as={'p'} fontSize={'xl'}>
              For Real Estate Photography inquiries, please provide all relevant
              information about the property including location(s).
            </Text>
            <Text as={'p'} fontSize={'lg'} fontWeight={'bold'} mt={2}>
              Thank you and we look forward to working with you!
            </Text>
          </Box> */}
          {bp ? (
            <>
              <ContactIcons />

              <Box pb={{}}>
                <WeatherCard />
              </Box>
            </>
          ) : (
            <>
              <ContactIcons />
              <WeatherCard />
            </>
          )}
        </Stack>

        <Stack
          spacing={{ base: 6, md: 10 }}
          // pb={{ base: 0, lg: '40px', xl: 0 }}
          mt={{ base: 0, xl: 0 }}
        >
          <ContactForm />
        </Stack>
      </SimpleGrid>
    </Box>
  )
}

export default ContactCard
