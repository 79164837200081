import { useState } from 'react'
import { Avatar, Box, Image, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'

const AvatarWrapper = ({ person }) => {
  const [isFallback, setIsFallback] = useState(false)
  const { colorMode } = useColorMode()
  const getSrc = avatar => {
    return `${process.env.PUBLIC_URL}/media/avatars/${avatar}`
  }
  return (
    <Avatar
      bg={'brand'}
      borderColor={useColorModeValue('brand.accent', 'brand.accent')}
      color={useColorModeValue('brand', 'brand.background')}
      mr={2}
      name={person.name}
      onError={() => setIsFallback(true)}
      showBorder={isFallback}
      src={getSrc(person.avatar)}
    ></Avatar>
  )
}

export default AvatarWrapper
