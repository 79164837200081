import { NavLink, useLocation } from 'react-router-dom'
import { useTheme, useColorMode, textDecoration } from '@chakra-ui/react'
import useScrollPosition from '../../hooks/useScrollPosition'

import { Box, Hide, Image, Link, Show, Text } from '@chakra-ui/react'
import logoBlack from '../../images/logo-trans-black.png'
import logoWhite from '../../images/logo-trans-white.png'
import NavLinkDesktop from '../nav-link-desktop/nav-link-desktop'
import NavMobile from '../nav-mobile'
import ColorModeToggle from '../controls/color-mode-toggle'
import ScrollTopButton from '../controls/scroll-top-button'

const NavBar = () => {
  const scrollPos = useScrollPosition()
  const { colorMode, toggleColorMode } = useColorMode()
  // console.log(colorMode)

  const theme = useTheme()
  // console.log(theme)
  // console.log(theme?.semanticTokens?.colors)
  const themeBg = `${theme?.colors?.brand?.background?.[colorMode]}` ?? ''
  // const themeBg =
  //   theme?.semanticTokens?.colors?.['chakra-body-bg']?.[`_${colorMode}`] ??
  //   `${theme?.colors?.brand?.background?.[colorMode]}` ??
  //   ''
  // const themeBg = themeBg
  // const themePrimary =
  //   colorMode === 'dark' ? 'brand.secondary' : 'brand.primary'
  // console.log(theme)

  // const { isOpen, onToggle } = useDisclosure()
  const locationData = useLocation()
  // const locationData = { pathname: '/' }
  const { pathname } = locationData
  const rootPage = pathname.split('/')[1]

  const validRootPages = [
    'contact',
    'home',
    'manage',
    'portfolio',
    'services',
    'about',
  ]

  const isValidRootPage = validRootPages.includes(rootPage)
  const isHome = locationData?.pathname === '/'

  // console.log(scrollPos)
  const bgOpacity = !isHome ? 1 : scrollPos / 500
  // console.log(bgOpacity)
  const linkColor =
    isHome && scrollPos < 275
      ? 'brand.secondary.light'
      : colorMode === 'dark'
      ? 'brandDark.primary'
      : 'brand.primary'
  // const linkColor = scrollPos < 275 ? 'brand.secondary' :  !isHome || scrollPos > 275 ? 'brand.secondary' : themeBg
  const logoWidthLarge = 180
  const logoWidthSmall = 80
  // console.log(60 - scrollPos / 1000)
  const logoWidth = logoWidthLarge - scrollPos / 5
  // console.log(logoWidthLarge - scrollPos / 5)
  const logo =
    isHome && scrollPos < 275
      ? logoWhite
      : colorMode === 'dark'
      ? logoWhite
      : logoBlack

  return (
    <Box
      alignItems={'center'}
      bg={'transparent'}
      display={'flex'}
      minH={'60px'}
      py={{ base: 2, sm: 4, md: 2 }}
      px={{ base: 2, sm: 4 }}
      // pr={'50px'}
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={'transparent'}
      gap={{ base: 2, lg: 10 }}
      justifyContent={{
        base: 'space-between',
        lg: 'center',
      }}
      top={0}
      pos={'fixed'}
      w={'100%'}
      zIndex={1300}
      _after={{
        content: '""',
        h: '100%',
        w: '100%',
        bg: themeBg,
        opacity: bgOpacity,
        // opacity: { base: 1, sm: bgOpacity },
        position: 'absolute',
        pr: '50px',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      {/* SCROLL TOP BUTTON */}
      <ScrollTopButton scrollPos={scrollPos} />
      {/* END SCROLL TOP BUTTON */}
      <Hide above='md'>
        <Link end='true' p={0} href={'/'}>
          <Image
            alt='Manuel Pellón Circular Logo'
            objectFit='cover'
            h='auto'
            opacity={bgOpacity}
            src={colorMode === 'light' ? logoBlack : logoWhite}
            width={'60px'}
          />
        </Link>
        {isValidRootPage && (
          <Link
            as={NavLink}
            to={`/${rootPage}`}
            _hover={{ textDecoration: 'none' }}
          >
            <Text
              fontSize={'4xl'}
              fontWeight={'black'}
              mr={{ base: 0, md: 0 }}
              textDecoration={'none'}
            >
              {/* margin right to account for hamburger menu */}
              {rootPage.toUpperCase()}
            </Text>
          </Link>
        )}
        <NavMobile
          bgOpacity={bgOpacity}
          colorMode={colorMode}
          isHome={isHome}
          locationData={locationData}
          menuColor={colorMode === 'light' ? 'dark' : 'light'}
          toggleColorMode={toggleColorMode}
        />
      </Hide>

      {/* SM AND UP */}
      <Show above='md'>
        {/* DARK MODE TOGGLE */}
        <Box position={'fixed'} top={2} right={2}>
          <ColorModeToggle
            color={linkColor}
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
          ></ColorModeToggle>
        </Box>
        <NavLinkDesktop
          color={linkColor}
          flex={1}
          showActive={locationData?.pathname.includes('services')}
          text='SERVICES'
          to='/services'
        ></NavLinkDesktop>
        <NavLinkDesktop
          color={linkColor}
          flex={1}
          showActive={locationData?.pathname.includes('portfolio')}
          text='PORTFOLIO'
          to='/portfolio'
        ></NavLinkDesktop>

        <Box flex={1} maxW={'220px'}>
          <Link
            as={NavLink}
            end='true'
            p={2}
            to={'/'}
            // ml={{ base: 0, xl: scrollPos > 400 ? 0 : '40px' }}
          >
            <Image
              alt='Manuel Pellón Circular Logo'
              h='auto'
              minWidth={'20px'}
              mx={'auto'}
              objectFit='cover'
              src={logo}
              w={
                !isHome || logoWidth <= logoWidthSmall
                  ? '80px'
                  : `${logoWidth}px`
              }
              // transform={`translateX(${
              //   !isHome || logoWidth <= logoWidthSmall ? '0px' : '20px'
              // })`}
            />
          </Link>
        </Box>

        <NavLinkDesktop
          color={linkColor}
          flex={1}
          ml={{ base: 0, xl: scrollPos > 400 ? 0 : 6 }}
          showActive={locationData?.pathname.includes('about')}
          text='ABOUT ME'
          to='/about'
        ></NavLinkDesktop>
        <NavLinkDesktop
          color={linkColor}
          flex={1}
          showActive={locationData?.pathname.includes('contact')}
          text='CONTACT'
          to='/contact'
        ></NavLinkDesktop>
      </Show>
    </Box>
  )
}

export default NavBar
