// const MEDIA_URL = 'http://localhost:3030/' + 'images/'
// const MEDIA_URL = process.env.REACT_APP_MEDIA_URL + 'images/'

import { fetchApi } from './api'

// console.log(process.env)
const { NODE_ENV, REACT_APP_IMAGE_HOST_URL, REACT_APP_MEDIA_URL } = process.env
const BASE_IMG_SRC_URL = NODE_ENV === 'development' ? REACT_APP_MEDIA_URL || '' : REACT_APP_IMAGE_HOST_URL || ''

const createSrc = (file_name, height, width, quality = 75) => {
  //https://manuelpellonphotography.imgix.net/ecde261b67a897f331c65ae9f79ba4e1.jpg?auto = format & ixlib=react - 9.5.4 & w=142.72999836479056 & h=189.09499754718584 & dpr=1 & q=75
  //TODO: what to append? height, width..
  //defaults for both?
  // console.log(file_name)
  let url = `${BASE_IMG_SRC_URL}${file_name}?dpr=1`
  url += `&q=${quality}`
  if (width) {
    url += `&w=${width}`
  }
  if (height) {
    url += `&h=${height}`
  } //else fit=clip ?

  return url
}

const createSrcSet = image => {
  // console.log(image.file_name)
  const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48]
  // const width = breakpoints[0]
  // const height = (image.height / image.width) * width
  const srcSet = breakpoints.map(breakpoint => {
    const height = Math.round((image.height / image.width) * breakpoint)
    return {
      src: createSrc(image.file_name, height, breakpoint),
      width: breakpoint,
      height,
    }
  })
  return srcSet
}

const fetchImages = async () => {
  try {
    const data = await fetchApi('image')
    // console.log(data)
    if (data && data?.length > 0) {
      const images = data.map(i => ({
        ...i,
        src: createSrc(i.file_name),
        srcSet: createSrcSet(i),
      }))
      return images
    }
  } catch (e) {
    console.log('error caught fetching image list')
    console.log(e)
  }
}

const fetchImagesByTag = async tag => {
  // console.log(tag)
  let URL = 'image/tag/'
  if (tag) {
    URL += tag
  }
  try {
    const data = await fetchApi(URL)
    if (data && data?.length > 0) {
      const images = data.map(i => ({
        ...i,
        src: createSrc(i.file_name),
        srcSet: createSrcSet(i),
      }))
      return images
    }
  } catch (e) {
    console.log('error caught fetching image list')
    console.log(e)
  }
}

export { createSrc, createSrcSet, fetchImages, fetchImagesByTag }
