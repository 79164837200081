import { Box, Button, CloseButton, Text } from '@chakra-ui/react'
// import MultiSelect from '../multi-select'
import TagMain from '../tag-main'

const contactFields = [
  {
    field: 'First Name',
    key: 'first_name',
  },
  {
    field: 'Last Name',
    key: 'last_name',
  },
  {
    field: 'Email',
    key: 'email',
  },
  {
    field: 'Telephone',
    key: 'phone',
  },
  {
    field: 'Address',
    key: 'address',
  },
  {
    field: 'City',
    key: 'city',
  },
  {
    field: 'State',
    key: 'state',
  },
  {
    field: 'Zipcode',
    key: 'zipcode',
  },
  {
    field: 'Notes',
    key: 'note',
  },
]

const ContactOverview = ({ contact }) => {
  return (
    <Box display={'flex'} alignItems={'flex-start'} flexDir={'column'} gap={1} maxHeight={'40vh'} overflowY={'auto'} width={'100%'}>
      {contactFields.map(cf => (
        <Box key={cf.field}>
          <Text as={'span'} fontWeight={'bold'} mr={2}>
            {cf.field}:
          </Text>{' '}
          <Text as={'span'}>{contact?.[cf.key] || '-'}</Text>
        </Box>
      ))}
    </Box>
  )
}
export default ContactOverview
