import {useCallback, useEffect, useRef, useState} from 'react'
import {useParams, useLocation, useSearchParams} from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  extendTheme,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {chakraComponents} from 'chakra-react-select'
import CaptchaWidget from '../controls/captcha-widget'
import {DatePicker} from 'chakra-datetime-picker'

import {BiBuildingHouse, BiCalendarEvent, BiSolidBuildingHouse} from 'react-icons/bi'
import {BsFillPersonFill, BsListStars, BsPersonBoundingBox, BsPersonLinesFill, BsPersonFillAdd, BsFillPersonCheckFill} from 'react-icons/bs'

import {FaAddressBook} from 'react-icons/fa6'
import {ImCamera} from 'react-icons/im'
import {IoGrid, IoLocationSharp} from 'react-icons/io5'
// import { LuCalendarClock } from 'react-icons/lu'
import {MdEventAvailable, MdMail, MdPhone, MdSend} from 'react-icons/md'
import {TfiLayoutGrid2Alt} from 'react-icons/tfi'

import AlertWrapper from '../alert-wrapper'
import MultiSelect from '../controls/multi-select'

import { addContact, deleteContact, updateContact } from '../../services/contact'


import usStates from '../../data/usStates'

const defaultContact = {
  address: '',
  city: '',
  email: '',
  first_name: '',
  // id: null,
  last_name: '',
  note: '',
  phone: '',
  state: 'NY',
  zipcode: '',
}

const ContactEditForm = ({onCancel, onDelete, onUpdate, originalContact}) => {
  //input fields

  const [actionState, setActionState] = useState({})
  const [contact, setContact] = useState({...defaultContact})
  const [stateSelect, setStateSelect] = useState(null)

  //form state
  const [invalidFields, setInvalidFields] = useState([])
  const [removeEvents, setRemoveEvents] = useState(false)

  const [submitResult, setSubmitResult] = useState(null)
  const [submitStatus, setSubmitStatus] = useState(null)

  const contactEditForm = useRef()
  const nameInputEl = useRef()
  const emailInputEl = useRef()

  const handleContactUpdate = e => {
    // console.log('handleContactUpdate')
    // console.log(e)
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  const handleSubmitForm = async e => {
    // console.log(e)
    if (!e) {
      console.error('e not received')
    }
    e.preventDefault()
    setSubmitResult(null)

    setSubmitStatus('loading')
    setSubmitResult(null)
    // console.log('handle contact submit')
    return
    // try {
    //   // const url = 'http://localhost:3030/contact'
    //   const url = process.env.REACT_APP_API_URL + 'contact/test'
    //   const res = await fetch(url, {
    //     method: 'POST',
    //     body: JSON.stringify(formDataStateMap),
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   })

    //   const data = await res.json()
    //   console.log(data)
    //   // setSubmitStatus(null)
    //   // return
    //   console.log('set timeout to see if request timed out and report.. ')
    //   setSubmitResult({
    //     status: data?.status || 'error',
    //     message: data?.message || 'Error submitting.',
    //     title: data?.title || null,
    //   })

    //   if (data.status === 'success') {
    //     // resetForm()
    //     setSubmitResult({ ...data })
    //   }
    // } catch (e) {
    //   setSubmitResult({
    //     status: e?.status || 'error',
    //     message: e?.message || 'Error encountered while submitting.',
    //   })
    // }
    // setSubmitStatus(null)
  }

  const handleSubmitButton = e => {
    e.preventDefault()
    setInvalidFields([])
    // console.log('handle button submit')
    // Make this return a value and use in submit?
    return
    // let errorMessage = 'Please enter a valid '
    // const nameIsValid = nameInputEl.current.checkValidity()
    // const emailIsValid = emailInputEl.current.checkValidity()
    // const withError = []
    // if (!nameIsValid || !emailIsValid) {
    //   if (!nameIsValid) {
    //     errorMessage += 'name'
    //     withError.push(nameInputEl)
    //   }
    //   if (!emailIsValid) {
    //     errorMessage += !nameIsValid ? ' and email.' : 'email.'
    //     withError.push(emailInputEl)
    //   } else {
    //     errorMessage += '.'
    //   }
    //   setInvalidFields(withError)
    //   setSubmitResult({
    //     status: 'error',
    //     message: errorMessage,
    //   })

    //   return
    // }
    // handleSubmitForm(e)
  }

  const onConfirmDelete = async () => {
    // console.log('onConfirmDelete')
    // console.log(contact)
    // PICKUP HERE,
    // ON BACKEDN.. DONT DELETE, SET is_contact =0
    setActionState({ ...actionState, deleteContact: { status: true } })
    try {
      const res = await deleteContact(contact.id, removeEvents)
      // console.log(res)
      setActionState({ ...actionState, deleteContact: {} })
      onDelete(contact.id, removeEvents)
    } catch (e) {
      setActionState({ ...actionState, deleteContact: { status: 'error', message: e?.message ?? e ?? 'erro deleting contact' } })
    } finally {
      // console.log('onConfirmDelete finally block ==')
    }

    //set loading
    // fetch.delete contact/id/t
    // 3rd param remove events
  }
  const onDeleteClick = () => {
    // console.log('onDeleteClick')
    // console.log(contact)
    setActionState({ ...actionState, confirmDelete: { status: true } })
  }

  const saveContact = async () => {
    // console.log('saveContact')
    setActionState({ ...actionState, savingContact: { status: 'loading' } })
    try {
      const action = contact?.id ? updateContact : addContact
      const resp = await action(contact)
      // console.log(resp)
      if (resp?.error) {
        console.log('has error')
        throw new Error(resp.error)
      }
      //resp or resp.data ?
      onUpdate(resp)
      // console.log('has error passed, onUpdate ran')
    } catch (err) {
      setActionState({ ...actionState, savingContact: { status: 'error', message: err?.message ?? err ?? 'Error saving contact' } })
    }
  }

  useEffect(() => {
    // console.log(originalContact)
    const contactData = { ...defaultContact }
    //ensure no null/undefined values for inputs
    for (const k in originalContact) {
      if (originalContact[k]) {
        contactData[k] = originalContact[k]
      }
    }
    const contactState = contactData?.state || 'NY'
    const state = usStates.find(s => s.value === contactState)
    if (state?.value) {
      setStateSelect(state)
    }
    // console.log(contactData)
    setContact(contactData)
  }, [originalContact])

  return (
    <Box>
      <Box
        as={'form'}
        bg={'transparent'}
        border={'solid'}
        // borderColor={'brand.primary'}
        borderWidth={1.5}
        borderRadius='none'
        height={'100%'}
        id={'contact-form'}
        maxHeight={{base: '65vh', md: '70vh'}}
        name={'contact-form'}
        onSubmit={handleSubmitForm}
        overflowY={'auto'}
        p={{base: 8}}
        ref={contactEditForm}
        // color={'brand.primary'}
        shadow='base'
      >
        {/* <Box mb={8}>
        <Text as={'p'} fontSize={'lg'}>
       
        </Text>
      </Box> */}
        <VStack height='100%' spacing={10}>
          {/* NAME INPUT */}
          <FormControl>
            <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={{base: 2}}>
              <Icon as={BsFillPersonFill} boxSize={'22px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Name
              </Text>
            </FormLabel>

            <SimpleGrid columns={{base: 1, md: 2}} overflow={'hidden'} spacing={{base: 4}}>
              <FormControl isRequired isInvalid={invalidFields.includes(nameInputEl)}>
                <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    First
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='first_name'
                  onChange={handleContactUpdate}
                  onFocus={() => setInvalidFields(invalidFields.filter(i => i !== nameInputEl))}
                  // placeholder='Full Name*'
                  ref={nameInputEl}
                  type='text'
                  value={contact.first_name}
                />
                {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
              </FormControl>
              <FormControl isRequired isInvalid={invalidFields.includes(nameInputEl)}>
                <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    Last
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='last_name'
                  onChange={handleContactUpdate}
                  onFocus={() => setInvalidFields(invalidFields.filter(i => i !== nameInputEl))}
                  // placeholder='Full Name*'
                  ref={nameInputEl}
                  type='text'
                  value={contact.last_name}
                />
                {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
              </FormControl>
            </SimpleGrid>
          </FormControl>
          <SimpleGrid columns={{base: 1, md: 2}} overflow={'hidden'} spacing={{base: 4}} width={'100%'}>
            {/* EMAIL INPUT */}

            <FormControl isRequired isInvalid={invalidFields.includes(emailInputEl)}>
              {/* <p>TODO: group these into "contact"?</p>
          <Icon as={FaAddressBook} boxSize={'22px'} mr={1} /> */}
              <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                <Icon as={MdMail} boxSize={'22px'} mr={1} />

                <Text as={'span'} fontSize={'lg'}>
                  Email Address
                </Text>
              </FormLabel>

              <InputGroup display={'flex'} alignItems={'center'}>
                {/* <Icon as={MdMail} mr={2} boxSize={'20px'} /> */}
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='email'
                  onChange={handleContactUpdate}
                  onFocus={() => setInvalidFields(invalidFields.filter(i => i !== emailInputEl))}
                  // placeholder='Email*'
                  ref={emailInputEl}
                  type='email'
                  value={contact.email}
                />
              </InputGroup>
              {/* <FormErrorMessage ml={'28px'}>Field is required.</FormErrorMessage> */}
            </FormControl>

            {/* PHONE INPUT */}

            <FormControl>
              {/* <FormLabel>Phone</FormLabel> */}
              <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1} mt={{base: 4, md: 0}}>
                <Icon as={MdPhone} boxSize={'22px'} mr={1} />
                <Text as={'span'} fontSize={'lg'}>
                  Telephone Number
                </Text>
              </FormLabel>
              <InputGroup display={'flex'} alignItems={'center'}>
                {/* <Icon as={MdPhone} mr={2} boxSize={'20px'} /> */}
                {/* <InputLeftElement children={<MdPhone />} /> */}
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='phone'
                  onChange={handleContactUpdate}
                  // placeholder='Phone number'
                  type='tel'
                  value={contact.phone}
                />
              </InputGroup>
            </FormControl>
          </SimpleGrid>
          <FormControl>
            <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={{base: 4}}>
              <Icon as={IoLocationSharp} boxSize={'22px'} mr={1} />

              <Text as={'span'} fontSize={'lg'}>
                Address
              </Text>
            </FormLabel>

            <SimpleGrid columns={{base: 1, md: 2}} overflow={'hidden'} spacing={{base: 4}}>
              <FormControl>
                <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    Street Address
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='address'
                  onChange={handleContactUpdate}
                  // placeholder='Address number'
                  type='text'
                  value={contact.address}
                />
              </FormControl>
              <FormControl mt={{base: 4, sm: 0}}>
                {/* <FormLabel>Services</FormLabel> */}

                <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    City
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='city'
                  onChange={handleContactUpdate}
                  // placeholder='City number'
                  type='text'
                  value={contact.city}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={{base: 1, sm: 2}} overflow={'hidden'} spacing={{base: 4}} mt={4}>
              {/* 
                  TODO: 
                  pickup here,
                    set breakpoints fot state/zip split
                    state label bp sm abb, lg full name etc

                */}
              <FormControl>
                <FormLabel display={'flex'} alignContent={'center'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    State
                  </Text>
                </FormLabel>
                <MultiSelect isMulti={false} onChange={setStateSelect} options={usStates} value={stateSelect}></MultiSelect>
              </FormControl>
              <FormControl mt={{base: 4, sm: 0}}>
                {/* <FormLabel>Services</FormLabel> */}

                <FormLabel display={'flex'} alignContent={'center'} alignItems={'end'} lineHeight={'22px'} mb={1}>
                  <Text as={'span'} fontSize={'sm'}>
                    Zip code
                  </Text>
                </FormLabel>
                <Input
                  borderRadius={'none'}
                  focusBorderColor={'brand.accent'}
                  name='zipcode'
                  onChange={handleContactUpdate}
                  // placeholder='Zipcode number'
                  type='text'
                  value={contact.zipcode}
                />
              </FormControl>
            </SimpleGrid>
          </FormControl>
          <FormControl>
            <FormLabel>Additional Notes</FormLabel>
            <Textarea
              borderRadius={'none'}
              focusBorderColor={'brand.accent'}
              name='note'
              onChange={handleContactUpdate}
              placeholder='Additional messages.'
              rows={4}
              resize='none'
              value={contact.note}
            />
          </FormControl>
        </VStack>
      </Box>

      <Box d={'flex'} alignContent={'center'} minHeight={'75px'} w={'full'}>
        {actionState?.savingContact?.status === 'error' && <AlertWrapper {...actionState.savingContact} onClick={() => setActionState({...actionState, savingContact: {}})}></AlertWrapper>}
        {/* {submitResult && <AlertWrapper {...submitResult} onClick={() => setSubmitResult(null)}></AlertWrapper>} */}
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} p={2}>
        <Button colorScheme={'yellow'} onClick={onCancel} variant='outline' width={'80px'}>
          CANCEL
        </Button>

        <Popover isLazy 
          isOpen={actionState?.['confirmDelete']?.status} 
          onOpen={() => setActionState({...actionState, 'confirmDelete' : true})}
          onClose={() => setActionState({...actionState, 'confirmDelete' : null})}
          placement='top'
          
        >
            <PopoverTrigger>
            <Button colorScheme={'red'} isDisabled={!contact?.id}  isLoading={actionState?.confirmDelete } variant='outline' width={'80px'} >
          {/* <Button colorScheme={'red'} isLoading={tagDeleting?.id} onClick={() => onDeleteTag()} variant='outline' width={'80px'}> */}
          DELETE
        </Button>
            </PopoverTrigger>
         
            <PopoverContent  maxWidth="600px" width="100%" >
              <PopoverArrow />
              <PopoverCloseButton  />
              <PopoverHeader color={'red'} fontSize={'2xl'} fontWeight={'bold'}>
                CONFIRM
              </PopoverHeader>
              <PopoverBody >
                {actionState?.['deleteContact']?.status === 'error' ? (
                  <>
                    <Text as={'p'}>The last attempt to delete this contact resulted in the following error:</Text>
                    <Text as={'p'} color={'red'} py={4}>
                      {actionState?.['deleteContact']?.message || 'Unkown error occurred '}
                    </Text>
                    <Text as={'p'}>Do you want to try again?</Text>
                  </>
                ) : (
                  <>
                  <Text as={'p'} fontSize={'2xl'}>This contact will be removed from the saved contacts list. </Text>
                  <Checkbox
                    colorScheme='orange'
                    iconSize='4rem'
                    isChecked={removeEvents}
                    my={8}
                    onChange={(e) => setRemoveEvents(e.target.checked)}
                  >
                    <Text fontSize={'lg'} fontWeight={'bold'}>
                     Remove all associated events.
                    </Text>
                  </Checkbox>
                  </>
                )}

                {/* <Box textAlign={'center'}>(this will remove the image completely from all categories.)</Box> */}
                <Button
                  mt={4}
                  colorScheme='red'
                  isLoading={actionState?.['deleteContact']?.status === 'deleting'}
                  onClick={onConfirmDelete}
                  width={'100%'}
                  // ref={initRef}
                >
                  {actionState?.['deleteContact']?.status === 'error' ? 'RETRY' : 'DELETE'}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>


   

        <Button
          colorScheme={'green'}
          isLoading={actionState?.['savingContact']?.status === 'loading'}
          rightIcon={contact?.id ? <BsFillPersonCheckFill /> : <BsPersonFillAdd />}
          onClick={saveContact}
          variant='outline'
        >
          {contact?.id ? 'SAVE' : 'ADD'} CONTACT
        </Button>
      </Box>
    </Box>
  )
}

export default ContactEditForm
