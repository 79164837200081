import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import {useParams, useLocation, useSearchParams} from 'react-router-dom'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdOutlineLogin } from 'react-icons/md'

import PrimaryButton from '../../components/controls/primary-button/primary-button'

import { login, testAuth } from '../../services/auth'

import logoBlack from '../../images/logo-trans-black.png'
import logoWhite from '../../images/logo-trans-white.png'

const LoginPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { colorMode, toggleColorMode } = useColorMode()
  const [formState, setFormState] = useState({ status: 'loading' })
  const loginFormRef = useRef()

  const logo = colorMode === 'dark' ? logoWhite : logoBlack

  const handleSubmit = async e => {
    // console.log('handleSubmit')
    // console.log(e)
    // console.log(loginFormRef)
    e.preventDefault()
    setFormState({ status: 'loading' })
    const formData = {}
    for (const el of e.target.elements) {
      if (el?.name) {
        formData[el.name] = el.value
      }
    }
    // console.log(formData)
    try {
      const resp = await login(formData)
      if (resp?.error) {
        throw new Error(resp.error)
      }
      setFormState({})
      navigate(`/manage`)
    } catch (e) {
      console.log(e)
      setFormState({ status: 'error', message: e?.message ?? e ?? 'error logging in' })
    }
  }

  useEffect(() => {
    testAuth()
      .then(r => {
        // console.log(r)
        navigate(`/manage`)
      })
      .catch(e => {
        console.log('catch test auth error')
        console.log(e)
        const error = searchParams.get('e')
        if (error === 'expired') {
          setFormState({ status: 'error', message: 'Session expired. Please log back in.' })
        } else {
          setFormState('')
        }
      })
  }, [])

  return (
    <Container display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'} textAlign={'center'}>
      {/* <Image alt='Manuel Pellón Circular Logo' h='auto' maxWidth={'300px'} objectFit='cover' src={logo} width={'80%'} /> */}
      <Heading lineHeight={1.1} fontWeight={'bold'} fontSize={{ base: '4xl' }} textAlign={'center'} textTransform={'uppercase'}>
        WELCOME
      </Heading>
      <Box height={'80px'} maxWidth={'500px'} mt={10} width={'100%'}>
        {formState?.status === 'error' && (
          <Alert cursor={'pointer'} onClick={() => setFormState({})} status='error'>
            <AlertIcon />
            <AlertTitle>Login Error</AlertTitle>
            <AlertDescription>{formState?.message || ''}</AlertDescription>
          </Alert>
        )}
      </Box>
      <Box
        as={'form'}
        border={'solid white 1px'}
        boxShadow={'lg'}
        id='login-form'
        maxWidth={'500px'}
        method='post'
        name='login-form'
        onSubmit={handleSubmit}
        p={8}
        ref={loginFormRef}
        rounded={'md'}
        width={'95%'}
      >
        <FormControl id='email'>
          <FormLabel>Username</FormLabel>
          <Input colorScheme='orange' name='username' type='text' />
        </FormControl>
        <FormControl id='password' mt={4}>
          <FormLabel>Password</FormLabel>
          <Input colorScheme='orange' name='password' type='password' />
        </FormControl>
        <Stack align={'center'} mt={10} spacing={10}>
          {/* <Button
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
            >
              Sign in
            </Button> */}

          <Button
            display={'flex'}
            alignItems={'center'}
            colorScheme={'brand'}
            form='login-form'
            isLoading={formState?.status === 'loading'}
            leftIcon={<MdOutlineLogin mr={2} />}
            // mt={{ base: 0, md: 4 }}
            maxW='80%'
            px={'.5em'}
            py={'.25em'}
            rounded={'none'}
            size={'md'}
            textTransform={'uppercase'}
            transition={'all 0.2s ease-in-out'}
            type='submit'
            variant='outline'
            width='full'
            _hover={{
              boxShadow: 'lg',
              borderColor: 'brand.accent',
              borderWidth: '2.5px',
              color: 'brand.accent',
            }}
          >
            LOGIN
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default LoginPage
