import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Center, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { AiFillFileAdd } from 'react-icons/ai'

import { fetchApi } from '../../services/api'

//https://media.manuelpellon.com/images/PORTRAITS/manuel.JPG
const UploadForm = ({ onFilesDrop }) => {
  const onDrop = useCallback(
    (acceptedFiles, a, b, c) => {
      onFilesDrop(acceptedFiles, a, b, c)
    },
    [onFilesDrop],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
    maxFiles: 30,
    multiple: true,
  })

  const dropText = isDragActive
    ? 'Drop the images here...'
    : 'Click/Drag images here'

  const activeBg = useColorModeValue('gray.100', 'gray.600')

  // console.log(fetchApi())
  return (
    <Box whiteSpace={'nowrap'} width={'100%'}>
      {/* <form action='http://localhost:3030/upload' method='post'> */}
      <Center
        bg={isDragActive ? activeBg : 'transparent'}
        cursor='pointer'
        alignItems={'center'}
        display={'flex'}
        p={4}
        transition='background-color 0.2s ease'
        _hover={{ bg: activeBg }}
        width={'100%'}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Icon as={AiFillFileAdd} boxSize={'1.5rem'} mr={2} />
        <Text as={'p'} fontSize={'lg'} fontWeight={'bold'}>
          {dropText}
        </Text>
      </Center>
      {/* <button type={'submit'}>SUBMIT</button>
      </form> */}
    </Box>
  )
}

export default UploadForm
