import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Container } from '@chakra-ui/react'
import PortraitPricingCard from '../../components/portrail-pricing-card'
import RealEstatePricingCard from '../../components/real-estate-pricing-card'
// import VirtualStagingPricingCard from '../../components/virtual-staging-pricing-card'
const ServicesPage = () => {
  const refMap = {
    'additional-services': useRef(),
    'portrait-photography': useRef(),
    'real-estate': useRef(),
    'virtual-staging': useRef(),
  }
  const locationData = useParams()
  const activeService = locationData?.service || 'top'

  useEffect(() => {
    const offset = 140 //clear navbar
    const timoutID = setTimeout(() => {
      const activeElement = refMap?.[activeService]?.current
      if (activeElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: activeElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
        })
      } else {
        // console.log('SCROLL TOP')
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timoutID)
  }, [])

  return (
    <Container>
      <Box ref={refMap['real-estate']} />
      <RealEstatePricingCard></RealEstatePricingCard>
      <Box mb={'120px'} />

      {/* <Box ref={refMap['virtual-staging']} />
      <VirtualStagingPricingCard></VirtualStagingPricingCard>
      <Box mb={'120px'} /> */}

      <Box ref={refMap['portrait-photography']} />
      <PortraitPricingCard></PortraitPricingCard>
      <Box mb={'120px'} />
    </Container>
  )
}

export default ServicesPage
