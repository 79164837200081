import { useEffect, useState } from 'react'
import { Box, Container, Flex, Image, Show } from '@chakra-ui/react'
import ColorModeToggle from '../controls/color-mode-toggle'
import NavLinkDesktop from '../nav-link-desktop/nav-link-desktop'
import SocialIcons from '../social-icons/social-icons'
import logoWhite from '../../images/logo-trans-white.png'
import './style.css'

const NavMobile = ({
  bgOpacity,
  colorMode,
  isHome,
  locationData,
  menuColor,
  toggleColorMode,
}) => {
  // console.log(isHome)
  //TODO: GET HEX VALUE OF PASSED MENU COLOR
  //TODO: USE THEME DARK/LIGHT MODE
  // console.log(menuColor)
  const [showMenu, toggleMenu] = useState(false)
  const handleClick = (e) => {
    if (e?.target?.id?.includes('nav')) toggleMenu(!showMenu)
  }

  const handleColorToggle = () => {
    toggleColorMode()
    toggleMenu(false)
  }

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflowY = showMenu ? 'hidden' : 'auto'
  }, [showMenu])
  return (
    <div className={` menu ${showMenu ? 'active' : ''} `}>
      <button
        aria-label='toggle menu'
        className={`nav-tgl ${showMenu ? 'active' : ''} ${
          showMenu || (isHome && bgOpacity === 0) ? 'light' : menuColor
        }`}
        id='mobile-nav-menu'
        onClick={handleClick}
        type='button'
        // style={{
        //   backgroundColor: menuColor,
        // }}
      >
        <span aria-hidden='true' id='nav-span'></span>
      </button>
      <nav
        aria-label='toggle menu'
        className={`nav ${showMenu ? 'active' : ''}`}
      >
        <Container
          display={'flex'}
          flexDirection={'column'}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          justifyItems={'flex-end'}
          height={'100vh'}
          left={0}
          opacity={showMenu ? 1 : 0}
          position={'absolute'}
          top={0}
          transition={`opacity ${showMenu ? '1.5' : '.5'}s ease, visibility ${
            showMenu ? '1.5' : '.5'
          }s ease`}
          visibility={showMenu ? 'visible' : 'hidden'}
          maxWidth={'100vw'}
          w={'100vw'}
        >
          {showMenu && (
            <Box position={'fixed'} top={4} left={4}>
              <ColorModeToggle
                color={'brand.secondary.light'}
                colorMode={colorMode}
                toggleColorMode={handleColorToggle}
              ></ColorModeToggle>
            </Box>
          )}
          <Image
            alt='Manuel Pellón Circular Logo'
            pointerEvents={'none'}
            h='auto'
            left={'50vw'}
            objectFit='cover'
            opacity={0.05}
            pos={'absolute'}
            src={logoWhite}
            top={'50vh'}
            transform={'translate(-50%, -50%)'}
            w={{ base: '75vw' }}
          />
          <Flex
            bg={'transparent'}
            align={'center'}
            borderBottom={1}
            borderColor={'transparent'}
            borderStyle={'solid'}
            direction={'column'}
            gap={{ base: 2, lg: 10 }}
            justifyContent={{
              base: 'space-between',
            }}
            // height={'30vh'}
            // mt={'12vh'}
            position={'fixed'}
            top={'50vh'}
            left={'50vw'}
            transform={'translate(-50%, -50%)'}
            px={{ base: 2, sm: 4 }}
            py={{ base: 2, sm: 4, md: 2 }}
            // w={'100%'}
            onClick={handleClick}
          >
            <NavLinkDesktop
              color={'brand.secondary.light'}
              end
              showActive={locationData?.pathname === '/'}
              size={'2rem'}
              text='HOME'
              to='/'
            ></NavLinkDesktop>
            <NavLinkDesktop
              color={'brand.secondary.light'}
              showActive={locationData?.pathname.includes('services')}
              size={'2rem'}
              text='SERVICES'
              to='/services'
            ></NavLinkDesktop>
            <NavLinkDesktop
              color={'brand.secondary.light'}
              showActive={locationData?.pathname.includes('portfolio')}
              size={'2rem'}
              text='PORTFOLIO'
              to='/portfolio'
            ></NavLinkDesktop>

            <NavLinkDesktop
              color={'brand.secondary.light'}
              showActive={locationData?.pathname.includes('about')}
              size={'2rem'}
              text='ABOUT'
              to='/about'
            ></NavLinkDesktop>
            <NavLinkDesktop
              color={'brand.secondary.light'}
              showActive={locationData?.pathname.includes('contact')}
              size={'2rem'}
              text='CONTACT'
              to='/contact'
            ></NavLinkDesktop>
          </Flex>
          <Box>
            <SocialIcons iconColor={'brand.secondary.light'} />
          </Box>
        </Container>
      </nav>
    </div>
  )
}

export default NavMobile
