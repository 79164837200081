import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabIndicator,
  TabPanel,
  useColorMode,
  // useDisclosure,
} from '@chakra-ui/react'

//custom components
import TagManagement from '../../components/tag-management'

//services
import { fetchTag } from '../../services/tag'

const tabs = ['tags']

const ManageContent = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { colorMode } = useColorMode()

  const [showTabs, setShowTabs] = useState(true)
  const [tags, setTags] = useState([])

  const navigate = useNavigate()
  const params = useParams()

  const handleTabsChange = tabIdx => {
    console.log(handleTabsChange)
    setActiveTab(tabIdx)
    const view = tabs[tabIdx]
    if (view) {
      navigate(`/manage/content/${view}`)
    }
  }

  const onTagsUpdate = newTagData => {
    setTags(newTagData)
  }

  // useEffect(() => {
  //   //tabs set to lazy and keep mounted.
  //   // Only setup if on images view and active tab is not initialized
  //   if (params?.view !== 'images') return
  //   if (activeTab) return
  //   if (params?.secondaryView) {
  //     const idx = tabs.indexOf(params.secondaryView)
  //     if (idx > -1) {
  //       setActiveTab(idx)
  //     } else {
  //       handleTabsChange(0)
  //     }
  //   } else {
  //     handleTabsChange(0)
  //   }
  // }, [params])

  useEffect(() => {
    fetchTag()
      .then(tags => setTags(tags))
      .catch(e => console.log(e.message || 'error fetching tags from gallery'))
  }, [])

  return (
    <>
      <Tabs align='start' index={activeTab} isLazy={true} lazyBehavior={'keepMounted'} onChange={handleTabsChange} variant='unstyled'>
        {showTabs && (
          <Box
            bgColor={colorMode === 'dark' ? 'brandDark.background' : 'brand.background'}
            position={'sticky'}
            // after primary tabs top
            top={186}
            zIndex={19}
          >
            <TabList bgColor={`brand.background.${colorMode}`} shadow={'md'}>
              <Tab fontWeight={'bold'} fontSize={'xl'}>
                TAGS
              </Tab>
            </TabList>
            <TabIndicator mt='-1.5px' height='2px' bg='brand.accent' borderRadius='1px' />
          </Box>
        )}

        <TabPanels pt={4}>
          <TabPanel>
            <TagManagement onUpdate={onTagsUpdate} tags={tags} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <ModalDialog
        isOpen={isModalOpen}
        onOpen={onModalOpen}
        onClose={onModalClose}
        modalBody={<ImageEditForm image={uploadEditing} saveImage={onSaveUploadImage} tagOptions={tagOptions} />}
        title={'EDIT IMAGE'}
      /> */}
    </>
  )
}

export default ManageContent
