import { Image } from '@chakra-ui/react'
import { Blurhash } from 'react-blurhash'
import Imgix from 'react-imgix'
import { createSrc } from '../../services/image'
import './style.css'
//https://manuelpellonphotography.imgix.net/06c6ee1d820fcff30682c4f43e8679bd.jpg?w=100
const ImageWrapper = (props) => {
  // console.log(props)
  const { imageProps, layout, layoutOptions, photo } = props
  // console.log('props image-wrapper ')
  // console.log(props)
  // { photo, wrapperStyle, renderDefaultPhoto } = props
  return (
    /*{ <Blurhash
        hash={photo?.blurhash}
        height={layout.height}
        width={layout.width}
        punch={1}
        // style={{
        //   position: 'absolute',
        //   zIndex: -1,
        // }}
      /> }*/
    // <div {...imageProps}>
    //   <Imgix
    //     // animation={'fadeIn 3s'}
    //     height={layout?.height + 50 || undefined}
    //     // height={
    //     //   layout?.height ? layout.height - layoutOptions.spacing : undefined
    //     // }
    //     // maxWidth={'500px'}
    //     loading={'lazy'}
    //     width={layout?.width + 50 || undefined}
    //     // width={layout?.width ? layout.width - layoutOptions.spacing : undefined}
    //     // {...imageProps}
    //     // fallback={
    //     //   <Blurhash
    //     //     hash={photo?.blurhash}
    //     //     height={layout.height}
    //     //     width={layout.width}
    //     //     punch={1}
    //     //   />
    //     // }
    //     htmlAttributes={{
    //       ...imageProps,
    //       style: {
    //         cursor: 'pointer',
    //         height: '100%',
    //         width: '100%',
    //         // position: 'absolute',
    //         // zIndex: 1,
    //       },
    //     }}
    //     src={photo?.src}
    //   ></Imgix>
    // </div>
    <Image
      fallback={
        photo?.blurhash ? (
          <Blurhash
            hash={photo?.blurhash}
            height={layout?.height}
            key={photo?.md5}
            width={layout?.width}
            punch={1}
            // style={{
            //   position: 'absolute',
            //   zIndex: -1,
            // }}
          />
        ) : (
          'loading'
        )
      }
      {...imageProps}
      key={photo?.md5}
      src={createSrc(
        photo?.file_name,
        layout?.height + 200,
        layout?.width + 200,
      )}
    ></Image>
  )
}
export default ImageWrapper
