import { useEffect, useState } from 'react'
import { Box, Flex, HStack, Icon, IconButton, Image, Tag, TagLabel, TagCloseButton, Text, Tooltip, useBreakpoint } from '@chakra-ui/react'

import { BiCloudUpload, BiEdit } from 'react-icons/bi'
import { MdClear, MdOutlineDelete, MdOutlineCloudDone } from 'react-icons/md'
import { TbCloudOff } from 'react-icons/tb'

import TagMain from '../tag-main'

//TODO
//extract iconBottons, or at least map status codes to color, icon, loader etc

const ImageUploadTile = ({ image, deleteItem, editItem, status, tagData, uploadItem }) => {
  const sizeKB = bytes => Math.floor(bytes / 1024)
  // console.log(image)
  const createImgSrc = () => {
    console.log(image)
    return image.file ? URL.createObjectURL(image.file) : image.src
  }

  const onUploadClick = () => {
    if (status.status === 'success') return
    if (status.status === 'error') {
      console.log('display error message here.. ')
      return
    }

    uploadItem(image)
  }

  const uploadTooltip = () => {
    const tooltip = {
      bg: '',
      label: 'Upload Image',
    }
    if (status?.status === 'success') {
      tooltip.bg = 'green'
      tooltip.color = 'white'
      tooltip.label = status?.message || 'Image uploaded successfully.'
    }
    if (status?.status === 'error') {
      tooltip.bg = 'red'
      tooltip.color = 'white'
      tooltip.label = status?.message || 'Error encountered uploading image. '
    }

    return tooltip
  }

  return (
    <Box alignContent={'center'} border={'.2px  solid'} display='flex' flexWrap={'wrap'} gap={4} minHeight={'100px'} justifyContent={'flex-start'} mt={2} padding={2}>
      <Image loading={'lazy'} alt={image.name} height='auto' maxHeight='100%' objectFit={'contain'} flexShrink={'1'} width={'80px'} src={createImgSrc()} />
      <Flex direction='column' alignItems={'flex-start'}>
        <Text as={'p'} fontSize='lg' m={0}>
          {image.name}
        </Text>
        <Text as={'p'} fontSize='sm' m={0}>
          {sizeKB(image.size ?? image.file_size ?? 0)}KB
        </Text>
        {/* <TagsSelect></TagsSelect> */}
        <Box alignContent={'flex-start'} alignItems={'flex-start'} display={'flex'} gap={2} justifyContent={'flex-start'} mt={2} flexWrap={'wrap'}>
          {image?.tags?.map(tag => <TagMain key={tag.id} label={tag?.label || '???'} size={'md'} />) || ''}
        </Box>
      </Flex>
      <Box display={'flex'} alignItems={'center'} ml={'auto'} px={4}>
      <IconButton
          aria-label='Remove from pending uploads'
          display={'flex'}
          icon={<Icon as={status?.status === 'success' ? MdClear : MdOutlineDelete} />}
          ml={4}
          onClick={() => deleteItem(image)}
          fontSize='2xl'
          title={'Remove from pending uploads'}
          variant={'unstyled'}
          _hover={{
            color: 'brand.accent',
          }}
        />
        <IconButton
          aria-label='Edit upload data'
          display={'flex'}
          icon={<Icon as={BiEdit} />}
          isDisabled={status?.status === 'success'}
          ml={4}
          onClick={() => editItem(image)}
          fontSize='2xl'
          title={'Edit upload data'}
          variant={'unstyled'}
          _hover={{
            color: 'brand.accent',
          }}
        />

        <Tooltip hasArrow {...uploadTooltip()} placement='top'>
          <IconButton
            aria-label='Upload Image'
            color={status.status === 'success' ? 'green' : status.status === 'error' ? 'red' : undefined}
            display={'flex'}
            icon={<Icon as={status.status === 'success' ? MdOutlineCloudDone : status.status === 'error' ? TbCloudOff : BiCloudUpload} />}
            isLoading={status.status === 'loading'}
            ml={4}
            onClick={onUploadClick}
            fontSize='2xl'
            title={'Upload Image'}
            variant={'unstyled'}
            _hover={{
              color: 'brand.accent',
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}
export default ImageUploadTile
