import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
// import { history } from 'history/browser'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabIndicator,
  TabPanel,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'

import { MdOutlineLogout } from 'react-icons/md'

import ManageClients from '../../components/manage-clients'
import ManageContent from '../../components/manage-content'
import ManageEvents from '../../components/manage-events'
import ManageImages from '../../components/manage-images'

import { logout } from '../../services/auth'

const tabs = ['clients', 'events', 'content', 'images', 'sales']

const ManagePage = () => {
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(0)
  const { colorMode } = useColorMode()
  const params = useParams()
  const [showTabs, setShowTabs] = useState(false)

  //delay loading of tab list on screen due to bug possibly rendering before font load
  setTimeout(() => setShowTabs(true), 800)

  const handleLogout = async () => {
    await logout()
  }

  const handleTabsChange = tabIdx => {
    setActiveTab(tabIdx)
    const view = tabs[tabIdx]
    if (view) {
      navigate(`/manage/${view}`)
    }
  }

  useEffect(() => {
    if (params?.view) {
      const idx = tabs.indexOf(params.view)
      if (idx > -1) {
        setActiveTab(idx)
      }
    }
  }, [params])

  useEffect(() => {
    // console.log('THEN SET MIDDLEWHERE IN MANAGE ROUTES..')
    // console.log('TEST AUTH')
    // all routes intercepting requests and redirecting on auth failure
  }, [])

  return (
    <>
      <Container pt={'140px'} display={'flex'} flexDirection={'column'}>
        <Box as={'header'} alignItems={'center'} display={{ base: 'none', md: 'flex' }}>
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '4xl' }} my={4} textAlign={'left'} textTransform={'uppercase'}>
            MANAGE
          </Heading>
          <Button colorScheme='orange' ml={'auto'} onClick={handleLogout} variant={'outline'}>
            <Icon as={MdOutlineLogout} mr={2} />
            LOGOUT
          </Button>
        </Box>
        {/* <Box alignItems='start' display='flex'>
          <p></p>
        </Box> */}
        <Tabs align='start' index={activeTab} isLazy onChange={handleTabsChange} pos={'sticky'} top={'300px'} variant='unstyled'>
          {/* Removing keep mounted from tabs... to many pages rely on updated content from eeachother */}
          {/* lazyBehavior={'keepMounted'} */}
          {showTabs && (
            <Box bgColor={colorMode === 'dark' ? 'brandDark.background' : 'brand.background'} position={'sticky'} top={140} zIndex={19}>
              <TabList bgColor={`brand.background.${colorMode}`} shadow={'md'}>
                <Tab fontWeight={'bold'} fontSize={'xl'}>
                  CLIENTS
                </Tab>
                <Tab fontWeight={'bold'} fontSize={'xl'}>
                  EVENTS
                </Tab>
                <Tab fontWeight={'bold'} fontSize={'xl'}>
                  CONTENT
                </Tab>

                <Tab fontWeight={'bold'} fontSize={'xl'}>
                  IMAGES
                </Tab>
                <Tab fontWeight={'bold'} fontSize={'xl'}>
                  SALES
                </Tab>
              </TabList>
              <TabIndicator mt='-1.5px' height='2px' bg='brand.accent' borderRadius='1px' />
            </Box>
          )}

          <TabPanels>
            <TabPanel>
              <ManageClients />
            </TabPanel>
            <TabPanel>
              {/* <Text as={'p'} mb={10}>
                Management of appointments pending approval, scheduled, reschedule, past etc...
              </Text> */}
              <ManageEvents />
            </TabPanel>
            <TabPanel>
              <ManageContent />
            </TabPanel>
            <TabPanel p={0} mt={1}>
              <ManageImages />
            </TabPanel>
            <TabPanel>
              <Text as={'p'} mb={10}>
                finances/invoicing/orders{' '}
              </Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* MODAL  */}
      </Container>
    </>
  )
}

export default ManagePage
