import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
//get from local
// const brand = {
//   light: {
//     accent: 'orange',
//     background: '#f7fafc',
//     disabled: '#edf2f7',
//     main: '#1a202c',
//     primary: '#1a202c',
//     secondary: '#f7fafc',
//   },
//   dark: {
//     accent: 'orange',
//     background: '#1a202c',
//     disabled: '#edf2f7',
//     main: '#f7fafc',
//     primary: '#f7fafc',
//     secondary: '#1a202c',
//   },
// }

// const accent = 'orange'
// const background = mode('#f6f5f4', '#1a202c')(props)
// // background: '#f7fafc',
// const primary = mode('#1a202c', '#f7fafc')(props)
// const secondary = mode('#f7fafc', '#1a202c')(props)
const customColors = {
  accent: {
    light: 'orange',
    dark: 'orange',
  },
  background: {
    light: '#f6f5f4',
    dark: '#1a202c',
  },
  disabled: {
    light: '#edf2f7',
    dark: '#edf2f7',
  },
  primary: {
    light: '#1a202c',
    dark: '#f7fafc',
  },
  secondary: {
    light: '#f7fafc',
    dark: '#1a202c',
  },
  selectHover: {
    light: '#edf2f7',
    dark: '#2f3542',
  },
}

const footerHeight = '140px'

const extendedTheme = extendTheme({
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '64em',
    // lg: '1200px',
    // lg: '62em',
    xl: '70em',
    '2xl': '96em',
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true,
  },
  styles: {
    global: props => ({
      'html, body': {
        bg: mode(customColors.background.light, customColors.background.dark)(props),
        color: mode(customColors.primary.light, customColors.primary.dark)(props),
        overflowX: 'hidden',
        zIndex: -1,
      },
      body: {
        position: 'relative',
        minHeight: '100vh',
        // textTransform: 'uppercase',
        // pt: '100px',
        _after: {
          content: '""',
          display: 'block',
          height: footerHeight,
        },
      },
      main: {
        // fontSize: 'min(10vw, 2rem)',
        // textAlign: 'center',
      },
    }),
  },
  fonts: {
    body: 'Barlow Condensed, sans-serif',
    heading: 'Barlow Condensed, sans-serif',
    mono: 'Menlo, monospace',
  },
  // fonts: {
  //   body: 'Josefin Sans',
  //   heading: 'Josefin Sans',
  //   mono: 'Menlo, monospace',
  // },
  colors: {
    yellow: '#ffd751',
    brand: {
      accent: 'orange',
      primary: 'yellow',
      background: {
        light: customColors.background.light,
        dark: customColors.background.dark,
      },
      disabled: customColors.disabled.light,
      primary: {
        light: customColors.primary.light,
        dark: customColors.primary.dark,
      },
      secondary: {
        light: customColors.secondary.light,
        dark: customColors.secondary.dark,
      },
      selectHover: {
        light: customColors.selectHover.light,
        dark: customColors.selectHover.dark,
      },
    },
    // background: '#f7fafc',
    datetimepicker: {
      50: 'eggplant',
      100: 'yellow',
      200: 'green',
      300: 'red',
      400: 'orange',
      500: 'pink',
      600: 'purple',
      700: 'aqua',
      800: 'magenta',
      900: 'aqua',
    },
  },
  semanticTokens: {
    brand: { ...customColors },
  },
  // brandDark: {
  //   accent: 'orange',
  //   background: '#1a202c',
  //   primary: '#f7fafc',
  //   secondary: '#1a202c',
  // },
  // brandLight: {
  //   accent: 'orange',
  //   bg: '#f6f5f4',
  //   // background: '#f7fafc',
  //   primary: '#1a202c',
  //   secondary: '#f7fafc',
  // },
  components: {
    Box: {
      baseStyle: {
        bg: '#f7fafc',
      },
    },
    Container: {
      baseStyle: {
        maxWidth: '1200px',
        pt: { base: '200px' },
        // pt: { base: '100px', md: '140px', xl: '200px' },
      },
    },
    ListIcon: {
      baseStyle: {
        color: 'orange',
      },
    },
    // Container: {
    //   baseStyle: {
    //     bg: '#f7fafc',
    //   },
    // },
  },
})
// console.log(extendedTheme)
export default extendedTheme
