import { useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Container } from '@chakra-ui/react'
import ContactCard from '../../components/contact-card'
const ContactPage = () => {
  const urlParams = useParams()
  const location = useLocation()
  useEffect(() => {
    // const { service } = urlParams
    // console.log(service)
    // const offset = 120 //clear navbar
    // if (service) return
    const timoutID = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 300)
    return () => clearTimeout(timoutID)
  }, [location?.pathname])
  return (
    <Container maxWidth={'1600px'}>
      <ContactCard showTitle={false}></ContactCard>
    </Container>
  )
}

export default ContactPage
