const BASE_URL = process.env.REACT_APP_API_URL

const fetchApi = async (urlParams = '', method = 'GET') => {
  //Only GET/DELETE methods

  // const url = process.env.REACT_APP_API_URL
  const url = BASE_URL + urlParams
  // console.log(url)

  try {
    const resp = await fetch(url, {
      credentials: 'include',
      method,
      withCredentials: true,
    })
    // console.log(resp)
    if (resp?.status === 401) {
      // console.log(window.location)
      if (!window.location?.pathname?.includes('login')) {
        const URL = `${window.location.origin}/login?e=expired`
        window.location.replace(URL)
      }
      throw new Error(resp)
    }
    // const data = await resp.json()
    // console.log(data)
    return await resp.json()
    // return data
  } catch (e) {
    console.log('error caught fetching event data')
    console.log(e)
    throw new Error(e?.message ?? e ?? 'error fetching api')
  }
}

const postApi = async (urlParams = '', postData = {}, method = 'POST') => {
  //Only GET/DELETE methods

  // const url = process.env.REACT_APP_API_URL
  const url = BASE_URL + urlParams
  // console.log(url)

  try {
    const resp = await fetch(url, {
      body: JSON.stringify(postData),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      method,
      withCredentials: true,
    })
    // console.log(resp)
    if (resp?.status === 401) {
      // console.log(window.location)
      if (!window.location?.pathname?.includes('login')) {
        const URL = `${window.location.origin}/login`
        window.location.replace(URL)
      }
      throw new Error(resp)
    }

    if (resp?.status !== 200) {
      throw new Error(` ${resp?.status} - ${resp?.statusText} ` || 'Error updating event')
    }
    // const data = await resp.json()
    // console.log(data)
    return await resp.json()
    // return data
  } catch (e) {
    console.log('error caught fetching event data')
    console.log(e)
    throw new Error(e?.message ?? e ?? 'error fetching api')
  }
}

export { fetchApi, postApi }
