import { memo, useCallback, useEffect, useRef } from 'react'
import { WidgetInstance } from 'friendly-challenge'
import { Box } from '@chakra-ui/react'
import { useColorMode } from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react'
import './style.css'

const CaptchaWidget = ({ onDone, widget }) => {
  const container = useRef()
  const { colorMode } = useColorMode()
  const theme = useTheme()

  const doneCallback = useCallback(
    (solution) => {
      if (onDone) onDone(solution)
    },
    [onDone],
  )

  // const errorCallback = useCallback(
  //   (err) => {
  //     console.log('There was an error when trying to solve the Captcha.')
  //     // console.log(err)
  //     if (onError) onError(err)
  //   },
  //   [onError],
  // )
  const errorCallback = (err) => {
    console.log('There was an error when trying to solve the Captcha.')
    // console.log(err)
    // if (onError) onError(err)
  }

  useEffect(() => {
    // console.log(colorMode)
    // console.log(theme)
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'none',
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      })
    }
    return () => {
      if (widget.current !== undefined) widget.current.reset()
    }
  })

  return (
    <Box
      as={'div'}
      ref={container}
      className={`frc-captcha ${colorMode}`}
      style={{
        backgroundColor: theme?.colors?.brand?.background?.[colorMode],
        border: 'solid 1px',
      }}
      data-sitekey={process.env.REACT_APP_FRIENDLY_CAPTCHA_SITE_KEY}
      w={'100%'}
    />
  )
}

export default memo(CaptchaWidget)
