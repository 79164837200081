import { useEffect, useRef, useState } from 'react'

import {
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'

// icons
// import { BiLayerPlus } from 'react-icons/bi'
// import { MdAddBox, MdClear } from 'react-icons/md'
// import { IoMdTrash } from 'react-icons/io'



const TagMain = props => {
  const defaultProps = {
    borderRadius: 'sm',
    border: `2px dotted `,
    borderColor: 'brand.accent',
    boxSizing: 'border-box',
    cursor: props?.onClick ? 'pointer' : '',
    fontWeight: 'bold',
    size: 'lg',
    variant: 'solid',
  }
  const tagProps = { ...defaultProps, ...props }
  return (
    <Tag {...tagProps}>
      <TagLabel>{props?.label || ''}</TagLabel>
      {/* <TagCloseButton as={IoMdTrash} cursor={'pointer'} onClick={() => onDeleteTag(tag)} /> */}
    </Tag>
  )
}
export default TagMain
