import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CheckBox, Container, Flex, Heading, SimpleGrid, Tag, useColorMode } from '@chakra-ui/react'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { calendarEvent } from '../../../services/event'
const AddToCalendar = ({ event }) => {
  // const { colorMode } = useColorMode()
  // const [selected, setSelected] = useState([])
  // console.log('AddToCalendar')
  // console.log(event)
  // const calendarEvent = calendarEvent(event)
  // console.log(e)

  return (
    <Box
      alignContent={'center'}
      display={'flex'}
      flexDir={'column'}
      fontSize={'lg'}
      justifyContent={'space-between'}
      // minHeight={'200px'}
      width={'100%'}
    >
      <AddToCalendarButton
        {...calendarEvent(event)}
        buttonsList
        buttonStyle='round'
        hideTextLabelButton
        options="'Apple','Google','iCal','Outlook.com','Yahoo'"
        timeZone='America/New_York'
      ></AddToCalendarButton>
    </Box>
  )
}

export default AddToCalendar
